.product-impact {
  --section-color-dark: var(--arkefip-pine-primary);
  --section-color-primary: var(--arkefip-pine-secondary);
  --section-color-background: var(--arkefip-pine-background);
}

.impact-grid {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.impact-card {
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-radius: 0.5rem;
  overflow: hidden;
  
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    min-height: 240px;
  }
}

.impact-card-image {
  background-size: cover;
}

.impact-card-content {
  padding: var(--arkefip-spacer-sm);
  color: white;
  border-top: 1px solid var(--arkefip-pine-dark);
}

.impact-text {
  font-size: var(--arkefip-text-xs);
}

.impact-figure {
  font-family: var(--arkefip-font-headings);
  font-size: 1.2rem;
  font-weight: 100;
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  line-height: 1;
}

.impact-quote {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);
  align-items: center;
  border-radius: 0.5rem;
  color: var(--arkefip-pine-primary);
  position: relative;
  
  background: url('../../assets/images/patterns/arkefip-wood-background-pine.svg'), var(--arkefip-pine-background);
  background-size: cover;
  background-position: bottom;
  
  @include media-breakpoint-up(md) {
    display: grid;
    align-items: stretch;
    grid-template-columns: 1fr 3fr;
    gap: 0;
  }

  &::before {
    content: "\275E";
    font-weight: 900;
    position: absolute;
    top: var(--arkefip-spacer-xs);
    right: var(--arkefip-spacer-xs);
    color: transparent;
    opacity: 0.2;
    -webkit-text-stroke: 1px var(--arkefip-pine-secondary);
    font-size: 8rem;
    line-height: 0.8;
  }  

  &-picture {
    display: none;
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    &-mobile {
      display: flex;
      margin-bottom: var(--arkefip-spacer-xs);
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &-content {
    padding: var(--arkefip-spacer-sm);
    font-size: var(--arkefip-text-md);
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: var(--arkefip-spacer-sm);
    
    @include media-breakpoint-up(md) {
      flex-direction: column;
      align-items: flex-end;
      padding: var(--arkefip-spacer-md) var(--arkefip-spacer-md) var(--arkefip-spacer-md) 0;
    }
  }

  &-author {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-signature {
    font-weight: bold;
    border-radius: 0 !important;
  }

  &-title {
    font-size: var(--arkefip-text-sm);
    color: var(--arkefip-def-darth);
    font-weight: 300;
  }

  blockquote {
    line-height: 1.3;
    margin-bottom: 0;
  }
}