.arkefip-sections-footprint-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: url('../../assets/images/patterns/arkefip-wood-background-dark.svg'), var(--arkefip-primary);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
}

.arkefip-home-footprint {
  padding: 3rem var(--arkefip-layout-padding);
  color: white;
  width: 100%;

  h2 {
    color: white !important;
  }
}

.arkefip-home-footprint-carbon-group {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.arkefip-home-footprint-carbon-block {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: start;
  gap: 18px;
  border-radius: 18px;
  background: var(--arkefip-def-background);
  box-shadow: var(--arkefip-shadow-lg);

  p {
    margin: 0;
  }

  i {
    width: 24px;
    color: var(--arkefip-secondary);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    line-height: 130%;
  }

  &-negative {
    p {
      text-decoration-line: line-through;
    }

    i {
      color: var(--arkefip-pollen-primary);
    }
  }
}

.arkefip-home-footprint-carbon-block-content {
  h3 {
    font-family: var(--arkefip-font-body);
    color: var(--arkefip-def-primary);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin: 0;
  }
}

.arkefip-home-footprint-carbon-block-content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 8px;
}

.arkefip-home-footprint-carbon-block-content-grid-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--arkefip-def-primary);
  font-family: var(--arkefip-font-body);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
