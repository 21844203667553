.partner-choice {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: linear-gradient($primary 0%, $primary 50%, transparent 50%);

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0;
  }

  &-sidecard {
    background: white;
    padding: 2rem;
    box-shadow: $box-shadow;
    border-radius: 1rem;

    @media (min-width: 900px) {
      border-radius: 0 1rem 1rem 0;
    }
  }
}

.partner-choice-carousel {
  overflow-y: scroll;
  box-sizing: contain;

  &-group {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-items {
    display: flex;
    height: 100%;
    grid-gap: 1rem;
    padding: 2rem;
  }

  &-item {

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(https://images.adsttc.com/media/images/5e05/5b1a/3312/fdb5/6b00/0046/large_jpg/by_Julia_Kivela_high_res_HG5A0317.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: $box-shadow;
    border-radius: 1rem;
    padding: 1rem;
    color: white;
    min-width: clamp(200px, 25vw, 100%);
    gap: 0.5rem;

    &-body {
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 110%;
    }
  }
}

#scroll-r {
  position: absolute;
  right: 2rem;
}
