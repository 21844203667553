.table-responsive {
  border-radius-top: 10px;
}
.table-rounded {
  border-radius: 10px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 2px $secondary;
  & > thead > tr > th {
    border-bottom: 2px solid $secondary;
  }
  & > tbody > tr > th {
    border-bottom: 1px solid $secondary;
  }
}

.table-wrapper {
  &.scroll-x {
    width: 100%;
    overflow: scroll;
    position: relative;
    .sticky-left {
      position: sticky;
      left: 0;
    }
     tr td:first-child, tr th:first-child {
      position: sticky;
      left: 0;
      top: auto;
    }
  }
}

.dashboard-table > table {
  background: white;
  thead tr {
    border-bottom: 1px solid #eee;
    border-top: none;
  }

  thead tr td {
    padding: 15px 25px;
    color: #F8AB1D;
    text-align: left;
  }

  tr td {
    padding: 25px;
    font-size: 15px;
    line-height: 1.4;
    vertical-align: top;
  }

  td.important {
    border: 3px solid #fff;
  }
}

.invest-dash-table .form-group > .form-control {
  color: #799ca8;
  font-weight: 400;
  font-size: 15px;
}

.invest-dash-table td {
  padding-top: 0;
  padding-bottom: 0;
  min-width: 108px;
}

.invest-dash-table {
  margin-bottom: 15px !important;
}

.custom-table table {
  width: 100%;
  overflow: scroll;
}
.custom-table table tbody tr:first-child {
  padding: 10px;
  font-size: 14px;
  height: 40px;
  vertical-align: center;
  background-color:$primary !important;
  text-align: center;
  color: $color-base-5;
}

.custom-table table tbody tr:first-child td {
  border: none;
}

.custom-table table tbody tr:first-child td:first-child {
  border-top-left-radius: 7px;
}

.custom-table table tbody tr:first-child td:last-child {
  border-top-right-radius: 7px;
}

.custom-table table tbody tr {
  background-color: white;
}

.custom-table table tbody tr td {
  padding: 10px;
  font-size: 14px;
  line-height: 1.4;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.custom-table p img {
  max-width: 100% !important;
  height: auto;
}
