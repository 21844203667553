.has-error {
  .help-block {
    @extend .text-danger;
    width: 100%;
  }

  .radiobutton label {
    @extend .text-danger;
  }

  .form-control {
    @extend .border-danger !optional;
  }
}
