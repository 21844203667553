.product-comment {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-xs);
  &-author {
    display: flex;
    gap: var(--arkefip-spacer-xs);
    align-items: center;

    &-picture {
      display: flex;
      align-items: center;
      font-size: var(--arkefip-text-xl);
      color: var(--arkefip-def-light);
      width: 32px;
    }

    &-infos {
      display: flex;
      flex-direction: column;
      font-size: var(--arkefip-text-sm);
      line-height: 1.3;

      span:first-child {
        font-weight: bold;
      }
    }
  }

  &-body {
    padding: var(--arkefip-spacer-sm);
    border-radius: 0.25rem 1rem 1rem 1rem;
    background-color: var(--arkefip-def-background);
    font-size: var(--arkefip-text-sm);

    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }

  &-actions {
    display: flex;
    gap: var(--arkefip-spacer-xs);
    .btn i {
      margin-right: 4px;
    }
  }

  &.enerfip {
    align-items: flex-end;

    .product-comment {
      &-author {
        flex-direction: row-reverse;
        text-align-last: right;
      }
      &-body {
        background-color: var(--arkefip-pine-background);
        color: var(--arkefip-pine-primary);
        border-radius: 1rem 0.25rem 1rem 1rem;
      }
    }
  }
}