.arkefip-home-expertise {
  &-container {
    display: flex;
    padding: 0px 72px;
    flex-direction: column;
    gap: var(--arkefip-spacer-lg);

    @include media-breakpoint-down(md) {
      text-align: center;
      padding: 0px 34px;
      align-items: center;
    }
  }

  &-card {
    display: flex;
    padding: var(--arkefip-spacer-sm);
    padding-top: var(--arkefip-spacer-xl);
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    border-radius: 24px;
    background: var(--arkefip-def-background);

    @include media-breakpoint-down(md) {
      width: auto;
    }

    &-container {
      display: flex;
      gap: var(--arkefip-spacer-sm);
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    &-title {
      color: var(--arkefip-primary);
      text-align: center;
      font-family: var(--arkefip-font-headings);
      font-size: var(--arkefip-text-lg);
      line-height: 100%;
    }

    &-description {
      text-align: center;
      height: 4rem;
      max-height: 15vh;
    }

    &-image {
      height: 10rem;
      max-height: 26vh;
    }
  }
}
