.arkefip-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;

  &-icon {
    font-size: 3rem;
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    font-weight: 200;
    line-height: 1.2;

    span:last-child {
      font-weight: 400;
    }
  }

  &-subtitle {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    opacity: 0.8;
  }
}

.arkefip-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;

  &-large {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: var(--arkefip-spacer-md);
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  }
}

.arkefip-page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 720px;
  padding: 8rem 2rem 2rem 2rem;

  @include media-breakpoint-up(md) {
    padding-top: 10rem;
  }

  &-icon {
    width: 90%;
    max-width: 200px;
  }

  &-overtitle {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    color: var(--arkefip-tertiary);
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span:last-child {
      font-family: highest-praise, sans-serif;
      margin-top: -1rem;
      transform: rotate(-3deg);
      font-size: 120%;
      color: var(--arkefip-secondary);
    }
  }

  &-excerpt {
    text-align: center;
    opacity: 0.5;
    font-size: 1.2rem;
  }

  .logo {
    max-height: 100px;
  }
}

.arkefip-page-header-cover {
  display: flex;
  width: 100%;
  padding: var(--arkefip-layout-padding);

  &-double {
    display: flex;
    width: 100%;
    padding: var(--arkefip-layout-padding);

    .arkefip-page-header-cover-body {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

    }
  }

  &-body {
    display: flex;
    min-height: 80vh;
    align-items: center;
    width: 100%;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--arkefip-spacer-md);
  }

  &-image {
    background-size: contain;
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    display: flex;
    background-position: center;

    @include media-breakpoint-up(md) {
      height: 100%;
    }
  }

  &-overtitle {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    color: var(--arkefip-tertiary);
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    span:last-child {
      font-family: highest-praise, sans-serif;
      margin-top: -1rem;
      transform: rotate(-3deg);
      font-size: 120%;
      color: var(--arkefip-secondary);
    }
  }

  &-title-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;

    span:last-child {
      font-family: highest-praise, sans-serif;
      margin-top: -1rem;
      transform: rotate(-1deg);
      font-size: 120%;
      color: var(--arkefip-secondary);
    }
  }

  &-excerpt {
    text-align: left;
    opacity: 0.75;
    font-size: 1.2rem;
  }

  img {
    max-width: 100%;
  }
}

.arkefip-h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;

  strong {
    font-weight: 500;
  }
}


.page-header {
  @extend .bg-white !optional;

  &-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .hero {
    @extend .py-4;
    min-height: 33vh;
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-end;
    content: " ";

    @include media-breakpoint-up(sm) {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.9) 66%);
    }

    @include media-breakpoint-down(md) {
      background: rgba(255, 255, 255, 0.9);
    }
  }
}
