.data-tile {
  overflow: hidden;
  @extend .border;
  @extend .rounded;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .data-tile-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    padding: 1.2rem;
    background-position: center;
    background-size: cover;
    color: $white;
    outline: 1px solid #fff;
    i {
      font-size: 1rem;
      margin-right: 1.2rem;
      align-self: center;
    }
    .data-tile-data {
      font-size: 0.9rem;
      font-weight: 200;
      margin-bottom: 0;
    }
    .data-tile-text {
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

$data-pictures: bioenergie, coins, contract, digger, figures, handshake, hydro, map, pipes, people, performance, pollution, savings, solar, solar1, solar2, solar3, solar-wind, village, windmill-sea, windmill, windmill1, windmills, worker;

@each $data-picture in $data-pictures {
  .data-bg-#{$data-picture} {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../../assets/images/components/data-card-images/#{$data-picture}.jpg');
  }
}
