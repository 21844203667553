.steps-navigation-bottom {
  position: fixed;
  bottom: 20px;
  width: 300px;
  z-index: -1;

  p {
    font-size: 10px;
  }
}
.steps-navigation-sticky-wrapper {
  position: sticky;
  top: 105px;
}
.steps-navigation-wrapper {
  background: white;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid $border-color;
  z-index: 2;
  .fas, .fa {
    color: $color-base-80;
  }

  .info-title, .info-item {
    color: $color-base-100;
  }

  .info-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .info-item-title {
    font-weight: bold;
    padding-right: 5px;
  }

  // Generate `.step-navigation-wrapper.*` color utilities
  @each $key, $value in $palette-background-colors {
    &.#{$key} {
      @include alert-variant($value, map-get($palette-light-colors, $key), map-get($palette-text-colors, $key));
    }
  }

  .title {
    font-size: 14px;
    // padding: 10px;

    .title-text {
      padding-left: 10px;
      margin: 0;
      border-radius: 5px;
      padding: 10px;
      color: $color-base-80;

      &.hover:hover {
        background: $color-base-5;
        color: $color-base-100;
      }
    }

    &.active {
      .title-text {
        background: rgba($primary, 0.05);
        color: $primary;
        margin-bottom: 10px;
        .fas, .fa {
          color: $primary;
        }
      }
    }
  }

  .subtitle {
    font-size: 12px;
    color: $color-base-80;
    margin-left: 20px;
    display: block;
    padding: 10px;
    border-radius: 5px;

    a {
      color: $color-base-80;
    }

    &.hover:hover  {
      background: $color-base-5;
    }
    &.active {
      background: rgba($primary, 0.05);

      a {
        color: $primary;
        font-weight: bold;
      }
    }
  }
}


.step-footer-navigation {
  position: fixed;
  bottom: 10px;
  right: 10px;

  i {
    &.disabled {
      opacity: 0.2;
    }
  }
}

.mobile-navigation-wrapper {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 2px solid $border-color;
  height: auto;
  .steps-mobile-nav {
    padding: 10px 0;
    .info-title {
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      margin-bottom: 5px;
    }
    .info-item-title {
      font-weight: bold;
      margin-right: 5px;
    }
  }
  .steps-static-mobile-nav {
    background: white;
    padding: 10px;
    width: 100%;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    z-index: 100;
    &.fixed {
      position: fixed;
      top: 0;
    }
  }
}
