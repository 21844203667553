.arkefip-tab-menu {
  @extend .nav;
  @extend .nav-tabs;
  font-family: var(--arkefip-font-headings);
  font-size: var(--arkefip-text-lg);
  margin-top: 3rem;
  color: var(--arkefip-def-primary);
  border: none;
  display: flex;
  justify-content: space-around;

  .nav-link {
    padding: 10px 30px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-link.active {
    color: var(--arkefip-pine-primary);
    border-color: transparent;
    border-bottom: 2px solid var(--arkefip-pine-primary);
    border-radius: 0%;
    background-color: transparent;
  }

  .nav-link-icon {
    margin-right: 4px;
    width: 25px;
    border: 3px solid var(--arkefip-def-extralight);
  }

  @include media-breakpoint-down(lg) {
    overflow-x: auto;
    flex-wrap: nowrap;
    max-width: 100vw;
    padding: 10px;
    font-size: var(--arkefip-text-md);
    -webkit-overflow-scrolling: touch;
    /* Smooth scrolling on mobile */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar for IE/Edge */

    /* Optional to remove scroll bar in WebKit-based browsers */
    &::-webkit-scrollbar {
      display: none;
      /* Hide scrollbar for Chrome/Safari */
    }

    .nav-link {
      flex-wrap: wrap;
    }
  }
}