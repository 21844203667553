.arkefip-partners-intro {
  width: 100%;
  max-width: 900px;
  padding: var(--arkefip-spacer-md);
  margin: 0 auto;

  // layout
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  align-items: center;
  text-align: center;

  &-quote {
    font-family: var(--arkefip-font-headings);
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: 300;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }

    &-author {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-up(md) {
        align-items: flex-start;
      }

      &-signature {
        img {
          height: 2.5em;
          width: 100%
        }
      }

      &-status {
        padding: 0 2rem;
      }
    }
  }

  .arkefip-kpi {
    &-figure {
      transform: rotate(-7deg);
      &-large {
        font-family: var(--arkefip-font-handwriting);
        font-size: 5rem;
        line-height: 0.5;
        color: var(--arkefip-secondary);
      }
      &-medium {
        font-family: var(--arkefip-font-handwriting);
        font-size: 3rem;
        color: var(--arkefip-primary);
      } 
    }
    &-baseline {
      color: var(--arkefip-def-primary);
    }
  }
}