.ComponentsStepMessage--outer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ComponentsStepMessage--icon {
    width: 40px;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    border-radius: $border-radius-sm;
    margin-right: 10px;
  }

  .message-outer-title {
    font-size: 14px;
  }
}

.ComponentsStepMessage--wrapper {
  margin: 15px 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;


  // Generate `.ComponentsStepMessage--wrapper.*` color utilities
  @each $key, $value in $palette-background-colors {
    &.#{$key} {
      @include alert-variant($value, map-get($palette-light-colors, $key), map-get($palette-text-colors, $key));
    }
  }

  &.white {
    background-color: white;
  }
  &.bordered {
    border: 1px solid $secondary;
  }

  p {
    margin-bottom: 0;
  }

  .message-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .message-image {
    width: 48px;
    height: 48px;
  }

  .message-title {
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
  }

  .message-link {
    text-transform: uppercase;
    align-self: flex-end;

    i {
      margin-left: 10px;
    }
  }

  &.large {
    flex-direction: column;
    text-align: center;

    .message-title {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    text-align: center;
  }
}
