.arkefip-floating-idcard {
  @extend .arkefip-bg-leaves-action;
  position: fixed;
  bottom: 0;
  right: var(--arkefip-layout-padding);
  border-radius: 1rem 1rem 0 0;
  color: white;
  padding: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: var(--arkefip-shadow-lg);
  transition: all ease-in-out 0.2s;

  &:hover {
    color: white;
    padding-bottom: 2rem;

    .arkefip-idcard-cta {
      border: 1px solid white;
      color: white;
      background: transparent;
    }
  }
}

.arkefip-idcard {
  background-color: white;
  border-radius: 0.5rem;
  padding: var(--arkefip-spacer-sm);
  border: 1px solid var(--arkefip-def-extralight);

  &-h {
    display: flex;
    gap: var(--arkefip-spacer-sm);
    align-items: center;
  }

  &-picture {
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-name {
    font-family: var(--arkefip-font-headings);
    font-weight: 100;
  }

  &-title {
    font-size: 80%;
  }

  &-email {
    font-size: 80%;
  }

  &-cta {
    background-color: white;
    color: var(--arkefip-def-dark);
    border-radius: 10rem;
    padding: 0.25rem 0.5rem;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    margin-top: 1rem;
    transition: all ease-in-out 0.2s;
  }
}
