.product-excerpt {
  max-height: 250px;
  max-width: calc(960px - 2 * var(--arkefip-layout-padding) - 2* var(--arkefip-spacer-sm));
  overflow: hidden;
  position: relative;
  transition: all ease-in-out 1s;
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);

  @include media-breakpoint-up(xxl) {
    max-width: calc(1060px - 2 * var(--arkefip-layout-padding) - 2* var(--arkefip-spacer-sm));
  }

  h1, h2, h3, h4, h5 {
    font-family: var(--arkefip-font-body);
  }

  h3 {
    font-size: var(--arkefip-text-md);
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    padding: 0 var(--arkefip-spacer-md);
  }

  &::before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, white 10%, transparent 30%);
    display: flex;
    pointer-events: none; 
  }
}

.product-excerpt.expanded {
  max-height: none;
  overflow: visible;
}

.product-excerpt.expanded::before, .excerpt-read-more.expanded {
  display: none;
}