// border colors
.card, .card-footer, .card-header, .card-body {
}

.card, .card-footer, .card-header {
  p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.card-header:first-child, .card-body:first-child {
  border-radius: $border-radius-lg $border-radius-lg 0 0;
}

.card-footer:last-child, .card-body:last-child {
  border-radius: 0 0 $border-radius-lg $border-radius-lg;
}

.card {
  &-dark {
    background-color: $color-base-100;
    border-color: $color-base-100;
    color: $white !important;
    .card-header, .card-footer {
      border-color: $color-base-100;
      background-color: $color-base-100;
      p, h1, h2, h3, h4, h5, h6, a, pre {
        color: $white !important;
      }
    }
  }
  .card-link {
    text-decoration: none;
  }

  .card-body li {
    font-weight: 300;
  }

  .bank-account-details {
    background-color: $color-base-10;
    border-radius: .5rem;
    padding: .75rem;

    .account-owner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color-base-25;
      padding-bottom: .2rem;
      margin-bottom: 1rem;

      .content p {
        &:first-child {
          font-weight: bold;
        }
        &:last-child {
          font-weight: 300;
        }
      }
    }
  }

  .deposit {
    background-color: $color-base-10;
    border-radius: .5rem;
    padding: 1rem;
  }

  .deposit p {
    font-size: var(--arkefip-text-xs);
    font-weight: 300;
  }

  .download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .by-wire-info {
    display: flex;
    align-items: baseline;
  }

  .by-wire-info p {
    font-weight: 300;
  }
}


a.payment-mean-card-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $color-base-15;
  border-radius: .5rem;
  padding: .75rem;
  margin-bottom: .75rem;
  text-decoration: none;
  color: black;

  &:hover {
    background-color: $color-base-10;
    border-color: $color-base-25;
  }

  .description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      margin-top: .1em;
    }
  
    .content p {
      &:first-child {
        font-weight: bold;
      }
      &:last-child {
        color: $color-base-60;
        font-weight: 100;
      }
    }
  }
}