// form focus
.form-control:focus {
  color: $body-color;
  background-color: #fff;
  border-color: $primary;
  outline: 0;
  box-shadow: none;
}
select.form-control:focus::-ms-value {
  color: $body-color;
  background-color: #fff;
}

//form disabled
.form-control:disabled, .form-control[readonly] {
  opacity: 0.5;
}