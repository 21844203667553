.equity-simulator {
  text-align: center;

  input {
    width: 5em;
    color: black;
  }

  .simulator-result {
    font-size: 120%;
    margin-left: .3em;
  }
}
