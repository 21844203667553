.Markdown {
  ul {
    list-style-type: disc;
    margin: revert;
    padding: revert;
  }
}

.markdown-content {
  ul>li {
    list-style: disc;
    margin-left: 1em;
  }

  ul {
    margin: 1em 0;
  }
}
