.page-projects,
.page-project_previews {
  .like_cta {
   display: flex;

   .btn {
     border-radius: 0.25rem 0 0 0.25rem;
     white-space: nowrap;
   }

   #like_sentence {
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0 1rem;
     font-weight: bold;
     border-radius: 0 0.25rem 0.25rem 0;
   }

   &.like_active {
     #like_sentence {
       background: $light;
       color: $primary;
       border: 1px solid $primary;
     }
   }

   &.like_success {
     #like_sentence {
       background: $color-success-bg;
       color: $color-success;
       border: 1px solid $color-success;
     }
   }
 }
}
