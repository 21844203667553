.arkefip-home-invest-steps {
  &-container {
    display: flex;
    padding: 0px 72px;
    gap: 36px;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-md);

    @include media-breakpoint-down(md) {
      align-items: center;
    }
  }

  &-image {
    transform: rotate(16.502deg);
    width: 50%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &-steps {
    display: flex;
    padding-left: var(--arkefip-spacer-sm);
    flex-direction: column;
    gap: 36px;

    @include media-breakpoint-down(md) {
      padding: 0px var(--arkefip-spacer-md);
      text-align: center;
    }
  }

  &-button {
    align-self: center;
  }

  &-step {
    display: flex;
    gap: 34px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--arkefip-spacer-xs);
    }

    &-title {
      color: var(--arkefip-primary);
      font-family: var(--arkefip-font-headings);
      font-size: var(--arkefip-text-lg);
      
      @include media-breakpoint-down(md) {
        font-size: var(--arkefip-text-base)
      }
    }

    &-icon {
      color: var(--arkefip-primary);
      font-size: var(--arkefip-text-xl);
      width: 32px;
    }
  }
}