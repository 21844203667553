.AppSettings--card {
  @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
    font-size: smaller;

    .AppSettings--card-image {
      width: 80px;
    }
  }

  h4.AppSettings--card-title {
    font-size: 18px;
    margin-bottom: map-get($spacers, 1);

    @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
      font-size: 16px;
    }
  }

  &-subtitle {
    font-weight: lighter;
    color: $color-base-60;
    font-size: 14px;

    @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
      font-size: 12px;
    }
  }

  &-image {
    width: 100px;
    height: 100px;
    filter: grayscale(100%);
  }

  &-link {
    font-size: $btn-font-size;
  }
}
