// standard hover motion
.transition {
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.transition-speed {
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}
