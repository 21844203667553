#CTA--signup {

  .CTA--signup-content {
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    overflow: hidden;
    border-radius: map-get($spacers, 3);
    background: $primary;
    color: $white;
    align-items: center;

    @include media-breakpoint-up(md) {
      display: grid;
      -webkit-display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .CTA--signup-col {
      display: flex;
      -webkit-display: flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      padding: map-get($spacers, 3);

      @include media-breakpoint-up(md) {
        display: grid;
        -webkit-display: grid;
        padding: map-get($spacers, 5);
      }

      &-right {
        width: 100%;
        height: 100%;
        align-items: center;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .CTA--signup-list {
    li {
      border-bottom: 1px solid $primary;
      padding: map-get($spacers, 2) 0;
      display: flex;
      align-items: center;
      color: $white;
    }
    li:first-child {
      padding-top: 0;
    }
    li:last-child {
      border: none;
      padding-bottom: 0;
    }
    i {
      color: $color-success;
      margin-right: map-get($spacers, 2);
    }
    p {
      margin-bottom: 0;
    }
  }
}
