// Enerfip loader
// https://codepen.io/alexisenerfip/pen/YzPjBmW
// also see .spinner-border (bootstrap/scss/_spinner)
@keyframes enerfip-loader {
  to { transform: rotate(360deg); }
}

.enerfip-loader {
  background-image: url("../../assets/images/components/enerfip-loader.svg");
  width: $spinner-width;
  height: $spinner-height;
  animation: enerfip-loader 1.5s ease-in-out infinite;
}
