.AppComponents--tag {
  padding: calc(map-get($spacers, 1) / 2);
  margin: calc(map-get($spacers, 1) / 4);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  height: fit-content;
  width: fit-content;
  background-color: $color-base-5;
  color: $color-base-80;
  border-radius: $border-radius;
  &.danger {
    background-color: $color-danger-bg;
    color: $color-danger-text;
  }
  &.primary {
    background-color: $color-brand;
    color: white;
  }
  &.success {
    background-color: $color-success-bg;
    color: $color-success-text;
  }
  &.warning {
    background-color: $color-warning-bg;
    color: $color-warning-text;
  }
  &.info {
    background-color: $color-info-bg;
    color: $color-info-text;
    width: min-content;
    text-align: center;
  }
}