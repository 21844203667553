.page-projects,
.page-project_previews {
  .projects-header {
    select.btn-outline-primary {
      &:hover, &:focus {
        color: inherit;
        background-color: inherit;
      }
    }
  }
}
