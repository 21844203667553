.arkefip-minimal-header {
  position: sticky;
  z-index: 1005;
  top: 0;
  width: 100%;
  padding: var(--arkefip-spacer-sm);
  align-items: center;
  background: white;
  border-bottom: 1px solid var(--arkefip-def-extralight);
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &-back {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-self: flex-start;
    }
  }
}
