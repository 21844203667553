.product-main-content {
  .product-menu-navigation {
    background: white;
    position: sticky;
    top: 4rem;
    z-index: 100;
    width: 100%;
    padding: calc(var(--arkefip-spacer-sm));
    padding-top: calc(1.5* var(--arkefip-spacer-sm));
    margin: 10px 0;
    border-radius: 0;
    border-bottom: 2px solid $primary;
    display: inline-grid;
    .nav {
      overflow-x: auto;
      flex-wrap: nowrap;
    }
    .nav-item {
      flex: none;
    }
    @include media-breakpoint-down(md) {
      &:before {
        content: ' ';
        position: relative;
        width: 100%;
        height: 3.3rem;
        background: white;      
      }
      top: 0;
    }
    .nav-link {
      font-size: 14px;
      @include media-breakpoint-up(xxl) {
        font-size: 16px;
      }
    }
  }
}