.fundcall-distribution-table {
  .table {
    thead {
      th {
        @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
          font-size: 11px;
        }
      }
    }
    tbody {
      tr {
        td {
          @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
            font-size: 11px;
          }
        }
      }
    }
  }
}

.fundcall-distribution-nav {
  .nav-item {
    .nav-link {
      height: 100%;
      @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
        padding: 0;
      }
      .title {
        @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
          font-size: 1em;
          margin: 0;
          padding: 1em;
        }
      }
    }
  }
}
