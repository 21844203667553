.catalog-section {
  &-header {
    display: flex;
    align-items: center;
    gap: var(--arkefip-spacer-sm);

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--arkefip-def-primary);
      color: white;
      border-radius: 100%;
      width: 32px;
      height: 32px
    }
  }

  &-innovation {
    .catalog-section-showcase {
      display: flex;
      flex-direction: column;
      gap: var(--arkefip-spacer-sm);
      border-radius: 1rem;
      padding: var(--arkefip-spacer-sm);
      background-color: var(--arkefip-plum-background);

      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    .catalog-section-header-icon {
      background-color: var(--arkefip-plum-primary);
    }
  }
}