.arkefip-home-figures {
  padding: 0px var(--arkefip-spacer-md);
  width: 100%;
  
  &-container {
    position: relative;
    display: flex;
    padding: 36px 94px;
    margin: 0 12px;
    gap: 36px;
    justify-content: space-between;
    border-radius: 18px;
    background: var(--arkefip-primary);
    box-shadow: 6px 9px 36px 0px rgba(0, 0, 0, 0.10);
    align-items: flex-end;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(../../assets/images/graphics/circles.svg);
      background-repeat: no-repeat;
      opacity: 0.25;
      left: 0;
    }
    
    @include media-breakpoint-down(md) {
      padding: 36px;
      margin: 0 auto;
      flex-direction: column;
      align-items: center;
      height: 550px;
      gap: 24px;
      text-align: center;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #FFF;
    font-family: var(--arkefip-font-headings);
    &-figure {
      font-size: 72px;
      line-height: 90%;

      @include media-breakpoint-down(md) {
        font-size: 58px;
      }
      span {
        font-size: 48px;
        line-height: 90%;
        font-weight: lighter;

        @include media-breakpoint-down(md) {
          font-size: 40px;
        }
      }
    }
    &-label {
      font-size: var(--arkefip-text-lg);

      @include media-breakpoint-down(md) {
        font-size: var(--arkefip-text-md);
      }
    }
    &-note {
      color: rgba(255, 255, 255, 0.75);
      font-size: var(--arkefip-text-lg);

      @include media-breakpoint-down(md) {
        font-size: var(--arkefip-text-md);
      }
    }
  }
}
