.arkefip-brick {
  background-color: white;
  border-radius: 0.5rem;
  padding: var(--arkefip-spacer-sm);
  border: 1px solid var(--arkefip-def-extralight);
  display: flex;
  gap: var(--arkefip-spacer-sm);
  align-items: center;

  &-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    background: var(--arkefip-def-extralight);
    color: var(--arkefip-def-primary);
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}