.arkefip-footer-minimal {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--arkefip-def-light);
  justify-content: center;
  align-items: center;
  padding: var(--arkefip-spacer-sm);

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &-psfp {
    display: flex;
    align-items: center;
    gap: var(--arkefip-spacer-sm);
    max-width: 600px;

    img {
      height: 2rem;
    }
  }
}
