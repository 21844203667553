// Inspired by: https://dev.to/madsstoumann/using-a-single-input-for-one-time-code-352l

.otp-attempt {
  input {
    --otp-digits: 6;
    --otp-ls: 3ch;
    --otp-gap: 1.25;
  
    /* private consts */
    --_otp-bgsz: calc(var(--otp-ls) + 1ch);
    --_otp-digit: 0;
  
    all: unset;
    height: 1.5em;
    background: 
    linear-gradient(90deg, 
      var(--otp-bg, #BBB) calc(var(--otp-gap) * var(--otp-ls)),
      transparent 0),
      linear-gradient(90deg, 
      var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),
      transparent 0
    );
    background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
    background-repeat: no-repeat, repeat-x;
    background-size: var(--_otp-bgsz) 100%;
    caret-color: var(--otp-cc, #222);
    caret-shape: block;
    clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
    font-family: ui-monospace, monospace;
    font-size: 1.5rem;
    inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
    letter-spacing: var(--otp-ls);
    padding-block: var(--otp-pb, 1ch);
    padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));
    @include media-breakpoint-down(md) {
      --otp-ls: 2.5ch;
      font-size: 1.25rem;

    }
  }
}