.timeline {
  @extend .border-top ;
  @extend .border-bottom ; 
  @extend .py-3 ;
  .timeline-item {
    @extend .row ;
    @extend .d-flex ;
    .timeline-item-title {
      @extend .col-4 ;
      @extend .h6 ;
      @extend .border-end ;
      @extend .mb-0 ;
      text-align: right;
    }
    .timeline-item-body {
      position: relative;
      @extend .col-8 ;
      margin-top: -1px;
      @extend .pb-3 ;
      &::before {
        font-family: "Font Awesome 6 Free";
        content: "\f192";
        font-weight: 400;
        font-style: normal;
        color: $primary;
        left: -0.4rem;
        position: absolute;
      }
    }
    &:last-of-type {
      .timeline-item-body {
        padding-bottom: 0 !important;
      }
      &::after {
        content: " " !important;
      }
    }
  }
  p, span {
    margin-bottom: 0;
  }
}
