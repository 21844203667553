@import "landing/index";
@import "navbars/index";
@import "project/index";

@import "notie";
@import "account_cta";
@import "account_switcher";
@import "admin_panel";
@import "advisory";
@import "alerts";
@import "announce_banner";
@import "app_assistance";
@import "ariane";
@import "braced_heading";
@import "brand";
@import "brick";
@import "btn_country";
@import "buttons";
@import "button_icon";
@import "cards";
@import "catalog";
@import "categories";
@import "certificate_card";
@import "cgu_acceptance";
@import "copy_to_clipboard";
@import "arkefip_chat";
@import "container";
@import "corporate_service_card";
@import "cta_signup";
@import "data_tile";
@import "discount";
@import "electronic_doc_form";
@import "energy";
@import "engagement_card";
@import "fisc_situation_info";
@import "floating_id_card";
@import "footer_minimal";
@import "footer_regulators";
@import "footer";
@import "front_header";
@import "has_error";
@import "header_minimal";
@import "header";
@import "help_modal";
@import "highlights";
@import "info_banner";
@import "interaction";
@import "investor_app_title";
@import "jumbotron";
@import "language_dropdown";
@import "like_cta";
@import "livret";
@import "loaders";
@import "map_outgrid";
@import "modal";
@import "new_account_step";
@import "otp_attempt_input";
@import "overlay";
@import "panel";
@import "pcards";
@import "portfolio_tab";
@import "pulse";
@import "rgpd_panel";
@import "sale_offer_card";
@import "satisfaction_form_wrapper";
@import "settings_card";
@import "settings_sidebar";
@import "share_oauth";
@import "slide";
@import "space_header";
@import "arkefip_sponsorship";
@import "step_item";
@import "step_messages";
@import "steps_navigation";
@import "support";
@import "tagbar";
@import "tags";
@import "timeline";
@import "toast";
@import "waiting_cta";
@import "breadcrumb";
@import "round_icon";
@import "popover";
@import "signatures_iframe";
@import "arkefip_tab_menu";

// Yet to find a place
.arkefip-deco-spark {
  position: relative;

  &:hover {
    &::before {
      // filter: brightness(0.8) sepia(1) hue-rotate(0deg) saturate(4);
      filter: opacity(1) brightness(0.4) sepia(0.8) hue-rotate(65deg) saturate(3);
      transform: translateX(-0.25rem) translateY(-0.25rem);
    }

    &::after {
      // filter: brightness(0.4) sepia(1) hue-rotate(170deg) saturate(2);
      filter: opacity(1) brightness(0.4) sepia(0.8) hue-rotate(65deg) saturate(3);
      transform: translateX(0.25rem) translateY(0.25rem);
    }
  }

  &::before {
    content: "";
    background-image: url(../../assets/images/graphics/pen-angles-01.svg);
    background-blend-mode: overlay;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: -1rem;
    top: -1rem;
    filter: opacity(0.8);
    transition: all ease-in-out 0.2s;
  }

  &::after {
    content: "";
    background-image: url(../../assets/images/graphics/pen-waves-01.svg);
    background-blend-mode: overlay;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    width: 3rem;
    height: 2rem;
    position: absolute;
    right: -1.8rem;
    bottom: -1.2rem;
    filter: opacity(0.8);
    transition: all ease-in-out 0.2s;
  }
}

.honey-field {
  // honeypot form field
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

#whatsapp-phone-form {
  @extend .transition;

  &.no-height {
    height: 0;
    overflow: hidden;
  }
}

#arkefip-app-main {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--arkefip-layout-padding);
  padding-bottom: 120px;
  flex-grow: 1;
}

.users_projects_container {
  background: $color-brand-light;
  padding-top: map-get($spacers, 3);
  min-height: 70vh;
}

header {
  #main-menu {
    .navbar-brand img.white-brand-img {
      height: 37px;
      margin-top: 0;
      margin-bottom: 0;
    }

    .main-nav-why,
    .main-nav-about {
      display: block;
    }
  }
}

header {
  @include media-breakpoint-up(md) {
    .navbar {
      font-size: larger;
    }
  }

  .nav-item-divider {
    &:after {
      content: ""; // "\2022"; // "&bull;";
      display: block;
      padding: 0.5rem;
      font-weight: bold;
    }
  }

  #main-menu {
    background-color: white;
    // height: 80px;

    .navbar-brand img {
      height: 25px;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    .navbar-toggler {
      border: none;
    }

    .nav-link {
      white-space: nowrap;
      font-size: 0.9em;
      @extend .transition-speed !optional;

      &:hover,
      &.active {
        color: $dark;
      }

      &.nav-link-private {
        color: $color-private-shadow !important;

        &:hover,
        &.active {
          color: $color-private-light !important;
        }
      }

    }


    .nav-item-divider {
      &:after {
        content: ""; // "\2022"; // "&bull;";
      }
    }
  }

  #main-menu-mobile {
    background-color: white;

    .navbar-brand img {
      height: 25px;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    .navbar-toggler {
      border: none;
    }

    .nav-item {
      font-size: 1rem;
      a {
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba($black, 0.2) !important; // $box-shadow-sm
  }
}

main {
  // padding-top: $header-offset;
  //
  // // cf header navbar.py-0.py-md-2
  // @include media-breakpoint-down(md) {
  //   padding-top: $header-offset-small;
  // }

  .main-sidebar {
    z-index: 100;
    /* Behind the header */
    padding: 0;
    @extend .border-end;
    @extend .border-bottom;
    background: $white;
    border-radius: 0 0 1rem 0;

    .sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
      top: $header-margin;
      height: calc(100vh - #{$header-margin});
      overflow-x: hidden;
      overflow-y: auto;
      padding: 1.5rem;
      /* Scrollable contents if viewport is shorter than content. */
    }

    // @include media-breakpoint-down(md) {
    //   display: block !important;
    //
    //   .sidebar-sticky {
    //     height: auto;
    //   }
    // }
  }
}

// bump 1
// used in francemap javascript
:root {
  --app-color-brand-dark: #{inspect($color-brand-dark)};
  --app-color-brand: #{inspect($color-brand)};
  --app-color-brand-border: #{inspect($color-brand-border)};
  --app-color-brand-light: #{inspect($color-brand-light)};
  --app-color-brand-bg: #{inspect($color-brand-bg)};
  --app-color-brand-links: #{inspect($color-brand)};
}

// Make sure it's used
// .arkefip-legacy-spacer {
//   margin-bottom: 6rem;

//   @include media-breakpoint-up(md) {
//     margin-bottom: 8rem;
//   }
// }

// .arkefip-underline {
//   filter: opacity(1) brightness(0.4) sepia(0.8) hue-rotate(65deg) saturate(3);
//   width: 4rem;
// }
