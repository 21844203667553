.arkefip-theme-cover {
  // background-image: linear-gradient(rgba($black,0), rgba($black,0.5)), url("../../assets/images/pictures/landscape.webp");
  background-color: $color-brand;
  // min-height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  align-items: flex-start;
  padding: 7rem 2rem 2rem 2rem;
  margin: 0 2rem;
  border-radius: 1rem;

  @include media-breakpoint-up(md) {
    align-items: flex-end;
    justify-content: space-between;
    padding: 8rem 2rem 2rem 2rem;
    flex-direction: row;
  }

  &-brand {
    display: flex;
    align-items: center;
    gap: 1rem;

    &-logo {
      display: flex;
      width: 64px;
      height: 64px;
      align-items: center;
      justify-content: center;
      padding: 4px;
      overflow: hidden;
      border-radius: 10rem;
      background-color: white;
      box-shadow: 0 0 0.25rem rgba(black, 0.5);

      img {
        width: 100%;
      }
    }
  }

  h1 {
    margin-bottom: 0;
    text-shadow: 0 0 0.25rem rgba(black, 0.5);

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    span {
      white-space: pre;
    }
  }

  p {
    text-shadow: 0 0 0.25rem rgba(black, 0.5);
    margin-bottom: 0;
    font-size: 16px;
  }

  &-navigation {
    display: flex;
    gap: 1rem;
  }
}

.page-projects-index,
.page-projects-show {
  .arkefip-theme-cover {
    margin-top: 2rem;
  }
}

.page-project-show .arkefip-theme-cover {
  margin-bottom: 0;
}
