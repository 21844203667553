.arkefip-slide {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-md);

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--arkefip-spacer-lg);
  }
}

.arkefip-slide-cover {
  border-radius: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  height: 40vh;
  order: 3;

  @include media-breakpoint-up(md) {
    height: 100%;
    order: initial;
  }
}

.arkefip-slide-content {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-md);
}

.arkefip-slide-content-headline {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);

  h3 {
    margin: 0;
    color: var(--linen-darker-text, #423E38);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.72px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span:last-child {
      font-weight: 500;
    }
  }

  p {
    margin: 0;
    color: var(--linen-primary, #736F67);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    /* 30px */
    letter-spacing: -0.48px;
  }
}

.arkefip-slide-content-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.arkefip-slide-content-group-cta {
  display: flex;
  gap: var(--arkefip-spacer-sm);
  align-items: center;
  padding: var(--arkefip-spacer-sm);
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--linen-primary, #736F67);
}

.arkefip-slide-content-group-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--arkefip-spacer-xs);
  flex: 1 0 0;
  align-self: stretch;
}

.arkefip-slide-content-group-btn-label {
  color: var(--linen-primary, #736F67);
  /* Button/Label 16L */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.arkefip-slide-content-group-btn-sublabel {
  color: var(--linen-primary, #736F67);
  font-style: normal;
  line-height: normal;
}
