// Contents
//
// body.page-pages .who-are-we

.who-are-we {
  h1 {
    font-weight: 300;
  }

  p {
    font-size: $h4-font-size; // $font-size-base * 1.5; // larger;
  }

  &--why-invest, &--team {
    font-size: 13px;

    img {
      width: 100%;
    }
  }

  &--team-members {
    p {
      font-size: inherit;
    }
  }

    .role-text {
      width: 90%;
      text-align: center;
      margin: auto;
      margin-top: 3rem;
      font-size: 1.2rem;
  }
}

.section_head {
  margin-top:100px;

  .section_img_head {
    display:inline-block;
    margin-top:-91px;
    margin-top:-155px;
    position:relative;
    top:-33px;
  }
}


// .team-member-div
.page-pages-who_are_we  {
  .team-section {
    @extend .p-5 ;
    .team-member-div {
      margin-top: 2em;
      .team-member-img {
        margin-top: 2em;
        padding: 0;
        width: 150px;
        height: 150px;
        margin: auto;
        @include media-breakpoint-down(md) {
          width: 120px;
          height: 120px;
        }  
        
      }
      h1 {
        @extend .mb-3;
      }
      h3 {
        margin-top: 20px;
        padding: 0;
        text-align: center;
      }

      h4 {
        text-align: center;
        font-style: italic;
        font-weight: lighter;
        font-size: var(--arkefip-text-md);
        font-family: $font-family-sans-serif;
        margin-bottom: 8px;
      }

        @include media-breakpoint-down(md) {
          padding: 1em;
        }    
    }
     .team-members-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }
  .team-founders {
    @extend .arkefip-bg-leaves-primary;
    @extend .rounded;

    h1 {
      text-align: center;
    }

    p {
      font-size: var(--arkefip-text-sm);
    }

    a i {
      color: white;
    }

    .team-members-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 2rem;

      @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr 1fr;
      }

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
      }
    }
    h1, h2, h3 {
      color: $white;
    }
    h4 {
      color: $color-base-15;
    }
    p {
      color: $color-base-5;
    }
  }
  .team-project, .team-customers, .team-application, .team-supports, .team-communication {
    h4 {
      color: $color-base-60;
    }
  
    @include media-breakpoint-down(md) {
      h1 {
       text-align: center;
       font-size: var(--arkefip-text-xl);
     }
   }
  }
}
