.section-features {
  //dimensions
  padding-top: 4rem;
  width: 100%;

  // layout
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  &-header {
    // layout
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-gap: 1.5rem;
  }

  &-image {
    width: 100%;  }
}
