.arkefip-front-header {
  display: none;

  @include media-breakpoint-up(md) {
    z-index: 995;
    position: sticky;
    display: flex;
    top: 0;
    right: 0;
    width: 100%;
    background: white;
    box-shadow: var(--arkefip-shadow-sm);
    display: flex;
    justify-content: flex-end;
  }

  &-navigation {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    margin-right: 3rem;

    &-item {
      background: transparent;
      color: var(--arkefip-def-dark);
      padding: 0.5rem 1rem;
      transition: all ease 0.2s;
      border-radius: 0.2rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: var(--arkefip-def-secondary);
      }
    }
  }
}

.arkefip-language-chooser-container {
  margin-right: 1rem;
}
.arkefip-front-header-dropdown {
  &-menu {
    flex-direction: column;
    min-width: 300px;
    border: none;
    background: white;
    padding: 0.5rem;
    box-shadow: var(--arkefip-shadow-lg);

    &.show {
      display: flex !important;
    }

    &-divider {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0.5rem 0;

      &::before {
        display: flex;
        content: '';
        border-top: 1px solid var(--arkefip-def-extralight);
        width: 100%;
      }
    }

  }

  .arkefip-front-header-navigation-item::after {
    content: '+';
    margin-left: -0.5rem;
    font-weight: bold;
    opacity: 0.5;
  }

  &.dropdown-toggle::after {
    display: none;
  }
}

.arkefip-front-header-button {
  border: 1px solid var(--arkefip-def-secondary);
  padding: 0.25rem;
  display: flex;
  align-items: center;
  border-radius: 10rem;
  background-color: white;
  transition: all ease 0.2s;
  gap: 0.5rem;
  cursor: pointer;

  .fa-bars, label {
    margin-right: 0.5rem;
  }

  &:hover {
    box-shadow: 0 0 0 0.05rem var(--arkefip-interaction);
    border-color: var(--arkefip-interaction);
  }
  
  
  &.vertical {
    display: block;
    border-radius: 1.3rem;
    border-color: var(--arkefip-interaction);
    .language-dropdown-label {
      width: 0;
      overflow: hidden;
      transition: all .5s ease-out;
    }
    .language-dropdown-item {
      width: 32px;
      height: 32px;
      padding: 8px 4px;
      margin-bottom: .5rem;
      transition: all .2s ease-out;
    }
    img { width: 24px !important; height: 24px !important; }
    li {
        &:first-child .language-dropdown-item { border-radius: 1.3rem 1.3rem 0 0; }
        &:last-child .language-dropdown-item {
          border-radius: 0 0 1.3rem 1.3rem;
          margin-bottom: 0;
        }

      }
    &:hover {
      .language-dropdown-item {
        border-radius: 0;
        width: auto;
        transition: all .5s ease-out;
      }
      .language-dropdown-label {
        width: auto;
        padding-right: 1rem;
        transition: all .5s ease-out;
      }
    }
  }

  &.dropdown-toggle::after {
    display: none;
  }

  &-icon {
    // layout
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--arkefip-interaction);
    color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 10rem;
    font-size: 1.2rem;
  }
}

a.language-dropdown-item {
  display:flex; 
  align-items: center;
  gap: var(--arkefip-spacer-xs); 
}