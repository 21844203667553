.arkefip-account-switcher-trigger {
  display: flex;
  align-items: center;
  padding: var(--arkefip-spacer-xs);
  border: 1px solid var(--arkefip-def-primary);
  border-radius: 10rem;
  transition: all 0.3s ease;
  &:hover {
    background: var(--arkefip-def-extralight);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }


  @include media-breakpoint-up(md) {
    width: 100%;
    justify-content: space-between;
    padding: var(--arkefip-spacer-xs);
  }

  &-label {
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--arkefip-def-dark);

    @include media-breakpoint-up(md) {
      width: auto;
      flex-grow: 1;
    }
  }
}

.arkefip-mobile-sidesheet-body {
  a {
    @extend .py-1;
    color: var(--arkefip-def-dark);
    display: block;
    &.danger { color: darken(red, 10%); }
    transition: all 1s ease;
    background: white;
    &:hover {
      transition: all .5s ease;
      background: darken(white, 3%);
    }
  }
  a.active {
    color: var(--arkefip-primary);
    .picture {
      color: var(--arkefip-primary);
      border-color: var(--arkefip-primary);
    }
  }
  .badge {
    float: right;
  }
  .fas {
    @extend .rounded-circle;
    @extend .p-1;
    border: 1px solid var(--arkefip-def-dark);
    display: inline-block;
    text-align: center;
    width: 2rem;
    margin-right: .25rem;
  }
}

#arkefipAccountSwitcherSidesheet {
  .offcanvas-header {
    display: block;
  }
  .btn-close { float: right; }
  .AppComponents--tag {
    float: right;
    display: inline-block;
  }
}

.arkefip-app-sidebar-navigation-bottom {
  font-style: italic;
  font-size: 90%;
  text-align: center;
  line-height: 1.2rem;
  color: var(--arkefip-def-dark);

  .arkefip-round-icon .picture{
    margin-top: -.8rem;
  }
  .arkefip-round-icon.with-badge {
    font-style: normal;
    top: .7rem;
    height: 0;
    line-height: 0;
    position: relative;
    &::after {
      left: 0.5rem;
      top: -1.5rem;
    }
  }
}
