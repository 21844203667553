#new_account_page {
  .start-title {
    margin: 40px 0;
    text-align: center;
    font-weight: bold;
  }

  .start-input-wrapper {
    @extend .border;
    background: white;
    padding: 10px;
    border-radius: $border-radius-lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 300px;
    text-align: center;
    margin-bottom: 10px;

    .start-input-title {
      height: 50px;

      h4 {
        font-weight: bold;
        font-size: 16px;
      }
    }

    i {
      font-size: 30px;
    }
  }
}
