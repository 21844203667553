.flash_countdown {
  display: block;

  .time {
    @include border-radius($border-radius-sm);
    width: 2.5em;
    overflow: hidden;
    display: inline-table;
    margin: .1em;
    padding: 5px;
    background-color: var(--arkefip-def-primary);
    color: $white;
    font-weight: normal;

    span {
      font-weight: normal;

      &.count {

      }

      &.label {
        display: block;
        text-align: center;
        font-size: 50%;
      }
    }
  }
}
