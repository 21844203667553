.arkefip-home {
  // layout
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 104px;

  @include media-breakpoint-up(xxl) {
    max-width: var(--bs-breakpoint-xxxl);
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {
    gap: 74px;
  }

  // default text style
  font-size: var(--arkefip-text-base);
  color: var(--arkefip-def-dark);

  p {
    font-weight: lighter;
  }

  @include media-breakpoint-down(md) {
    font-size: var(--arkefip-text-md);
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: var(--arkefip-font-headings);
    color: var(--arkefip-primary);
    font-weight: 200;
  }

  h2.section-heading {
    font-size: var(--arkefip-text-xxl);
    @include media-breakpoint-down(md) {
      font-size: var(--arkefip-text-xl);
    }
  }
}

.arkefip-home {
  &-cover {
    padding: var(--arkefip-layout-padding);
    overflow: hidden;
    width: 100%;

    &-container {
      overflow: hidden;
      border-radius: 1rem;
      position: relative;
      width: 100%;
      height: 80vh;
      min-height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
      z-index: 1;
    }

    h1 {
      color: white;
      font-size: 2rem;
      text-align: center;
      text-shadow: 0 0 1rem rgba(black, 0.4);

      @media (min-width: 800px) {
        font-size: 3rem;
      }
    }
  }
}

.arkefip-video-container {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;

  iframe {
    height: 100%;
    transform: scale(1.2) translate(-25%, 0);
    width: 150vh;

    @media (min-width: 960px) {
      transform: scale(1.2) translate(0);
    }
    @media (min-width: 1080px) {
      width: 100%;
      transform: scale(1.2) translate(0);
    }
  }
}

.arkefip-video-object {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

.arkefip-video-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.arkefip-home-features {
  width: 100%;
  &-showcase-grid {
    display: grid;
    padding: var(--arkefip-layout-padding);
    grid-template-columns: 1fr;
    grid-gap: var(--arkefip-layout-padding);

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    &-item {
      display: flex;
      border-radius: var(--arkefip-layout-padding);
      height: 50vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
