.outgrid {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 49%;
  background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 0;
}

.outgrid_div {
  @extend .outgrid;
  background: $blue;
}
