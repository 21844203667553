.arkefip-sponsorship {
  background-color: white;
  border: 1px solid var(--arkefip-def-extralight);
  border-radius: var(--arkefip-spacer-sm);
  padding: var(--arkefip-spacer-sm);
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-xs);
  font-size: var(--arkefip-text-sm);

  &-compact {
    flex-direction: row;
    align-items: center;
  }

  &-title {
    font-weight: bold;
  }

  &-code {
    display: flex;
    gap: var(--arkefip-spacer-sm);
    align-items: center;

    &-input {
      flex-grow: 1;

      input {
        padding: 0.25rem;
      }
    }
  }
}