.AppPortfolio {
  &--tab-item {
    .tab-link {
      padding:  map-get($spacers,1) map-get($spacers,1)  map-get($spacers,4)  map-get($spacers,1);
      margin-right: map-get($spacers,1);
      color: $color-base-80;
      font-size: larger;
      position: relative;
      &:hover {
        color: $primary; 
        font-weight: bold;
      }
      &.active {
        color: $primary; 
        border-bottom: 2px solid;
        font-weight: bold;
      }
      .tab-link-counter {
        position: absolute;
        right: -12px;
        font-size: smaller;
        height: 18px;
        width: 18px;
        font-weight: bold;
        text-align: center;
        color: $color-success-text;
        background: $color-success-bg;
        border-radius: 50%;
      }
    }
  }
}