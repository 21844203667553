// @use "sass:math";

// ComponentsPcards-s are new project cards
.ComponentsPcards {
  &--grid {
    display: flex;
    flex-direction: column;
    grid-gap: 1.125rem;
    @include media-breakpoint-up(md) {
      display: grid;
      grid-gap: 1.125rem;
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-gap: 1.125rem;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &--banner2x {
    @extend .arkefip-bg-leaves;
    @extend .arkefip-bg-leaves-primary;
    border: $border-width solid $border-color;
    border-radius: map-get($spacers,2);
    padding: map-get($spacers,1);
    max-width: 200%;
    overflow: hidden;
    grid-column: span 2;
    color: white;
    display: flex;
    flex-direction: column;
    grid-gap: 0.75rem;
    height: 100%;
    padding: map-get($spacers,2);
    .card-header {
      margin-bottom: 1rem;
    }
    .help-block {
      color: var(--arkefip-terra-background) !important;
    }
    .btn-ghost {
      color: var(--bs-btn-hover-color);
      text-decoration: underline;
    }
  }
  &--card {
    border: $border-width solid $border-color;
    border-radius: map-get($spacers,2);
    padding: map-get($spacers,2);
    background: white;
    max-width: 100%;
    overflow: hidden;

    .content {
      display: flex;
      flex-direction: column;
      grid-gap: map-get($spacers,2);
      height: 100%;
      .owner {
        .owner-logo-wrapper {
          box-shadow: $box-shadow-sm;
          border-radius: 100%;
          margin-right: map-get($spacers,1);
          width: 36px;
          height: 36px;
          padding: 6px;
          position: relative;
          cursor: help;
          flex-shrink: 0;
          .owner-logo {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            width: 100%;
          }
          .owner-flag {
            border-radius: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 12px;
            transition: all ease-in-out 0.2s;
          }
          &:hover {
            .owner-flag {
              width: 100%;
            }
          }
        }
        .owner-content {
          .owner-name {
            overflow: hidden;
            height: 1.25rem;
            a {
              font-size: var(--arkefip-text-sm);
              font-weight: 700;
              text-overflow: ellipsis;
            }
          }
          .owner-space {
            display: flex;
            align-items: center;
            .ComponentsPcards--space-prepend {
              span {
                font-size: 0.688rem;
                color: $color-base-25;
              }
            }
            .ComponentsPcards--space-label {
              border-radius: $border-radius-pill;
              background: $light;
              color: $primary;
              padding: 0.125rem 0.375rem;
              span {
                font-size: 0.688rem;
              }
            }
          }
        }
      }
      .cover {
        overflow: hidden;
        border-radius: map-get($spacers,2);
        text-decoration: none;
        .cover-picture {
          padding: map-get($spacers,2);
          height: 9rem;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          justify-content: flex-end;
          align-content: flex-start;
          flex-wrap: wrap;
          transition: all ease-in-out 0.2s;
        }
        &:hover {
          .cover-picture {
            filter: grayscale(1);
          }
        }
        .cover-heading {
          background: $secondary;
          padding: map-get($spacers,2);
          .already-invested {
            text-align: right;
            float: right;
            margin-top: -1.5em;
            padding: 0;
            span {
              display: inline-block;
              text-align: center;
              background: var(--arkefip-terra-background);
              color: #fff;
              border-radius: 30px;
              width: 1.8em;
              border: 2px solid #fff;
              margin-right: .2em;
              position: relative;
              z-index: 1000;
              &.investor-0 { background: #388; }
              &.investor-1 { background: #383; }
              &.investor-2 { background: #883; }
              &.investor-3 { background: #338; }
              &.investor-4 { background: #838; }
              &.investor-5 { background: #833; }
            }
          }
          .cover-heading-content {
            .status-wrapper {
              display: flex;
              align-items: center;
              .status {
                font-size: 0.563rem;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.031rem;
                margin-right: map-get($spacers,1);
                @include media-breakpoint-down(md) {
                  display: none;
                }
                &-active {
                  color: var(--arkefip-primary);
                }
                &-finished {
                  color: var(--arkefip-def-primary);
                }
                &-pre_published {
                  color: var(--arkefip-honey-primary);
                }
                &-to_come {
                  color: var(--arkefip-ocean-primary);
                }
                &::before {
                  content: "•";
                  margin-right: 0.25rem;
                }
              }
            }
            .title {
              overflow: hidden;
              height: 1.25rem;
              color: $color-base-100;
              span {
                text-overflow: ellipsis;
                font-family: $headings-font-family;
                font-weight: 300;
                font-size: 0.875rem;
              }
            }
          }
          .cover-heading-score {
            margin-left: auto;
          }
        }
      }
      .info {
        display: flex;
        flex-wrap: wrap;
        margin: 0 map-get($spacers,1);
        flex-grow: 1;
        .info-item {
          width: 50%;
          font-size: 0.688rem;
          i {
            margin-right: 0.375rem;
            color: $color-base-25;
            font-size: 0.563rem;
          }
          span, p, a {
            font-size: 0.688rem;
          }
        }
      }
      .details {
        background: $secondary;
        border-radius: map-get($spacers,2);
        padding: map-get($spacers,2);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .details-item {
          width: 33%;
          display: flex;
          flex-direction: column;
          margin-bottom: map-get($spacers, 1);
          .details-item-label {
            font-weight: 400;
            font-size: 0.563rem;
            color: $color-base-80;
            text-transform: uppercase;
          }
          .details-item-info {
            font-size: 0.625rem;
            font-weight: 700;
          }
        }
      }
      .cta {
        grid-gap: map-get($spacers,1);
        display: flex;
        .cta-eligibility-wrapper {
          display: flex;
          grid-gap: map-get($spacers,1);
          cursor: help;
          @include media-breakpoint-down(md) {
            .ComponentsButtons--icon {
              display: none;
            }
          }
        }
        .cta-action-wrapper {
          width: 100%;
          display: grid;
        }
        .cta-liker-wrapper {
        }
      }
      .countdown-wrapper {
        display: flex;
        border: $border-width solid $secondary;
        border-radius: $border-radius;
        .countdown-prepend {
          color: $color-base-25;
        }
        .countdown-widget {
          margin-left: map-get($spacers,1);
          .countdown-widget-label {
            font-weight: 400;
            font-size: 0.563rem;
            color: $color-base-80;
            text-transform: uppercase;
          }
          .countdown-widget-countdown {
            display: flex;
            grid-gap: map-get($spacers,1);
            span {
              font-size: 0.625rem;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
