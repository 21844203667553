.Event--main {
  .Event--hero-background {
    position: absolute;
    z-index: -1;
    top: 0;
  }
  .Event--content-wrapper {
    margin: 8vh auto 2rem auto;
    background: $white;
    border-radius: map-get($spacers, 2);
    width: 90%;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      width: 66%;
      margin-top: 25vh auto 0 auto;
      box-shadow: $box-shadow-lg;
    }

    .Event--banner {
      display: flex;
      width: 100%;
      border-bottom: 1px solid $border-color;
      align-items: center;
      justify-content: space-between;

      .Event--banner-content {
        display: flex;
        align-items: center;

        .Event--date-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: map-get($spacers, 3);
          border-right: 1px solid $border-color;

          .Event--date-month {
            background: $color-danger;
            color: $white;
            padding: map-get($spacers, 1) map-get($spacers, 3);
            text-transform: uppercase;
          }

          .Event--date-day {
            padding: map-get($spacers, 2) 0;
            margin-bottom: 0;
            font-family: $headings-font-family;
          }
        }

        .Event--banner-title {
        }
      }
    }

    .Event--header, .Event--content-body {
      padding: map-get($spacers, 3);

      @include media-breakpoint-up(lg) {
        padding: 3rem;
      }
    }

    .Event--header {
      h1 {
        font-weight: 100;
        @include media-breakpoint-down(lg) {
          font-size: 1.2rem;
          text-align: left;
        }
      }

      .Event--subtitle {
        font-size: 1rem;
        color: #999;
        font-weight: 500;
        text-align: left;
        @include media-breakpoint-up(lg) {
          font-size: 1.2rem;
          // text-align: center;
        }
      }
    }

    .Event--organizers {
      display: flex;
      align-items: center;
      grid-gap: map-get($spacers, 2);
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;

      padding: map-get($spacers, 1) map-get($spacers, 3);

      @include media-breakpoint-up(lg) {
        padding: map-get($spacers, 2) 3rem;
      }

      img {
        padding: map-get($spacers, 1);
        border-radius: $border-radius-sm;
        box-shadow: $box-shadow-sm;
      }
    }

    .Event--content-body {
      display: flex;
      flex-direction: column;
      grid-gap: 3rem;
      font-size: 0.9rem;

      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-gap: map-get($spacers, 3);
      }

      .Event--details-wrapper {
        display: flex;
        flex-direction: column;
        grid-gap: 3rem;
        h3 {
          margin-bottom: 1rem;
        }
        .Event--speakers {
          display: flex;
          flex-direction: column;
          grid-gap: map-get($spacers, 2);
          li {
            display: flex;
            align-items: center;
            img {
              border-radius: 100%;
              margin-right: map-get($spacers, 2);
              width: 4rem;
              height: 4rem;
            }
            .Event--speaker-infos {
              h5 {
                a {
                  margin-left: map-get($spacers, 1);
                  color: $color-info;
                }
              }
              p {
                margin-bottom: 0;
                font-size: 0.9rem;
                font-weight: 400;
                color: $color-base-25;
              }
            }
          }
        }
      }
      .Event--sidebar {
        margin-top: 3rem;

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }
    }
  }
  .Event--organizers-card-group {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    grid-gap: map-get($spacers, 2);

    &-3 {
      @include media-breakpoint-up(lg) {
        width: 66%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: map-get($spacers, 3);
      }
    }

    &-2 {
      @include media-breakpoint-up(lg) {
        width: 66%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: map-get($spacers, 3);
      }
    }

    .Event--organizers-card-item {
      display: flex;
      flex-direction: column;
      grid-gap: map-get($spacers, 2);
      overflow: hidden;
      background: $white;
      border-radius: map-get($spacers, 2);
      box-shadow: $box-shadow-lg;
      padding: map-get($spacers, 3);
    }
  }
  .Event--banner-details {
    display: flex;
    align-items: center;
    margin-top: map-get($spacers, 2);
    grid-gap: map-get($spacers, 1);

    i {
      color: $color-base-50;
    }
  }
}
.Event--cta {
  display: flex;
  width: 100%;
  justify-content: center;
  border-top: 1px solid $border-color;
  background: $white;
  position: fixed;
  bottom: 0;
  padding: map-get($spacers, 2) 0;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
