.arkefip-home-people {
  background: url("../../assets/images/components/people/crowd-investment-map.webp"); // TODO import assets
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-card {
    // layout
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;

    // style
    background: var(--arkefip-interaction);
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: $box-shadow;

    h1, h2, h3, p, span {
      color: white;
    }
  }
}
