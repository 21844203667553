.subsection-heading {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: var(--arkefip-spacer-sm);

  h3 {
    font-family: var(--arkefip-font-body);
    font-weight: 700;
    font-size: var(--arkefip-text-md);
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  &::after {
    content: ' ';
    height: 1px;
    width: 100%;
    flex: 1;
    background: var(--arkefip-def-extralight);
  }
}