.ComponentsPills {
  &--energy, &--offer-type {
    display: flex;
    background: $white;
    border-radius: $border-radius-pill;
    box-shadow: $box-shadow-sm;
    padding: 0.375rem;
    width: auto;
  }

  &--energy {
    .energy-pill-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      border-radius: 100%;
      margin-right: map-get($spacers,1);
      width: 1.225rem;
      height: 1.225rem;
      text-align:center;
      i {
        align-self: center;
        color: $white;
        font-size: 0.6rem;
      }
      &.category {
        &-solaire {
          background-color: $yellow;
        }
        &-eolien {
          background-color: $secondary;
        }
        &-hydroelectrique {
          background-color: $secondary;
        }
        &-bioenergie {
          background-color: $green;
        }
        &-innovation {
          background-color: $orange;
        }
        &-economie_energie {
          background-color: $red;
        }
        &-reseau_chaleur {
          background-color: #525252; // $brown;
        }
        &-reseau_froid {
          background-color: #39DEDE;
        }
        &-mix_energetique {
          background-color: #dde9e6; // $kaki;
        }
        &-stockage {
          background-color: $energy-stockage;
        }
        &-hydrogen {
          background-color: $energy-hydrogen;
        }
      }
    }
    .energy-pill-label {
      margin-right: map-get($spacers,1);
      span {
        color: var(--arkefip-def-primary);
        font-size: 0.625rem;
        font-weight: 500;
      }
    }
  }

  &--offer-type {
    padding: 0.15rem;
    width: fit-content;

    &.offer-type {
      &-innovation {
        color: var(--arkefip-plum-primary);
        background-color: var(--arkefip-plum-background);
      }
    }
    .offer-type-pill-label {
      margin-left: map-get($spacers,1);
      margin-right: map-get($spacers,1);
      span {
        font-size: 0.625rem;
        font-weight: 500;
      }
    }
  }
}
