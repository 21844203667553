.project-main-info i {
  margin-right: 10px;
}

@media only screen and (max-width: 960px) {
  .project-main-info {
    min-height: 0;
  }
}

.project-main-info {
  font-size: 14px;

  #project-meta {
    p {
      margin: 0 1rem 0 0;
    }
  }
}
