.AppComponents--discount {
  display: flex;
  background: $secondary;
  border: 1px solid $border-color;
  position: relative;
  justify-content: space-between;

  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid $border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    border-radius: 0 $border-radius 0 0;
    text-decoration: none;
  }
}
