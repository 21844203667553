.ariane {
  position: relative;
  @extend .bg-primary !optional;
  min-height: 50px;

  ul {
    position:relative;
    top: 8px;
    padding: 0px;
  }

  li {
    display: inline-block;
    margin: auto 5px;

    a, span {
      line-height: 40px;
      margin-right: 8px;
      color: lightgray; // #ccc;
    }

    a:last-child {
      color: white; // #fff;
    }

    i {
      font-size: 20px;
      position: relative;
      top: 2px;
      color: lightgray; // #aaa
    }
  }
}
