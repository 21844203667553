
.arkefip-footer {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-lg);
  margin: 0rem;
  padding: var(--arkefip-layout-padding);
}

.arkefip-footer {
  &-bg-colored {
    padding-top: var(--arkefip-spacer-md);
    padding-left: var(--arkefip-spacer-lg);
    padding-right: var(--arkefip-spacer-lg);
    padding-bottom: var(--arkefip-spacer-sm);
    border-radius: 12px;
    background-color: var(--arkefip-def-extralight);

    @include media-breakpoint-up(md) {
      background: url('../../assets/images/patterns/arkefip_footer.svg'), var(--arkefip-def-extralight);
      background-position: center;
      background-size: cover;
    }

    @include media-breakpoint-up(xl) {
      padding-top: var(--arkefip-spacer-xl);
      padding-left: var(--arkefip-spacer-2x);
      padding-right: var(--arkefip-spacer-2x);
      padding-bottom: var(--arkefip-spacer-lg);
    }

  }

  &-grid {
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-md);

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-self: stretch;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

.arkefip-footer {
  &-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%
  }

  &-title {
    color: var(--arkefip-def-dark);
    font-family: var(--arkefip-font-headings);
    font-size: 24px;
    font-weight: 400;
  }
  &-bottom {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-sm);
    padding-top: var(--arkefip-spacer-md);
  }
  &-risk {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--arkefip-def-primary);
    font-size: 12px;
    line-height: 1.3;
  }
  &-brand {
    display: flex;
    width: 100%;
    justify-content: center;
    svg {
      width: var(--arkefip-spacer-xl);
      fill: var(--arkefip-def-primary);
    }
  }
}
.arkefip-footer {
  &-item {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: white;

    &-title {
      color: var(--arkefip-def-dark);
      font-family: var(--arkefip-font-headings);
      font-size: 16px;
      font-weight: 500;

      &-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }

    &-description {
      font-size: 12px;
    }

    &-flags {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      &-image {
        max-width: 14px;
      }
    }

    &-subtitle {
      color: var(--arkefip-def-primary);
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.arkefip-footer {
  &-cta {
    display: flex;
    width: 100%;
    align-items: center;
    color: var(--arkefip-interaction);
    &-icon, &-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      width: var(--arkefip-spacer-lg);
      height: var(--arkefip-spacer-lg);
      padding-right: var(--arkefip-spacer-sm);

      @include media-breakpoint-up(md) {
        padding-right: var(--arkefip-spacer-xs);
      }
    }
    &-content {
      flex-grow: 1;
    }
    &-title {
      font-size: 16px;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 12px;
      font-weight: 500;
    }
  }
  &-contact {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  &-assistance {
    display: flex;
    flex-direction: column;
    width: 100%;
    }
  &-social {
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      a {
        color: var(--arkefip-interaction);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: var(--arkefip-spacer-lg);
        height: var(--arkefip-spacer-lg);
        border-radius: 100%;
        border: 1px solid var(--arkefip-interaction);

        @include media-breakpoint-up(md) {
            width: var(--arkefip-spacer-md);
            height: var(--arkefip-spacer-md);
          }
      }
    }
  }
  &-help {
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
}
