.page-design_resources {
  display: flex;
  align-items: flex-start;
  background-color: var(--arkefip-def-background);
  font-size: var(--arkefip-text-md);

  header {
    display: flex;
    flex-direction: column;
    padding: var(--arkefip-layout-padding);
    position: sticky;
    top: 0;
    width: 300px;
    flex-shrink: 0;
  }

  main {
    padding: var(--arkefip-layout-padding) var(--arkefip-layout-padding) var(--arkefip-layout-padding) 0;
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-sm);
    flex-grow: 1;
    max-width: 1200px;
  }

  table {
    align-self: flex-start;
  }
}

.design-sidebar {
  background-color: white;
  padding: var(--arkefip-layout-padding);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-md);
  font-size: 12px;

  &-section {
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: 700;
      font-size: 80%;
      color: var(--arkefip-def-primary);
      text-transform: uppercase;
      letter-spacing: 0.025rem;
      margin-bottom: var(--arkefip-spacer-xs);
    }
  }

  &-item {
    padding: var(--arkefip-spacer-xs);
    display: flex;
    transition: all ease-in-out 0.2s;
    background-color: transparent;
    color: var(--arkefip-def-dark);
    border-radius: 0.5rem;

    &:hover {
      background-color: var(--arkefip-def-background);
      color: var(--arkefip-def-darth);
    }
  }
}

.design-logo {
  color: var(--arkefip-def-dark);

  &-title {
    font-family: var(--arkefip-font-headings);
    font-size: var(--arkefip-text-md);
  }
}

.preview {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--arkefip-def-light);

  pre {
    background-color: var(--arkefip-def-extralight);
    padding: var(--arkefip-spacer-xs);
    margin-bottom: 0;
  }
  &-body {
    padding: var(--arkefip-layout-padding);
  }
}