// Contents
//
// .arkefip-project-page-main
// .show-project
// .show-project-innovation

.arkefip-project-page-main {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

// .show-project {
//   &-innovation {
//     background-color: var(--arkefip-plum-background);
//     display: flow-root;
//   }
// }
