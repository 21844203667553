#arkefip-app-assistance {

  @media (min-width:960px) {
    position: fixed;
    bottom: var(--arkefip-layout-padding);
    right: var(--arkefip-layout-padding);
    }

  @media (max-width:960px) {
    position: relative;
    margin-bottom: var(--arkefip-spacer-sm);
  }

  a {
    padding: var(--arkefip-spacer-xs) var(--arkefip-spacer-sm);
    border-radius: 100rem;
    background-color: var(--arkefip-interaction);
    color: white;
    transition: all ease-in-out 0.2s;
    outline: 0 solid transparent;

    &:hover {
      animation: blow 1s 1;
      outline: 2px solid var(--arkefip-interaction);
      opacity: 0.9;
    }
  }


}

#AssistanceSidesheet {
  .offcanvas-body {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-md);
  }
}

@-webkit-keyframes blow {
  0% {
    box-shadow: 0 0 0 var(--arkefip-interaction);
  }
  100% {
    box-shadow: 0 0 0 5rem transparent;
  }
}
