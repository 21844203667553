#help-modal {

  .nav-item {
    a {
      @extend .transition-speed !optional;
      display: block;
      padding: 0.4rem;
      border-bottom: 2px solid transparent;
      color: $color-base-80;

      &.active {
        border-bottom: 2px solid $primary;
        color: $primary;
        font-weight: bold;
      }
    }
  }

  .list-group {
    .hovering {
      background: $white;
      @extend .d-flex ;
      @extend .justify-content-between ;
      &:after {
        font-family: "Font Awesome 6 Free";
        content: "\f105";
        font-weight: 900;
        font-size: 80%;
        margin-left: 0.5rem;
        transition: all ease-in-out 0.2s;
      }
      &:hover {
        background: $color-brand-bg;
        &:after {
          font-family: "Font Awesome 6 Free";
          content: "\f067";
          font-weight: 900;
          font-size: 80%;
          margin-left: 0.5rem;
          transition: all ease-in-out 0.2s;
        }
      }
    }
  }
  #email_confirmation {
    display: none;
  }
}
