.arkefip-storypage-founders {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-picture {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-text {
      background: var(--arkefip-def-background);
      padding: 1rem;
      border-radius: 1rem;
      box-shadow: var(--arkefip-shadow-lg);
      width: 100%;
      max-width: 400px;
      margin-top: -3rem;

      @include media-breakpoint-up(md) {
        margin-top: -8rem;
      }

      p {
        margin: 0;
      }
    }
  }

  &-values {
    width: 100%;
    max-width: 1000px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;

    padding: 4rem 0;

    &-item {
      display: flex;
      gap: 1rem;

      &-body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      &-title {
        font-weight: 300;
        font-size: 1.5rem;
        color: var(--arkefip-primary)
      }
    }
  }
}
