.risk-item {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--arkefip-def-background);
  border-radius: 0.5rem;
  
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &-risk, &-mitigation {
    padding: var(--arkefip-spacer-sm);
    display: flex;
    flex-direction: column;
    line-height: 1.3;
  }

  &-risk {
    i.fas {
      color: var(--arkefip-pollen-primary);
    }
  }

  &-mitigation {
    background: var(--arkefip-def-background);
    border-radius: 0 0 0.5rem 0.5rem;

    @include media-breakpoint-up(md) {
      border-radius: 0 0.5rem 0.5rem 0;
    }

    i.fas {
      color: var(--arkefip-pine-secondary);
    }
  }

  &-heading {
    display: flex;
    align-items: center;
    gap: var(--arkefip-spacer-xs);
    h3 {
      font-size: 1.2rem;
      font-weight: 500;
    }
    
  }
  &-heading-wrapper {
    margin-bottom: 1rem;
  }
}