.AppSettings--fisc-situation-info {
  display: flex;
  justify-content: flex-start;
  padding: map-get($spacers, 1) 0;

  .title {
    width: 30%;
    text-transform: uppercase;
    font-weight: lighter;
  }

  .info {
    width: 70%;
    font-weight: bold;
    border-left: 1px solid $border-color;
    padding-left: map-get($spacers, 1);
  }
}
