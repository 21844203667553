.form-card {

  &.scrollable {
    overflow: scroll;
  }
  .title.clickable {
    cursor: pointer;
  }
  .content {
    transition: all 0.5s;
    &.show {
      max-height: 1000px;
    }
    &.hide {
      max-height: 0px;
      overflow: hidden;
    }
  }
  .form-switch {
    label {
      padding-left: 0.75rem;
    }
    label::before {
      height: 1.25rem;
      width: 2.5rem;
      border-radius: 0.75rem;
    }
    label::after {
      width: calc(1.25rem - 4px);
      height: calc(1.25rem - 4px)
    }
    .form-check:checked ~ .form-check-label::after {
      transform: translateX(1.25rem);
    }
  }
}

.ComponentsStepItem--section-divider {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  position: relative;
  &:before {
    content: " ";
    height: 2px;
    background: map-get($palette-main-colors, "success");
    width: calc(50% - 100px);
    position: absolute;
    left: 0;
    top: 14px;
  }
  &:after {
    content: " ";
    height: 2px;
    background: map-get($palette-main-colors, "success");
    width: calc(50% - 100px);
    position: absolute;
    right: 0;
    top: 14px;
  }
  .content {
    border-radius: $border-radius;
    background: map-get($palette-main-colors, "success");
    color: white;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 170px;
    z-index: 1;
    span {
      margin: 0 5px;
    }
    i {
      margin: 0 5px;
    }
  }
}

.ComponentsStepItem--divider {
  margin: 15px 0;
  border-bottom: 1px solid $border-color;
  width: calc(60px + 100%);
  position: relative;
  left: -30px;
}


.ComponentsStepItem--question-title {
  color: $headings-color;
  font-size: 14px;
}

.ComponentsStepItem--question-hint {
  color: $color-base-60;
  font-weight: lighter;
  font-style: italic;
}

.ComponentsStepItem--question-subtitle {
  color: $headings-color;
}
