.arkefip-chat {
  &-group {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-xs);
  }

  &-author {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    &-picture {
      width: 48px;
      height: 48px;
      border-radius: 100%;
    }

    &-infos {
      font-size: var(--arkefip-text-sm);
      
      p {
        margin-bottom: 0;
      }
    }
  }
  
  &-message {
    padding: 0.75rem;
    font-size: var(--arkefip-text-sm);
    background: var(--arkefip-def-primary);
    border-radius: 0.25rem 1rem 1rem 1rem;
    color: var(--arkefip-def-extralight);
    font-weight: 300;

    p {
      margin-bottom: 0;
    }

    a {
      color: wheat;
      border-bottom: 1px dotted var(--arkefip-def-light);
    }
  }
}