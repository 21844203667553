.satisfaction-form-wrapper {
  height: 3500px;

  @media (min-width: 450px) {
    height: 3200px;
  }

  @media (min-width: 500px) {
    height: 3000px;
  }

  @media (min-width: 600px) {
    height: 2500px;
  }
}
