.AppInvestments {
  &--card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: map-get($spacers, 1);
    border-bottom: 1px solid $border-color;
    text-decoration: none;

    &.active {
      background: $secondary;
    }

    .main-content {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      color: $color-base-100;
    }
  }
}
