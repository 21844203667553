@import "a";
@import "backgrounds";
@import "fonts";
@import "images";
@import "markdown";
@import "tables";
@import "ul";


.main-content {
  border-color: $border-color;
}

.hidden {
  display: none;
}


section .subtitle {
  font-style: italic;
  font-size: 1.2rem;
  margin-bottom: 2rem;

  @include media-breakpoint-down(md) {
    font-size: 0.9rem;
  }
}
