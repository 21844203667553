.projects-sidebar {

  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-family: $font-family-sans-serif;
    font-size: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-section {
    &-heading {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  .btn-circle {
    width: 40px;
    height: 40px;
    padding: 8px 0px;
    border-radius: 20px !important;
    font-size: 16px;
  }

  .all-projects {
    display: block;
    font-weight: 500;
    text-decoration: underline;
  }
}

.filters {
  &-issuer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: var(--arkefip-spacer-sm)
  }
}