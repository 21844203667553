.form-switch.text-small {
  font-size: 12px;
}

.form-switch {
  .form-check-label {
    margin: 0 0.5em;
  }
  .form-check-input {
    border-color: $primary;
    height: 1.25em;
    width: 2.5em;
  }
}
.form-control {
  font-family: $font-family-sans-serif;
  padding: 1rem;
  font-size: 14px;
}

.input-group-prepend, .input-group-append {
  display: flex;
}
// extended version of radio and checkboxes to use with .custom-checkboxes and .custom-radiobuttons
.label-extended {
  font-size: 14px;
  .radio, .checkbox {
    @extend .transition-speed !optional;
    padding: 10px;
    background: $white;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    margin-bottom: 4px;
    color: $color-base-80;
    &:hover {
      border: 1px solid $dark;
      color: $dark;
    }
    &:focus {
      background: $color-base-5;
      border: 1px solid $color-base-80;
      color: $color-base-100;
    }
  }
  input {
    margin-right: 4px;
  }
  label {
    margin-bottom: 0;
    display: block;
    cursor: pointer;
  }
}