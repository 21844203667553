.overlay-backdrop {
  display: block; /* Changé pour être toujours dans le DOM mais invisible par défaut */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1101;
  opacity: 0; /* Commence totalement transparent */
  transition: opacity 0.3s ease; /* Animation de l'opacité */
  pointer-events: none; /* Empêche les interactions quand il est invisible */
}

.overlay-backdrop.show {
  opacity: 1;
  pointer-events: auto; /* Permet les interactions quand il est visible */
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  max-height: 90vh;
  background-color: #fff;
  z-index: 1102;
  opacity: 0;
  overflow-y: auto;
  transition: opacity 0.3s ease, transform 0.3s ease-out;
  box-shadow: var(--arkefip-shadow-lg);
  left: 0;
  top: 0;
  transform: translate(0, -100%);
  border-radius: 0 0 1rem 1rem;
  
  @include media-breakpoint-up(md) {
    transform: translate(-50%, 50%);
    left: 50%;
    top: 50%;
    width: 100%;
    max-width: 720px;
    max-height: 70vh;
    border-radius: 1rem;
  }
}

.overlay.show {
  opacity: 1;
  transform: none;
  transform: translate(0, 0);

  @include media-breakpoint-up(md) {
    transform: translate(-50%, -50%);
  }
}

.overlay-header {
  padding: 20px;
  background: #fff;
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--arkefip-def-extralight);
}

.overlay-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);
}

.overlay-dismiss {
  background-color: var(--arkefip-interaction);
  color: white;
  border: none;
  z-index: 1103;
  visibility: hidden;
  position: fixed;
  bottom: var(--arkefip-layout-padding);
  left: 50%;
  transform: translate(-50%, 0);
}

.overlay-dismiss.show {
  visibility: visible;
}