.arkefip-app-mobile-topbar {
  z-index: 1001;
  display: flex;
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    display: none;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--arkefip-layout-padding) var(--arkefip-layout-padding) 0 var(--arkefip-layout-padding);
  }

  &-title {
    display: flex;
    flex-direction: row;
    font-size: 24px;
    align-items: center;
    gap: 8px;
    i{
      height: 36px;
    }
    h1 {
      margin-bottom: 0;
    }
    .arkefip-empreinte {
      fill: var(--arkefip-primary);
      width: 1rem;
    }
  }

  &-tabs {
    &-wrapper {
      width: 100%;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-group {
      display: flex;
      padding: 16px;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &-item {
        display: flex;
        flex-direction: row;
        background-color: #F9F8F6;
        text-decoration: none;
        color: #423E38;
        padding: 0px 12px 0px 4px;
        align-items: center;
        border-radius: 32px;
        min-width: fit-content;

        &-icon {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-label {
          font-size: 12px;
          font-weight: 600;
        }

        &.active {
          color: #22633B;
          background-color: #E8F6ED;
        }
      }
    }
  }
}
