.arkefip-project-page-specifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;

  @include media-breakpoint-up(md) {
    grid-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
  }

  &-col {
    width: 100%;
  }

  &-status {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
