@mixin background-image($url) {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.5%, rgba(0, 0, 0, 0.40) 51%, rgba(0, 0, 0, 0.50) 60.49%, rgba(0, 0, 0, 0.70) 100%), url($url);
  background-position: center;
  background-size: cover;
}

.arkefip-testimonials {
  width: 100%;
  padding: 3rem var(--arkefip-layout-padding);
  @include media-breakpoint-up(md) {
    padding: 0 72px;
  }

   .section-heading {
    margin-bottom: 1em;
    padding: 1rem 0;

    @include media-breakpoint-down(md) {
      align-items: flex-start;
    }

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
   }

  .arkefip-testimonial-buttons {
    display: flex;

    .testimonial-toggle {
      gap: 1.5rem;
      @include media-breakpoint-down(sm) {
        gap: 1rem;
      }
    }

    button {
      padding: 8px 16px;
      border: 1px solid var(--arkefip-def-primary);
      border-radius: 8px;
      background-color: var(--Linen-Background);

      h4 {
        margin-bottom: 0;
      }
    }

    .nav-link.active {
      background-color: var(--arkefip-pine-dark);

      h4 {
        color: white;
      }
    }
  }

  .interview-cards {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include media-breakpoint-down(lg) {
      overflow-x: auto;
      scroll-behavior: smooth;
      flex-wrap: nowrap;
    }

    @include media-breakpoint-down(md) {
      overflow-x: auto;
      scroll-behavior: smooth;
      flex-wrap: nowrap;
    }

    .before-enerfip, .after-enerfip {
      display: flex;
      gap: 1.5rem;
    }

    .beginner-interviewed-investor-card-before {
      @include background-image('/app/assets/images/pages/landing/default/avant-enerfip-leopold.webp');
    }

    .experienced-interviewed-investor-card-before {
      @include background-image('/app/assets/images/pages/landing/default/avant-enerfip-julien.webp');
    }

    .beginner-interviewed-investor-card-after {
      @include background-image('/app/assets/images/pages/landing/default/avec-enerfip-leopold.webp');
    }

    .experienced-interviewed-investor-card-after {
      @include background-image('/app/assets/images/pages/landing/default/avec-enerfip-julien.webp');
    }

    .beginner-interviewed-investor-card-before,
    .experienced-interviewed-investor-card-before,
    .beginner-interviewed-investor-card-after,
    .experienced-interviewed-investor-card-after {
      height: 300px;
      display: flex;
      align-items: flex-end;
      border-radius: 16px;
    }
  }

  .interviewed-investor-quote {
    border-radius: 16px;
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: space-between;

    .interviewed-investor-link {
      border: none;
      align-self: end;
      background-color: white;
      width: 40px;
      height: 40px;

      @include media-breakpoint-down(sm) {
        width: var(--arkefip-spacer-md);
        height: var(--arkefip-spacer-md);
      }

      a {
        border: none;
        padding: var(--arkefip-spacer-xs);
        @include media-breakpoint-down(sm) {
          padding: 4px;
        }

        &:hover {
          color: white;
        }

        i {
          color: var(--arkefip-terra-primary);
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &-text {
      color: white;

      p {
        font-size: var(--arkefip-text-md);
        @include media-breakpoint-down(sm) {
          font-size: var(--arkefip-text-sm);
          line-height: normal;
        }
      }

      span {
        font-size: var(--arkefip-text-sm);
        @include media-breakpoint-down(sm) {
          font-size: var(--arkefip-text-xs);
        }
      }
    }
  }

  .impact {
    padding: 1.5em;
    height: 100%;
    border-radius: 16px;
    color: white;
    display: grid;

    

    h4 {
      color: white;
    }

    span {
      align-self: end;
      font-size: var(--arkefip-text-xs);
      font-weight: 300;
    }

    p {
      font-size: var(--arkefip-text-md);
    }

    &-before-enerfip {
      background-color: var(--arkefip-def-darth);
    }

    &-after-enerfip {
      background-color: var(--arkefip-pine-dark);
    }
  }

  .slide-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 90%;
    margin-top: 2rem;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--arkefip-spacer-lg);
      height: var(--arkefip-spacer-lg);
      background-color: white;
      border: 1px solid var(--arkefip-interaction);

      i {
        color: var(--arkefip-interaction);
        font-size: var(--arkefip-text-xl);
      }
    }

    .disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
}