.form-card .documents-card  {
  margin: 0;
  padding: 0;
  text-align: center;
  border: none !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 250px;
  
  h3 {
    display: block;
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
  .back-button {
    cursor: pointer;
    font-weight: bold;
  }

  .subkind-selection {
    a {
      max-width: 100%;
      font-size: 11px;
      text-wrap: wrap;
    }
  }

  .subkinds-wrapper {
    display: flex;
    justify-content: center;
    
    a {
      text-decoration: none;
      cursor: pointer;
      min-height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a p {
      font-size: 11px;
      max-width: 100%;
      text-wrap: wrap;
      margin-bottom: 0;
      font-family: $font-family-sans-serif;
    }
  }

  .dropzone-wrapper {
    @extend .border;
    margin-bottom: 10px;
    padding: 0;

    position: relative;
      .success-text {
        font-weight: bold;
        margin-top: 10px;
        font-size: 16px;
      }
      .dropzone {
        @extend .bg-light;
        min-height: 120px;
        margin: 0;
        padding: 10px;
      }
    .floating-btn {
      padding: 5px;
      margin: 10px;
      font-size: 11px;
    }
  }
}

.documents-block {
  .hidden {
    display: none;
  }

  min-height: 220px;
  position: relative;

  .hint a {
    color: #f60;
  }

  .back {
    position: absolute;
    bottom: 2em;
  }

  .edit-button {
    text-align: center;
    float: left;
  }

  .image-preview {
    background: #fff;
    float: right;
    margin-top: -50px;
    border: 1px solid $border-color;
  }



  .doc_upload_field .fields {
    height: 3.5em;
  }

  .doc_upload_field label {
    margin-top: 1em;
  }

  h3,
  .radio label {
    font-weight: 100;
    padding: 0;
    margin: 0;
  }

  .hint {
    font-size: 80%;
    color: rgba($dark, 0.5);
  }

  a.back {
    float: left;
    text-transform: uppercase;
    font-size: 80%;

    &:before {
      content: "\f053";
      font-weight: 900;
      font-family: 'Font Awesome 6 Free';
      margin-right: .6em;
    }
  }

  a.button {
    text-transform: uppercase;
    border-radius: 5px;
    background: $color-green;
    padding: .2em 1em;
  }

  .subkind-section,
  .upload-section p {
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
    font-family: $headings-font-family;
  }

  .subkinds-container {
    // max-height: 220px;
    overflow: auto;
  }

  .radio {
    font-size: 85%;
    margin: 2px 0;

    input {
      visibility: hidden;
    }

    label {
      display: block;
      padding-left: 0;
    }

    label:before {
      content: "\f111";
      font-family: 'Font Awesome 6 Free';
      margin-right: .6em;
    }
  }
}
