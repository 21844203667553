.partner-commitment {
  // dimensions
  width: 100%;
  padding: 1.5rem 0;

  @media (min-width: 900px) {
    padding: 4rem 0;
  }

  // layout
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;

  //style
  background: url('../../assets/images/pictures/renewables.webp');
  background-size: cover;

  &-content {
    padding: 0 1.5rem;

    @media (min-width: 900px) {
      padding: 0 4rem;
    }
  }

  &-card {
    // dimensions
    padding: 1.5rem;

    @media (min-width: 900px) {
      padding: 4rem;
    }

    // layout
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    //style
    border-radius: 1rem;
    box-shadow: $box-shadow;
    background: $light;

    i {
      font-size: 1.5rem;
    }

    &-left {
      // layout
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 1rem;
    }
  }

  &-gallery {
    &-wrapper {
      // dimensions
      width: 100%;

      //layout
      display: flex;
      overflow-y: scroll;
    }

    &-group {
      // dimensions
      padding: 0 1.5rem;

      @media (min-width: 900px) {
        padding: 0 4rem;
      }

      // layout
      display: flex;
      grid-gap: 1rem;
    }

    &-item {
      // dimensions
      width: 200px;
      padding: 1.5rem 1rem 0 1rem;

      // layout
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      grid-gap: 1rem;

      // style
      background: white;
      border-radius: 1rem;

      &-title {
        text-align: center;
        font-family: $headings-font-family;
        font-weight: 300;
        margin: 0;
        line-height: 120%;

        strong {
          font-weight: 500;
        }
      }

      &-number {
        font-family: $headings-font-family;
        font-size: 1.5rem;
        font-weight: 100;
        color: $primary;
      }

      &-icon {
        // layout
        display: flex;
        justify-content: center;
        width: 42px;

        // style
        padding: 12px 0;
        border-radius: 9px 9px 0 0;
        background-color: $primary;
        color: $white;
      }
    }
  }
}
