.arkefip-home-intro {
  // layout
  align-self: stretch;
  display: flex;
  padding: 84px 18px 18px 18px;
  flex-direction: column;
  gap: 10px;

  @include media-breakpoint-down(md) {
    padding: 98px 18px 18px 18px;
  }
  
  &-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image: url('../../assets/images/illustrations/portfolio-cut.webp');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 35%;
    padding: 0px 72px;

    @include media-breakpoint-down(md) {
      background-image: none;
      padding: 0px 34px;
      flex-direction: row;
    }
  }

  &-content {
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: var(--arkefip-spacer-md);
    align-items: flex-start;

    > *:last-child {
      margin-top: var(--arkefip-spacer-md);
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  h1 {
    color: var(--linen-primary, var(--arkefip-def-primary));
    font-family: var(--arkefip-font-body);
    font-size: var(--arkefip-text-md);
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.32px;
    text-transform: uppercase;
  }

  h2 {
    color: var(--arkefip-dark);
    font-size: 60px;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -1.2px;

    @include media-breakpoint-down(md) {
      font-size: 48px;
    }
  }

  &-cta {
    display: flex;
    gap: var(--arkefip-spacer-md);

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: var(--arkefip-spacer-sm);
    }
  }
}
