.ProjectActuality {
  &--wrapper {
    &:not(:last-child) {
      border-bottom: 2px solid $border-color;
      padding-bottom: map-get($spacers, 3);
      margin-bottom: map-get($spacers, 3);
    }
    .actuality-image {
      max-height: 200px;
      object-fit: cover;
      width: 100%;
      border-radius: $border-radius;
      margin-bottom: map-get($spacers, 3);
    }
  }
}
