#signup-page {

  .registration-label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .signup-page-arguments {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1rem 0 1rem;

    @include media-breakpoint-up(lg) {
      padding: 2rem 2rem 0 2rem;
    }

    .page-info-advantages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
      color: white;

      .advantage {
        display: flex;
        font-size: 16px;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 48px;
          min-height: 48px;
          border-radius: 100%;
          border: 2px solid white;
          box-shadow: 0 0 0.5rem #00000033;
        }

        p {
          margin-bottom: 0;
          text-shadow: 0 0 0.5rem #00000044;
        }

        i {
          font-size: 20px;
        }
      }
    }

    .registration-bottom {
      display: flex;
      border-radius: 10px;
      background: white;
      align-items: center;
      justify-content: space-around;
      padding: 1rem;
      box-shadow: 0 0 0.5rem #00000033;

      .orias-footer,
      .rgpd-footer {
        width: 50%;
        padding: 10px;
        display: flex;

        p {
          font-size: 10px;
        }
      }
    }
  }
}
