#admin_panel {
  display: flex;
  z-index: 5000;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  position: fixed;
  justify-content: center;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--arkefip-plum-primary);
    padding: 1.25rem 1rem;
    border-radius: 0 0 1rem 1rem;
    text-align: center;
    cursor: pointer;
    color: #fff;
    box-shadow: var(--arkefip-shadow-sm);
    transition: all ease-in-out 0.2s;

    &:hover {
      padding-top: 2rem;
      background-color: var(--arkefip-plum-dark);
    }
  }
}
