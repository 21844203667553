.arkefip-app-mobile-navbar-container {
  z-index: 1001;
  position: fixed;
  bottom: 0;
  width: 100%;

  @media (min-width:960px){
    display: none;
  }

}
.arkefip-app-mobile-navbar {
  display: flex;
  justify-content: center;
  align-items: center;

  &-group {
    display: grid;
    // "1fr" x number of items in mobile navlinks
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 12px 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.05);

    &-item {
      display: flex;
      color: #423E38;
      text-decoration: none;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      &-icon {
        width: 100%;
        height: 24px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-label {
        font-size: 12px;
        font-weight: 400;
      }

      &.active {
        color: #22633B;
        .arkefip-app-mobile-navbar-group-item-icon {
          background-color: #E8F6ED;
          border-radius: 16px;
        }
        .arkefip-app-mobile-navbar-group-item-label {
          font-weight: 600;
        }
      }
    }
  }
}
