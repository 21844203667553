.info-tile {
  display: flex;
  align-items: center;
  gap: var(--arkefip-spacer-sm);
  padding: var(--arkefip-spacer-xs) var(--arkefip-spacer-sm);
  width: 100%;
  border: 1px solid var(--arkefip-def-extralight);
  border-radius: 0.5rem;
  position: relative;
  
  text-decoration: none;
  color: var(--arkefip-def-dark);
  
  
  &.responsive {
    flex-direction: column;
    align-items: stretch;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;

    }
    .info-tile-icon {
      @include media-breakpoint-down(md) {
        position: absolute;
        right: 0;
        top: 0;
        margin: 1rem;
      }
    }
  }

  &-icon {
    color: var(--arkefip-def-dark);
  }
  &-body {
    flex: 1;
  }
  &-label {
    font-size: var(--arkefip-text-xs);
    text-transform: uppercase;
    opacity: 0.5;
    display: flex;
    gap: var(--arkefip-spacer-xs);
  }
  &-data {
    font-size: var(--arkefip-text-sm);
    font-weight: 700;
  }
  &-excerpt {
    font-size: var(--arkefip-text-xs);
  }
  &-trigger {
    cursor: pointer;
    transition: all ease-in-out 0.2s;
  }

  &:hover &-trigger {
    transform: rotate(360deg);
  }

  &.featured {
    background-color: var(--section-color-primary);
    color: white;

    .info-tile {
      &-icon, &-label, &-trigger {
        color: var(--arkefip-color-background);
      }
    }
  }
}