.overflow-y-hidden {
  overflow-y: hidden;
}

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
