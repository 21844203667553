@use "sass:math";

.AppLivret {
  &--summary-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
    background: #d2e9ed;
    background-image: url('../../assets/images/components/livret/trans-card.webp');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    border-radius: 15px;
    padding: 1rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);

    &-child {
      background-image: linear-gradient(90deg, #FFFFFF, #FFFFFF99), url('../../assets/images/components/livret/child-drawing.webp');
      background-size: cover;
      text-shadow: 0 0 0.5rem white;
    }

    p {
        margin-bottom: 5px;
        font-weight: lighter;
        text-transform: uppercase;
        color: var(--arkefip-def-darth);
        font-size: 14px;
    }
    .note {
        margin-top: -25px;
        font-size: 10px;
        color: var(--arkefip-def-darth);
        margin-bottom: 15px;
    }
    .title {
        font-size: 12px;
        margin-top: 25px;
        font-weight: bold;
        margin-bottom: 0;
    }
    .amount {
        font-family: var(--arkefip-font-headings);
        font-weight: 300;
        font-size: 35px;
        margin: 0;
        line-height: 1;
    }
    &-logo {
      position: absolute;
      top: 1rem;
      right: 1rem;
      svg {
        width: 2rem;
        fill: var(--arkefip-primary);
      }
    }
  }

  &--summary-card-links {
    display: flex;
    justify-content: space-around;
    margin: map-get($spacers, 1) 0;
    .btn-outline-success {
      background: $color-success-bg;
    }
    .btn-outline-warning {
      background: $color-warning-bg;
    }
  }
  &--link-wrapper {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-base-100 !important;
    border-bottom: 1px solid $border-color;
    span.new-badge {
      position: absolute;
      height: 5px;
      width: 5px;
      background: red !important;
      color: red !important;
    }

    .text-wrapper {
      width: 100%;
      margin-left: map-get($spacers, 2);
    }
    .title {
      font-weight: bold;
    }
    .subtitle {
      font-weight: lighter
    }

    &--maintenance {
      color: $color-base-25 !important;
    }
  }
  &--situation-card {
    .line-item {
      display: flex;
      justify-content: space-between;
      margin: map-get($spacers, 1) 0;
      .icon-plus {
        color: #080
      }
      .icon-minus {
        color: #800
      }
    }
  }
}
