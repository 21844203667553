
// Deprecated in bootstrap 5.3 https://getbootstrap.com/docs/5.3/components/alerts/#sass-mixins
@mixin alert-variant($background, $border, $color) {
  --#{$prefix}alert-color: #{$color};
  --#{$prefix}alert-bg: #{$background};
  --#{$prefix}alert-border-color: #{$border};
  --#{$prefix}alert-link-color: #{shade-color($color, 20%)};

  @if $enable-gradients {
    background-image: var(--#{$prefix}gradient);
  }

  .alert-link {
    color: var(--#{$prefix}alert-link-color);
  }
}

// Generate `.alert-*` color utilities
@each $key, $value in $palette-background-colors {
  .alert-#{$key} {
    @include alert-variant($value, map-get($palette-light-colors, $key), map-get($palette-text-colors, $key));
  }
}

// Generate `.text-*` color utilities
@each $key, $value in $palette-text-colors {
  #{".text-#{$key}"} {
    color: $value !important;
  }
  a#{".text-#{$key}"}:hover,
  a#{".text-#{$key}"}:focus {
    color: darken($value, 10%) !important;
  }
}

// Generate `.border-*` color utilities
@each $key, $value in $palette-light-colors {
  .border-#{$key} {
    color: $value;
  }
}

// defining success border and error classes
@each $key, $value in $palette-background-colors {
  .#{$key} {
    @include alert-variant($value, map-get($palette-light-colors, $key), map-get($palette-text-colors, $key));
  }
}

// temporary backport boostrap 4 .text-right
.text-right {
  @extend .text-end
}
