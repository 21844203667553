.arkefip-storypage-gallery {
  width: 100vw;
  overflow: scroll;
  margin-bottom: 4rem;
  padding: 2rem;

  &-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-item {
      img {
        border-radius: 0.5rem;
        width: 100%;
      }
    }
  }
}
