.table_simulation {
  overflow: hidden;

  thead div {
    padding: 0 20px;
  }

  .total-roi-container {
    font-size: 150%;

    span {
      font-weight: bold;
    }
  }

  td {
    font-size: 12px;
    text-align: center;

    p {
      font-size: 14px;
      text-align: left;
      margin: 15px 0;
      line-height: 18px;
    }
  }
}
