.partner-expertise {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 900px) {
    max-width: 900px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 6rem 1rem;
  }

  &-content {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    justify-content: center;
    align-items: flex-start;
    padding: 1.5rem;
  }

  &-image {
      width: 50%;

      @media (min-width: 900px) {
        width: 100%;
      }
  }
}
