.AppSaleOffers {
  &--details {
    .details {
      margin-top: map-get($spacers, 1);
      background: $secondary;
      border-radius: map-get($spacers, 2);
      padding: map-get($spacers, 2);
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .details-item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-bottom: map-get($spacers, 1);

        .details-item-label {
          font-weight: 400;
          font-size: 0.563rem;
          color: $color-base-80;
          text-transform: uppercase;
        }

        .details-item-info {
          font-size: 0.625rem;
          font-weight: 700;
        }
      }
    }
  }
}
