.product-markdown-content {
  ul {
    list-style-type: disc;
    margin-left: 2em;
    li {
      margin-bottom: 0.5em;
    }
  }
  img.half-width {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }
  table {
    @extend .table;
    @extend .table-bordered;
    margin: 2em 0;

    thead {
      @extend .table-primary;
    }
    tbody {
      @extend .table-group-divider;
    }
  }
}