.arkefip-newsletter-subscription-success {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 120px 0;
  width: 34%;

  h1 {
    
    font-weight: 400;
    
    color: var(--arkefip-primary);
    
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    text-align: center;
  }
}