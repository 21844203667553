/*
CURRENT
Introducing new color framework in order to harmonize the UI with custom Enerfip Spaces palets
*/
// Set base framework
$color-base-100: #423E38; //#012553;
$color-base-80: #55514B; //#335075;
$color-base-60: #736F67; //#677D98;
$color-base-50: #ADA99F; // #7F91A8;
$color-base-25: #DEDACE; // #BFC8D4;
$color-base-15: #F2F0EB; // #D9DEE5;
$color-base-10: #F2F0EB; // #E5E8ED;
$color-base-5: #F9F8F6; //#F2F4F6;

// Set brand color
$color-brand-dark: #613217; // used for text and primary buttons
$color-brand: #924F3C; // used for action buttons
$color-brand-border: #924F3C; // used for info alerts and interactive element border
$color-brand-light: #DEDACE; // used for light theme
$color-brand-bg: #F9F4F1; // used for branded backgrounds

// Info (dark, 100%, 50%, 5%)
$color-info-text: #003876;
$color-info: #2C64A3;
$color-info-light: #DCE7F7;
$color-info-bg: #F2F6FC;

// Success (dark, 100%, 50%, 5%)
$color-success-text: #265C19;
$color-success: #68D673;
$color-success-light: #B5EBBA;
$color-success-bg: #ECFAEE;

// Danger (dark, 100%, 50%, 5%)
$color-danger-text: #742921;
$color-danger: #EB5757;
$color-danger-light: #F4AAAA;
$color-danger-bg: #FEF7F7;

// Warning (dark, 100%, 50%, 5%)
$color-warning-text: #8A360D;
$color-warning: #F9A443;
$color-warning-light: #FDE8D0;
$color-warning-bg: #FEF6E6;


/*
OBSOLETE
These are the redesign obsolete color system
*/

// Obsolete corporate colors
$color-dark: orange;
$color-main: yellow;
$color-shadow: pink;
$color-light: #2C64A3;

// Obsolete signalling colors
$color-blue: #00B5EC;
$color-green: #A8C813;
$color-gold: #FFD41F;
$color-orange: #F4950D;
$color-orange-light: #FDEACF;
$color-red: #a94442; // #dc3545;
$color-green-light: #ecfaee;
$white: #FFFFFF;

// Obsolete old Enerfip colors
$color-kaki: #F2F6FC;

// https://makandracards.com/makandra/42500-sass-how-to-convert-an-rgba-color-to-its-rgb-look-alike
@function rgba-to-rgb($rgba, $background: #fff) {
  @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%)
}

;

// $color-main-a80: rgba-to-rgb(rgba($color-main, 0.8)); /* #495e7f */
// $white-a50: rgba-to-rgb(rgba(#fff, 0.5), $color-main); /* #8d9baf */
// $color-blue-a20: rgba-to-rgb(rgba($color-blue, 0.2)); /* #d9f2ff */
// $white-a20: rgba-to-rgb(rgba(#fff, 0.2), $color-main); /* #336091 */
// $color-orange-a20: rgba-to-rgb(rgba($color-orange, 0.2)); /* #fdeacf */

// Gestion privée
// see https://github.com/enerfip-dev/enerfip/blob/features/3641-private-bank-white-brand/app/redesign/assets/stylesheets/themes/gestion/_colors.scss
$color-private-main: #AA8B5B;
$color-private-shadow: #846433;
$color-private-light: #D1B78F;
$color-private-main5: #F7E7CE;
