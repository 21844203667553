#arkefip-app-sidebar {
  display: none;
  @media (min-width:960px) {
    display: flex;
    width: 240px;
    min-width: 240px;
    padding: 1.5rem 0 1.5rem 1.5rem;
    height: 100vh;
    position: sticky;
    top: 0;
  }

  &-navigation {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 16px;
    box-shadow: 0 0 0.5rem rgba(0,0,0, 0.05);
    height: 100%;
    position: relative;
    overflow: hidden;
  }
}

.arkefip-app-sidebar-navigation {
  &-header {
    border-bottom: 1px solid var(--arkefip-def-extralight);
    padding: var(--arkefip-spacer-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--arkefip-spacer-sm);
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-md);
    flex-grow: 1;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: var(--arkefip-spacer-sm);
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-footer {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, white, transparent);
    padding: var(--arkefip-spacer-sm);
    &-tobot {
      padding: var(--arkefip-spacer-xs) var(--arkefip-spacer-sm);
      border-radius: 2rem;
      background-color: white;
      box-shadow: var(--arkefip-shadow-sm);
      cursor: pointer;
    }
  }

  &-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    svg {
      fill: var(--arkefip-primary);
      width: 4rem;
    }
  }

  &-account {
    padding: 0px 12px 0px 4px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    &-picture-profile {
      background-color: #55514B;
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }

    &-label {
      flex-grow: 1;
      font-size: 14px;
      color: #55514B;
    }
  }

  &-section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-heading {
      font-size: 12px;
      text-transform: uppercase;
      color: #5F5B54;
      font-weight: 600;
    }

    &-item {
      border-radius: 8px;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      padding: 0px 12px 0px 4px;
      height: 36px;
      align-items: center;
      transition: all ease-in 0.3s;
      color: #5F5B54;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: #F9F8F6;
        color: #423E38;
      }

      &-icon {
        min-width: 32px;
        display: flex;
        justify-content: center;
      }

      &-label {
        font-size: 14px;
      }

      &.active {
        background-color: #22633B;
        color: #FFFFFF;
        font-weight: 500;
      }
    }
  }
}
