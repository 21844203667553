.AppAdvisory {
  &--councelor-card {
    .advisor-image {
      border-radius: 50%;
      height: 80px;
      width: 80px;
      object-fit: contain;
      object-position: center;
      border: 1px solid $border-color;
    }
    .advisor {
      text-transform: uppercase;
      color: $color-base-80;
    }
  }
  &--profile-icon {
    text-transform: uppercase;
    color: $color-base-80;
    padding: map-get($spacers, 2);
    font-size: smaller;
    font-weight: 400;
    width: fit-content;
    &.active {
      color: $primary;
      border: 1px solid $primary;
      border-radius: $border-radius;
    }
    @media only screen and (max-width: map-get($grid-breakpoints,"md")) {
      font-size: 8px;
      padding: 5px;
    }
  }
  &--scores {
    .description {
      font-size: smaller;
    }
  }
}