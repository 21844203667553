.button-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  text-decoration: none;
  background-color: var(--arkefip-def-background);
  color: var(--arkefip-def-dark);

  &:hover {
    opacity: 0.75;
  }

  &.primary {
    background-color: var(--arkefip-interaction);
    color: white;
  }

  &.light {
    background-color: var(--arkefip-terra-background);
    color: var(--arkefip-terra);
  }

  &.outline {
    background-color: transparent;
    color: var(--arkefip-def-dark);
    border: 1px solid var(--arkefip-def-dark);
  }
}