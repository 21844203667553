.arkefip-engagement-card-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--arkefip-spacer-md);
}

.arkefip-engagement-card {
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  background: white;
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;

  @include media-breakpoint-up(md) {
    flex-direction: row;

    & > div:nth-child(n) {
      width: 50%;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .arkefip-engagement-card-cover {
        justify-content: flex-start;
      }
    }
  }
}
 
.arkefip-engagement-card-content {
  display: flex;
  padding: var(--arkefip-spacer-xl);
  flex-direction: column;
  text-align: left;
  gap: var(--arkefip-spacer-sm);
  flex: 1 0 0;
  position: relative;

  &-number {
    position: absolute;
    right: -25%;
    font-size: var(--arkefip-text-sm);
    top: -5%;
    color: var(--arkefip-def-extralight);
    font-family: var(--arkefip-font-handwriting);
    font-weight: 700;
    mix-blend-mode: multiply;
  }

  h2 {
    display: flex;
    flex-direction: column;
    color: var(--arkefip-primary);
    font-size: 2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 36px */
    padding: 0;

    span:first-child {
      font-weight: 500;
    }
  }
  quote {
    color: var(--arkefip-primary);
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%; /* 21.6px */
  }
  & > p {
    color: #7C8E85;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 20.4px */
  }
}
 
.arkefip-engagement-card-cover {
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 240px;

  @include media-breakpoint-up(md) {
    height: auto;
  }
}
 
.arkefip-engagement-card-cover-filter {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 36px;
}
 
.arkefip-engagement-card-cover-filter-pills {
  display: flex;
  padding: 4px 9px;
  text-transform: uppercase;
  color: #34945B;
  border-radius: 200px;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  background: #F3F8F3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}