.page-pages-hispanidad_30 {
  main {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-lg);
  }
}
.page-hispanidad-logo {
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100vw;
  display: flex;
  justify-content: center;
}
.page-hispanidad-cover {
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url('../../assets/images/pages/landing/hispanidad-23/background-hispanidad.webp');
}
.page-hispanidad-hero-banner {
 width: 100%;
 @include media-breakpoint-up(lg) {
  max-width: 960px;
 }
}
.page-hispanidad-icon {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--arkefip-primary);
}
.arkefip-slide-content-group-btn-sublabel {
  font-size: 0.875rem;
}
.arkefip-slide-content-group-btn-sublabel-link {
  display: flex;
  gap: 8px;
  flex-direction: column;
  font-size: 0.875rem;
}
.arkefip-slide-content-group-btn-warning {
  display: flex;
  gap: 8px;
  align-items: center;
  background: var( --arkefip-def-extralight);
  padding: 8px;
  border-radius: 8px;
}
.arkefip-slide-content-group-btn-hispanidad {
  display: flex;
  gap: 8px;
}
#hispanidad-accordion {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);
}
.arkefip-slide-content-group-btn-hispanidad-accordion {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  i {
    transition: all ease-out 0.2s;
    transform: rotate(45deg);
  }
  &.collapsed {
    i {
      transform: rotate(0deg);
    }
  }
}
#reglamentacionSidesheet {
  i {
    color: var(--arkefip-primary);
  }
}
.hispanidad-label{
  display: flex;
  font-family: var( --arkefip-font-handwriting);
  font-size: 24px;
  color: var(--arkefip-primary);
  align-items: center;
}
.hispanidad-accordion-label {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  color: var(--arkefip-primary);
  align-items: center;
}
.slide-content-group-hispanidad{
  i {
    color: var(--arkefip-primary);
  }
}
