#arkefip-storypage-timeline {
  &-creditagricole {
    position: absolute;
    width: 33%;
    left: 3rem;
    transform: translateY(-20vh);
    z-index: -2;
    display: flex;
    align-items: center;

    &-sofilaro-card {
      width: 30%;
      transform: rotate(8deg);
      margin-left: -3rem;
    }

    &-village-badge {
      width: 60%;
      transform: rotate(-16deg);
    }
  }
}

.arkefip-storypage-timeline-2014 {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 0;

  @media (min-width: 720px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-cover {
    display: flex;
    flex-direction: column;
    gap: 0;

    &-image {
      margin: 0 0 -3rem 10%;

      img {
        border-radius: 1rem;
        box-shadow: var(--arkefip-shadow-lg);
      }
    }

    &-description {
      width: 100%;
      max-width: 400px;
      background: white;
      padding: 1rem;
      border-radius: 1rem;
      box-shadow: var(--arkefip-shadow-lg);
    }
  }

  .arkefip-storypage-instant {
    transform: rotate(6deg);

    @media (min-width: 720px) {
      margin-left: -3rem;
    }
  }
}

.arkefip-storypage-timeline-2015 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  h4 {
    color: var(--arkefip-primary);
  }

  p {
    font-size: 1.2rem;
    opacity: 0.5;
  }

  &-firstraise {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 3rem;

    @media (min-width: 720px) {
      flex-direction: row;
      justify-content: center;
    }

    img {
      width: 100%;
      max-width: 240px;
      border-radius: 1rem;
      box-shadow: var(--arkefip-shadow-lg);
      transform: rotate(-6deg);
      margin-top: 2rem;
    }
  }

  &-firstevent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media (min-width: 720px) {
      flex-direction: row;
      align-items: center;
    }

    .arkefip-storypage-instant {
      transform: rotate(6deg);
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    justify-content: center;

    h4 {
      font-size: 2rem;
      font-family: var(--arkefip-font-handwriting);
    }

    p {
      margin-bottom: 0;
    }
  }
}

.arkefip-storypage-timeline-2017 {
  display: flex;
  flex-direction: column;

  @media (min-width: 720px) {
    padding: 0 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
  }

  &-item {
    display: flex;
    flex-direction: column;

    &:nth-child(0n+1) {
      padding-top: 3rem;
      align-items: flex-end;

      .arkefip-storypage-timeline-2017-item-cover {
        padding-right: 15%;
      }
    }

    &:nth-child(0n+2) {
      align-items: center;
    }

    &:nth-child(0n+3) {
      padding-top: 3rem;
      align-items: flex-start;

      .arkefip-storypage-timeline-2017-item-cover {
        padding-left: 15%;
      }
    }

    &-cover {
      img {
        border-radius: 1rem;
        box-shadow: var(--arkefip-shadow-lg);
      }
    }

    &-content {
      margin-top: -3rem;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 1rem;
      box-shadow: var(--arkefip-shadow-lg);
      padding: 1rem;
      width: 90%;

      h4 {
        font-size: 1.2rem;
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }

    &-croissanceverte {
      position: absolute;
      transform: rotate(-16deg);
      width: 10vw;
    }

    &-first {
      position: absolute;
      transform: rotate(-16deg) translateY(-2rem);
      width: 7vw;
    }
  }

  &-pv {
    position: absolute;
    left: -22vw;
    transform: rotate(16deg) translateY(-15vh);
    z-index: -2;
  }
}

.arkefip-storypage-timeline-2018 {
  // width: 100vw;
  display: flex;
  flex-direction: column;


  @media (min-width: 720px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    max-width: 1000px;
  }


  &-cover {
    @media (min-width: 720px) {
      margin-right: -8rem;
    }

    img {
      width: 100%;

      @media (min-width: 720px) {
        min-width: 500px;
      }
    }
  }

  &-content {
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-logo {
      img {
        width: 100%;
        max-width: 240px;
      }
    }

    &-title {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &-description {
      opacity: 1;
    }
  }
}

.arkefip-storypage-timeline-2019 {
  // width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 720px) {
    max-width: 1000px;
    padding: 0 4rem;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 4rem;
    align-items: center;
  }

  &-item {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    align-items: flex-start;

    &-cover {
      padding-left: 15%;
    }

    &-cover {
      img {
        border-radius: 1rem;
        box-shadow: var(--arkefip-shadow-lg);
      }
    }

    &-content {
      margin-top: -3rem;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 1rem;
      box-shadow: var(--arkefip-shadow-lg);
      padding: 1rem;
      width: 90%;

      h4 {
        font-size: 1.2rem;
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
  }

  &-party {
    display: flex;
    justify-content: flex-end;

    &-lifering {
      position: absolute;

      &::after {
        z-index: -2;
        display: flex;
        width: 14rem;
        height: 14rem;
        content: "";
        background-image: url('../../assets/images/pages/storypage/2019-rope.webp');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 18vw;
        right: 1vw;
      }

      &::before {
        z-index: 1;
        display: flex;
        width: 12rem;
        height: 12rem;
        content: "";
        background-image: url('../../assets/images/pages/storypage/2019-lifering.webp');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -5vw;
        right: -5vw;
      }
    }
  }

  .arkefip-storypage-instant {
    &::after {
      display: flex;
      width: 4rem;
      height: 4rem;
      content: "";
      background-image: url('../../assets/images/pages/storypage/pin-left.webp');
      background-size: cover;
      position: absolute;
      top: -2rem;
      left: 4rem;
    }
  }
}

.arkefip-storypage-timeline-2021 {
  width: 100%;
  max-width: 1000px;
}

.arkefip-storypage-timeline-2022, .arkefip-storypage-timeline-2024 {
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: url(../../assets/images/pages/storypage/2022-navigo.webp);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 15%;

  @media (min-width: 720px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 4rem;
    gap: 4rem;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-child(0n+1) {
      padding-top: 3rem;
      align-items: flex-end;

      .arkefip-storypage-timeline-2017-item-cover {
        padding-right: 15%;
      }
    }

    &:nth-child(0n+2) {
      align-items: center;
    }

    &-cover {
      img {
        border-radius: 1rem;
        box-shadow: var(--arkefip-shadow-lg);
      }
    }

    &-content {
      margin-top: -3rem;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 1rem;
      box-shadow: var(--arkefip-shadow-lg);
      padding: 1rem;
      width: 90%;

      h4 {
        font-size: 1.2rem;
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }

    .arkefip-storypage-instant {
      margin-top: 4rem;
      transform: rotate(4deg);

      &::after {
        display: flex;
        width: 4rem;
        height: 4rem;
        content: "";
        background-image: url('../../assets/images/pages/storypage/pin-left.webp');
        background-size: cover;
        position: absolute;
        top: -2rem;
        left: 4rem;
      }
    }
  }
}

.arkefip-storypage-timeline-2024 {
  // < .arkefip-storypage-timeline-2022
  background: none
}

#arkefip-storypage-timeline-yoga {

  @media (min-width: 720px) {
    position: absolute;
    right: 10vw;
  }

  &::after {
    display: flex;
    width: 4rem;
    height: 4rem;
    content: "";
    background-image: url('../../assets/images/pages/storypage/pin-left.webp');
    background-size: cover;
    position: absolute;
    top: -2rem;
    left: 4rem;
  }
}
