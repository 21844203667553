main, #AppMain {
  .main-support {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 2000;

    #help-btn {
      font-size: 1.2rem;
      border-radius: 10rem;
      box-shadow: 0 0 0 0.2rem rgba($color-brand, 0.3);
      transition: all ease-out 0.6s;
      cursor: pointer;
      color: $white;

      &:hover {
        box-shadow: 0 0 0 1rem rgba($color-brand, 0.3);
      }
    }
  }
}
