.product-offer {
  --section-color-dark: var(--arkefip-def-dark);
  --section-color-primary: var(--arkefip-def-primary);
  --section-color-background: var(--arkefip-def-background);

  &-tiles {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-xs);

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-documentation {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-sm);
  }

  .product-offer-simulator {
    border: 1px solid $primary;
    border-radius: var(--arkefip-spacer-xs);
    padding: var(--arkefip-spacer-sm);
    .simulator-container form {
      text-align: center;
      #sim-submit {
        @extend .btn-primary;

      }
    }
  }
}