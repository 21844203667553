.arkefip-project-page-cover {
  // fallback in case of not having a cover picture
  background: var(--arkefip-primary);
  background-image: linear-gradient(rgba($black, 0), rgba($black, 0.5)), url("../../assets/images/pictures/landscape.webp");

  min-height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  align-items: flex-start;
  padding: 9rem 2rem 2rem 2rem;
  margin: var(--arkefip-layout-padding);
  border-radius: 1rem;

  @include media-breakpoint-up(md) {
    align-items: flex-end;
    justify-content: space-between;
    padding: 8rem 2rem 2rem 2rem;
    flex-direction: row;
  }

  &-brand {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
    }

    &-logo {
      display: flex;
      width: 72px;
      min-width: 72px;
      height: 72px;
      min-height: 72px;
      align-items: center;
      justify-content: center;
      padding: 4px;
      overflow: hidden;
      border-radius: 10rem;
      background-color: white;
      box-shadow: 0 0 0.25rem rgba(black, 0.5);

      img {
        width: 100%;
      }
    }
  }

  h1 {
    margin-bottom: 0;
    text-shadow: 0 0 0.25rem rgba(black, 0.5);

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    span {
      white-space: pre;
    }

    small { font-size: 60%; margin-left: .5em; }
  }

  p {
    text-shadow: 0 0 0.25rem rgba(black, 0.5);
    margin-bottom: 1rem;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &-navigation {
    display: flex;
    gap: 1rem;
  }

  &-featured {
    display: flex;
    font-size: 1rem;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      gap: 1rem;
      flex-direction: row;
    }

    &-item {
      display: flex;

      &-title {}

      &-data {}
    }
  }

}
