.btn-country {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-xs);
  img {
    opacity: 0.8;
  }
  &.active {
    img {
      opacity: 1;
    }
  }
}