.project_tabs {
  overflow: hidden;

  .menu_left {}
  .menu_right {
    margin: 0;
    float: right;

    li {
      margin-right: 0;
    }
  }
}

.project_tabs_container_mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  .dir-btn,
  .pp-button-invest,
  .pp-callback {}
}

.project_tabs_mobile {
  position: sticky;
  z-index: 100;
  top: $header-margin-sm;
}

.is-sticky .fluid-container {
  box-shadow: 0 3px 5px #bbb;

  .projet_tabs {
    padding: 5px 0;
  }
}
