.arkefip-storypage-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    display: flex;
    content: "";
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    height: 6rem;
    border-right: 2px dotted var(--arkefip-def-light);
  }

  &-item {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    &::before {
      display: flex;
      content: " ";
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      background: var(--arkefip-secondary);
      border: 4px solid var(--arkefip-def-light);
      margin-bottom: 2rem;
    }

    &::after {
      display: flex;
      content: "";
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
      height: 3rem;
      border-right: 2px dotted var(--arkefip-def-light);
    }

    h2 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.75rem;
      line-height: 110%;
      text-align: center;
      margin-bottom: 2rem;
    }

    &-year {
      font-family: var(--arkefip-font-handwriting);
      font-size: 2.5rem;
      color: var(--arkefip-primary);
    }

    &-content {
      font-size: 1.2rem;
      opacity: 0.5;
      text-align: center;
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3rem;
    }

    &-cover {
      display: flex;
      width: 75%;
      border-radius: 1rem;
      box-shadow: var(--arkefip-shadow-lg);
      padding: 0;
      margin: 1.5rem 0;
      overflow: hidden;

      img {
        margin: 0;
      }
    }
  }
}
