.arkefip-storypage-confettis {
  background: url(../../assets/images/pages/storypage/2014-confettis-mobile.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (min-width: 720px) {
    background: url(../../assets/images/pages/storypage/2014-confettis.webp);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 35%;
  }
}

#confetti {
  pointer-events: none;
  position: absolute;
  z-index: 3005;
  right: -100px;
  top: 0;
  width: 100%;
  height: 100%;
}

.product-invest {
  #confetti {
    width: 300px;
    height: 200px;
    top: -120px
  }
}
