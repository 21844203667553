.AppSettings-step-subtitle {
  margin: map-get($spacers, 3) 0;
  padding: map-get($spacers, 3) 0;
  border-bottom: 1px solid $border-color;
}


.AppSettings--form-validation-wrapper {
  position: sticky;
  bottom: 0;
  height: 70px;
  padding: map-get($spacers, 1);
  background: white;
  border-top: 2px solid $color-success;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
    position: relative;
    flex-direction: column;
    padding: map-get($spacers, 3) 0;
    margin: map-get($spacers, 3) 0;
    min-height: map-get($spacers, 6);
  }
}
