// Contents
//
// body.page-users-sessions
// body.page-users-passwords

.page-users-sessions,
.page-users-omniauth_callbacks,
.page-users-passwords {
  background-image: url("../../assets/images/pictures/aerial-trees-small.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include media-breakpoint-up(lg) {
    background-image: url("../../assets/images/pictures/aerial-trees.webp");
  }

  footer {
    display: none;
  }
  .page {
    min-height: 100vh;
    display: flex;
    width: 100%;
    max-width: 600px;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;

    @include media-breakpoint-up(md) {
      padding: 4rem;
    }

    .panel {
      box-shadow: 0 0 1rem #00000033;
      &-body {
        display: flex;
        flex-direction: column;
        gap: var(--arkefip-spacer-sm);

        .form-inputs {
          display: flex;
          flex-direction: column;
          gap: var(--arkefip-spacer-sm);
        }
      }
      .help-block {
        font-size: var(--arkefip-text-xs);
        @extend .mt-2 ;
        line-height: inherit;
      }
    }
  }
}


#signin-page {
  min-height: 100vh;
  display: flex;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding: 4rem;
  }
}
