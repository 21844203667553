.EventCard- {
  &-list {
    max-height: 600px;
    overflow: auto;
  }

  &-item {
    display: flex;
    align-items: center;
    border-radius: map-get($spacers, 3);
    background: $white;
    box-shadow: $box-shadow-lg;
    -webkit-box-shadow: $box-shadow-lg;
    padding: map-get($spacers, 2);
    grid-gap: map-get($spacers, 2);

    &-sm {}
  }

  &-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 36px;
    border-right: 1px solid $border-color;
    padding-right: map-get($spacers, 3);
    padding-left: map-get($spacers, 2);

    &-day {
      font-size: 1rem;
      color: $color-danger;
      font-weight: bold;
    }

    &-month,
    &-year {
      font-weight: bold;
      color: $color-base-50;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 1rem;
    font-weight: 500;
  }

  &-details {
    display: flex;
    color: $color-base-80;
    align-items: center;
  }

  &-hour {
    padding: 0.125rem 0.375rem;
    background-color: $color-danger-bg;
    color: $color-danger;
    margin-right: map-get($spacers, 1);
    border-radius: map-get($spacers, 1);
  }

  &-address {}

  &-details {
    display: flex;
    align-items: center;
    grid-gap: map-get($spacers, 1);

    p {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-secondary;
    }
  }
}

.EventTab- {
  &-item {
    align-items: center;
    border-radius: map-get($spacers, 3);
    background: $white;
    box-shadow: $box-shadow-lg;
    -webkit-box-shadow: $box-shadow-lg;
    padding: map-get($spacers, 2);
    grid-gap: map-get($spacers, 2);
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 1rem;
    font-weight: 500;
  }

  &-link {
    border: none !important;
  }

  &-list {
    border: none !important;
  }
}


.ex-footer- {
  &-list-group {
    display: flex;
    flex-direction: column;
    grid-gap: 0.375rem;

    &-item {
      display: flex;
      flex-direction: row;
      grid-gap: 0.375rem;
      align-items: center;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }
    }
  }
}
