.arkefip-brand-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(md) {
    left: calc(var(--arkefip-layout-padding)*2);
    width: fit-content;
  }
}

.arkefip-brand-centered {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.arkefip-brand {
  padding: var(--arkefip-spacer-sm);
  border-radius: 0 0 1rem 1rem;
  color: var(--arkefip-def-background);
  // box-shadow: var(--arkefip-shadow-lg);

  svg {
    width: 2.75rem;

    @include media-breakpoint-up(md) {
      width: 3.25rem;
    }
  }

  &-sm {
    padding-top: var(--arkefip-spacer-sm);
    padding-right: var(--arkefip-spacer-sm);
    padding-left: var(--arkefip-spacer-sm);
    padding-bottom: var(--arkefip-spacer-sm);
    border-radius: 0 0 1rem 1rem;
    color: var(--arkefip-def-background);
    box-shadow: var(--arkefip-shadow-lg);

    svg {
      width: 2rem;

      @include media-breakpoint-up(md) {
        width: 3rem;
      }
    }
  }

  &-white {
    background: var(--arkefip-def-background);

    svg {
      fill: var(--arkefip-primary);
    }
  }

  &-primary {
    background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-dark.webp'), var(--arkefip-dark);
    background-blend-mode: MULTIPLY, NORMAL;
    background-size: 10rem;

    svg {
      fill: var(--arkefip-def-background);
    }
  }

  &-terra {
    background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-dark.webp'), var( --arkefip-terra-primary);
    background-blend-mode: MULTIPLY, NORMAL;
    background-size: 10rem;

    svg {
      fill: var(--arkefip-def-background);
    }
  }
}
