.page-pages-landing_project_owner {
  .arkefip-home-choice-sidecard {
    @include media-breakpoint-up(md) {
      min-width: 400px;
      max-width: 600px;
    }
  }
}

/*
Arkefip tiles
*/

.arkefip-section-tiles {
  padding: var(--arkefip-layout-padding);
}

.arkefip-tile {
  &-grid {
    display: grid;
    gap: var(--arkefip-spacer-md);

    &-2 {
      grid-template-columns: 1fr 1fr;
    }
  }
  &-title-white {
    color: white;
  }
  &-none {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-md);
    font-size: 1rem;
    &.tile-partner-logos {
      max-width: 60vw;
    }

  }
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: var(--arkefip-spacer-md);
  gap: var(--arkefip-spacer-md);
  font-size: 1rem;
  background-color: white;
}
