.section-heading {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);
  align-items: center;
  background-color: var(--section-color-background);
  padding: var(--arkefip-spacer-sm);
  border-radius: 0.5rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    text-align: left;
  }

  &-icon {
    min-width: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--section-color-primary);
    border-radius: 100%;

    i {
      font-size: 20px;
      color: white;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 0;
    color: var(--section-color-dark);
    flex: 1;
  }

  &-title, h2 {
    margin-bottom: 0;
  }

  &-subtitle {
    opacity: 0.8;
  }
  &-toc {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}