.AppSettings--sidebar {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-xs);
  position: sticky;
  top: var(--arkefip-layout-padding);

  &-link {
    padding: var(--arkefip-spacer-sm);
    border-radius: var(--arkefip-spacer-sm);
    line-height: 100%;
    color: var(--arkefip-def-primary);
    font-size: var(--arkefip-text-sm);
    transition: all ease-in-out 0.2s;
    text-decoration: none;

    i {
      width: 1.5rem;
    }

    .AppComponents--tag {
      float: right;
      display: inline-block;
      line-height: .5rem;
    }

    &:hover {
      background: var(--arkefip-def-extralight);
      color: var(--arkefip-def-dark);
    }

    &.active {
      background: var(--arkefip-def-primary);
      color: white;
    }
  }
}
