// Contents
//
// .fas.category-*

// TODO restore with proper @extend .fa-sun
.fas.category {
  &-solaire:before {
   content: "\f185";
  }
  &-eolien:before {
   content: "\f72e";
  }
  &-hydroelectrique:before {
   content: "\f043";
  }
  &-bioenergie:before {
   content: "\f06c";
  }
  &-innovation:before {
   content: "\f0eb";
  }
  &-economie_energie:before {
   content: "\f625";
  }
  &-reseau_chaleur:before {
   content: "\f06d";
  }
  &-reseau_froid:before {
   content: "\f2dc";
  }
  &-mix_energetique:before {
   content: "\f0e7";
  }
  &-stockage:before {
   content: "\f5df";
  }
  &-hydrogen:before {
   content: "\f46a";
  }
}
