.psfp-advantages-risks {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-md);

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  .psfp-advantages, .psfp-risks {
    display: flex;
    flex-direction: column;

    h4 {
      display: flex;
      gap: var(--arkefip-spacer-xs);
      align-items: center;
    }
    i {
      color: var(--arkefip-def-light);
      font-size: 80%;
    }
  }

  .psfp-advantages {
    font-size: var(--arkefip-text-sm);
  }

  .psfp-risks {
    a {
      text-decoration-style: dotted;
      color: var(--arkefip-def-dark);

      i {
        text-decoration: none;
        margin-left: var(--arkefip-spacer-xs);
      }
    }
  }
}