.product-navigation {
  display: none !important;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}
.product-nav {
  flex-wrap: wrap;
  gap: var(--arkefip-spacer-xs);
  font-size: var(--arkefip-text-sm);
  display: flex;
  justify-content: flex-start;

  a {
    padding: 4px 8px;
    border-radius: 0.5rem;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }

    &#nav-summary, &#nav-owners, &#nav-analysis, &#nav-comments {
      background-color: var(--arkefip-def-background);
      color: var(--arkefip-def-primary);
    }
    &#nav-announcements {
      background-color: var(--arkefip-terra-background);
      color: var(--arkefip-terra-primary);
    }
    &#nav-offer {
      background-color: var(--arkefip-ocean-background);
      color: var(--arkefip-ocean-primary);
    }
    &#nav-project {
      background-color: var(--arkefip-pollen-background);
      color: var(--arkefip-pollen-primary);
    }
    &#nav-impact {
      background-color: var(--arkefip-pine-background);
      color: var(--arkefip-pine-primary);
    }
  }
}