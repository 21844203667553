.owner-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--arkefip-spacer-sm);

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &.compact {
    gap: var(--arkefip-spacer-xs);
    font-size: var(--arkefip-text-sm);
  }

  .owner-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    padding: 8px;
    background-color: white;
    border-radius: 100%;
    position: relative;
    border: 1px solid var(--arkefip-def-extralight);

    img {
      width: 100%;
    }

    &.compact {
      width: 40px;
      height: 40px;
      border: none;
    }
  }
  
  .owner-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      align-items: flex-start;
    }

    &-name {
      font-weight: bold;
    }

    &-sub {
      display: flex;
      gap: var(--arkefip-spacer-xs);
      align-items: center;

      &-country {
        display: flex;
        gap: 2px;
        align-items: center;
        cursor: help;
        transition: all ease-in-out 0.2s;

        &:hover {
          opacity: 0.8;
        }
      }

      &-parent {
        font-size: var(--arkefip-text-xs);
        text-transform: uppercase;
        letter-spacing: 1px;
        color: var(--arkefip-def-primary);
        line-height: 1;
        padding: 4px 6px;
        border-radius: 4px;
        background-color: var(--arkefip-def-background);
      }
    }
  }

  .owner-actions {
    display: flex;
    gap: var(--arkefip-spacer-xs);
  }
}