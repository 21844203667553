.arkefip-home-choice {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: linear-gradient(transparent 0%, transparent 50%, var(--arkefip-def-background) 50%);

  @include media-breakpoint-up(md)  {
    display: flex;
    flex-direction: row;
    gap: 0;
  }

  &-sidecard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    background: white;
    padding: 2rem;
    box-shadow: $box-shadow;
    border-radius: 0;

    @include media-breakpoint-up(md) {
      border-radius: 0 1rem 1rem 0;
      min-width: 400px;
      max-width: 400px;
    }
  }

  .arkefip-home-choice-sidecard-subtitle {
    color: var(--arkefip-def-dark);
    font-family: var(--arkefip-font-body);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
  }
}

.arkefip-home-choice-carousel {
  overflow-y: scroll;
  box-sizing: contain;
  flex-grow: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }


  &-group {
    display: flex;
    height: 100%;
    grid-gap: 1rem;
    padding: 2rem;
    :hover {
      box-shadow: var(--arkefip-shadow-lg);
    }
  }

  &-item {
    // background: url('placeholder');
    background-position: center;
    background-size: cover;
    height: 100%;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    box-shadow: $box-shadow;
    border-radius: 1rem;
    padding: 1rem;
    color: white;
    text-decoration: none;

    &-category {
      color: white;
    }

    &-success {
      text-transform: uppercase;
      background-color: var(--arkefip-def-background);
      color: var(--arkefip-dark);
      padding: 0.3rem 0.5rem;
      border-radius: 1rem;
      text-shadow: none;
      font-size: 0.6rem;
      letter-spacing: 1px;
      line-height: 1;
      font-weight: 700;
      box-shadow: var(--arkefip-shadow-lg);
    }

    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-shadow: 0 0 1rem black;
    }

    &-headline {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    &-data {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      color: white;

      &-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    &-divider {
      display: flex;
      width: 100%;
      height: 1px;
      background: white;
      mix-blend-mode: soft-light;
      border-radius: 1rem;
    }

    &-logo {
      background-color: white;
      width: 4rem;
      height: 4rem;
      background-color: white;
      border-radius: 10rem;
      padding: 0.5rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

  }
}
.arkefip-home-choice-carousel-item-name {
  color: white;
  font-family: var(--arkefip-font-headings);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#scroll-r {
  position: absolute;
  right: 2rem;
}
