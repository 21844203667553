@import "home/index";
@import "partners/index";
@import "project_pages/index";
@import "story_and_success/index";

@import "arkefip_registration";
@import "innovation";
@import "signup";
@import "stats";
@import "tutorial";
@import "users";
@import "who_are_we";
@import "newsletter_success";


// Yet to find a place

.page-pages-story_and_success,
.page-pages-commitments,
.page-pages-tutorial,
.page-stats,
.page-pages-landing_project_owner,
.page-pages-who_are_we,
.page-pages-legal_pag,
.page-pages-our_partners_page,
.page-pages-innovation,
.page-pages-vision {
  font-size: 16px;
  width: 100vw;
  background-color: var(--arkefip-def-background);
  color: var(--arkefip-def-darth);
  background: url('../../assets/images/arkefip_pp/topographic-pattern.svg'), var(--arkefip-def-background);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  background-position: top;
  background-attachment: fixed;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    // Setup headings font
    font-family: var(--arkefip-font-headings);
    font-weight: 300;
    // line-height: 1.5;

    // Reinitialize headings margins
    margin-top: 0;
    margin-bottom: 0;

    strong {
      font-weight: 500;
    }

    span.outline {
      -webkit-text-fill-color: transparent;
      font-weight: 500;
      -webkit-text-stroke-width: clamp(var(--min), var(--val), var(--max));
      --min: 1px;
      --val: .2vw;
      --max: 2px;
    }
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
    line-height: 110%;
  }

  p {
    line-height: 130%;
  }
}
