@import url("https://use.typekit.net/vcm0jnz.css");

:root {
  // brand colors
  --arkefip-dark: #1A4C2D;
  --arkefip-primary: #22633B;
  --arkefip-secondary: #34945A;

  // terracotta colors
  --arkefip-terra-dark: #663021;
  --arkefip-terra-primary: #924F3C;
  --arkefip-terra-background: #FFF9F7;

  // interaction color for links and default buttons
  --arkefip-interaction: #924F3C;

  // neutral color instead of greyscale
  --arkefip-def-darth: #423E38;
  --arkefip-def-dark: #55514B;
  --arkefip-def-primary: #736F67;
  --arkefip-def-secondary: #ADA99F;
  --arkefip-def-light: #DEDACE;
  --arkefip-def-extralight: #F2F0EB;
  --arkefip-def-background: #F9F8F6;

  /*
  color palettes
  variants: dark(~darken 0.24), primary(-), secondary(?), background(~lighten 0.95)
  */

  // Pine
  --arkefip-pine-dark: #1A4C2D;
  --arkefip-pine-primary: #22633B;
  --arkefip-pine-secondary: #34945A;
  --arkefip-pine-background: #F3F8F3;

  // Ocean
  --arkefip-ocean-dark: #174261;
  --arkefip-ocean-primary: #346C94;
  --arkefip-ocean-background: #F1F6F9;

  // Plum
  --arkefip-plum-dark: #452C4C;
  --arkefip-plum-primary: #744A7E;
  --arkefip-plum-background: #F6F2F8;

  // Note A B C D E F G
  // #00AD7A #4AB962 #98CD7D #EEE931 #F0B225 #EF843E #DC2A2B
  //
  // --arkefip-note-a-dark:
  --arkefip-note-a-primary: #00AD7A;
  --arkefip-note-a-background: #ddfff5;
  // --arkefip-note-b-dark:
  --arkefip-note-b-primary: #4AB962;
  --arkefip-note-b-background: #edf8ef;
  // --arkefip-note-c-dark:
  --arkefip-note-c-primary: #98CD7D;
  --arkefip-note-c-background: #f5faf2;
  // --arkefip-note-d-dark:
  --arkefip-note-d-primary: #EEE931;
  --arkefip-note-d-background: #fdfdea;
  // --arkefip-note-e-dark:
  --arkefip-note-e-primary: #F0B225;
  --arkefip-note-e-background: #fdf7e9;
  // --arkefip-note-f-dark:
  --arkefip-note-f-primary: #EF843E;
  --arkefip-note-f-background: #fdf3ec;
  // --arkefip-note-g-dark:
  --arkefip-note-g-primary: #DC2A2B;
  --arkefip-note-g-background: #fbeaea;

  // Pollen
  --arkefip-pollen-dark: #9B6903;
  --arkefip-pollen-primary: #BD8005;
  --arkefip-pollen-background: #FFFAF0;

  // Sky color
  --arkefip-sky: #d2e9ed;

  // typography
  --arkefip-font-headings: 'new-spirit', serif; // for now we use ITC Font defined in bootstrap variables

  --arkefip-font-handwriting: 'highest-praise', sans-serif;

  // used as default font fot everything but avoid H1, H2 or H3 headings
  --arkefip-font-body: 'museo-sans', -apple-system,system-ui,BlinkMacSystemFont,"Helvetica Neue",Roboto,Arial,sans-serif;

  // font sizes
  --arkefip-text-xs: 12px;
  --arkefip-text-sm: 14px;
  --arkefip-text-md: 16px;
  --arkefip-text-base: 18px;
  --arkefip-text-lg: 24px;
  --arkefip-text-xl: 32px;
  --arkefip-text-xxl: 40px;

  // materials
  --arkefip-shadow-lg: 0 0 2rem rgba(0, 0, 0, 0.2);
  --arkefip-shadow-sm: 0 0 1rem rgba(0, 0, 0, 0.2);

  --arkefip-layout-padding: 1rem;
  @include media-breakpoint-up(md) {
    --arkefip-layout-padding: 2rem;
  }

  --arkefip-spacer-xs: 0.5rem; // 8px
  --arkefip-spacer-sm: 1rem; // 16px
  --arkefip-spacer-md: 2rem; // 32px
  --arkefip-spacer-lg: 3rem; // 48px
  --arkefip-spacer-xl: 4rem; // 64px
  --arkefip-spacer-2x: 8rem; // 128px

  font-size: 1rem;

}

$header-offset: 2rem; // elder header offset 69px
$header-offset-small: 1.125rem; // elder eader offset on small devices 54px
$header-margin: 4.625rem;
$header-margin-sm: 3.875rem;
