.copy-to-clipboard {
  margin: 10px 0;
  border: 1px solid var(--arkefip-def-secondary);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;

  .copy-to-clipboard-label {
    text-transform: uppercase;
    color: var(--arkefip-def-secondary);
  }

  .copy-to-clipboard-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-to-copy {
    margin-top: 5px;
    margin-right: 5px;
  }

  .copy-content-button {
    padding: 6px 10px;
    border-radius: 40px;
    border: 1px solid var(--arkefip-def-secondary);
    color: var(--arkefip-def-secondary);
  }

  .copy-content-button i {
    font-size: 14px;
  }

  &:hover {
    border-color: var(--arkefip-def-primary);

    .copy-content-button {
      border-color: var(--arkefip-def-primary);
      color: var(--arkefip-def-primary);
    }
  }
}
