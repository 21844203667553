.home-people {
  background: url("../../assets/images/pages/landing/agents/people.webp"); // TODO import assets
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-card {
    // layout
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;

    // style
    background: $primary;
    color: $white;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: $box-shadow;
  }
}
