.tagbar {
  @extend .row  ;
  @extend .overflow-y-hidden ;
  @extend .scrollbar-none ;

  .tagbar-content {
    white-space: nowrap;

    .btn {
      @extend .me-2 ;

      &:last-child {
        @extend .me-0 ;
      }
    }
  }
}
