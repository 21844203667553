.partner-intro {
  // size
  width: 100%;
  max-width: 900px;
  padding: 2rem 4rem;

  // layout
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  align-items: center;
  text-align: center;

  &-plant {
    width: 10vw;
    min-width: 100px;
  }

  &-quote {
    font-family: $headings-font-family;
    font-size: 1rem;
    line-height: 120%;
    font-weight: 300;

    @media (min-width: 900px) {
      font-size: 1.5rem;
    }

    &-author {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 900px) {
        align-items: flex-start;
      }

      &-signature {
        img {
          height: 2.5rem;
          width: 100%
        }
      }

      &-status {
        padding: 0 2rem;
      }
    }

    &-meta {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 900px) {
        flex-direction: row;
        padding: 1rem;
        border-radius: 100rem;
        grid-gap: 1rem;
      }
    }
  }
}

#plant-img {
  width: 10vw;
}
