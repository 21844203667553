.page-projects,
.page-project_previews,
.page-private_projects,
.page-investor_app-projects {
  .arkefip-project-showcase {
    padding: var(--arkefip-layout-padding); // self-responsive
    .projects-header {
      background-image: linear-gradient(rgba($black,0), rgba($black,0.4)), url("../../assets/images/pictures/landscape.webp");
      background-size: cover;
      background-repeat: no-repeat;
      color: $white;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: end;
      padding: 4rem 1rem 1rem 1rem;

      @include media-breakpoint-up(md) {
        justify-content: space-between;
        align-items: flex-end;
        padding: 6rem 2rem 2rem 2rem;
        flex-direction: row;
      }

      .content {
        margin-right: 1rem;
        p {
          margin: 0;
          text-shadow: #000 1px 0 2px;
        }
        h1 {
          margin-bottom: 1rem;
          text-shadow: 0 0 2px rgba(black, 1);
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
          span {
            white-space: pre;
          }
        }
      }
    }

    .status-links {
      gap: var(--arkefip-spacer-sm);
      a {
        font-family: var(--arkefip-font-headings);
        @extend .rounded-2;
        padding: .5em;
        border: 1px solid var(--arkefip-primary);
        color: var(--arkefip-dark);
        &.active, &:hover {
          background: var(--arkefip-primary);
          color: white;
          .icon {
            color: var(--arkefip-primary);
            background: white;
          }
        }
        .icon {
          @extend .rounded-3;
          padding: 3px 6px;
          margin-right: .5rem;
          background: var(--arkefip-primary);
          color: white;
          .fa {
          }
        }
      }
    }
    .pagination-links {
      @extend .my-2;
      text-align: center;
      a {
        display: inline-block;
        @extend .mx-1;
      }
    }
    .projects {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-showcase {
        max-width: 1200px;
      }

      &-showcase-section-heading {
        margin: 3rem 0 2rem 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        @include media-breakpoint-up(md) {
          white-space: nowrap;
          align-items: center;
        }

        &-line {
          display: flex;
          flex-grow: 1;
          height: 1px;
          width: 100%;
          background-color: $border-color;
        }

        h2 {
          margin-bottom: 0;
        }
        &-icon {
          min-width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $primary;
          border-radius: 100%;
          box-shadow: 0 0 16px #00000011;

          &-open {
            background-color: $color-success;
          }

          &-finished {
            background-color: $color-base-60;
          }
          i {
            color: $background;
            font-size: 1.2rem;
            position: relative;
          }
        }
      }
    }


    &-grid {
      display: flex;
      flex-direction: column;
      grid-gap: 1.125rem;
      @include media-breakpoint-up(md) {
        display: grid;
        grid-gap: 1.125rem;
        grid-template-columns: 1fr 1fr;
      }
      @include media-breakpoint-up(lg) {
        display: grid;
        grid-gap: 1.125rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    .more-card {
      height: calc(100% - 30px);
      .fas { padding-bottom: 1em; }
    }
    .projects-empty {
      padding-top: 0px;

      > .row {
        background-color: $white;
        @extend .card;
        overflow: hidden;
        flex-direction: row !important;
      }

      h2:before {
        content: none;
      }

      .text-underline {
        text-decoration: underline;
      }

      #projects-contact-form {
        .has-error {
          .help-block {
            color: theme-color("warning");
            margin-top: 0.5rem;
            padding-left: 1.25rem;
            display: inline-block;
          }
        }
        // @include media-breakpoint-up(sm) {
        //   @extend .border-left ;
        //   }
      }
    }

    .row {
      @include media-breakpoint-up(xxxl) {
        .col-xxxl-2-10 {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
    }
  }

    // Projets réservés & Gestion privée
  .reserved-projects, .private-projects {
    .row {
      align-items: center;
      justify-content: center;
    }
  }
}
