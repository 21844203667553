.list-phases {

  .list-phases-item {
    &-current {
      li {
        &:before {
          font-family: "Font Awesome 6 Free";
          content: "\f024";
          font-weight: 900;
          font-size: 80%;
          margin-right: 0.5rem;
          color: $color-success;
        }
      }
    }

    &-outdated,
    &-finished {
      li {
        &:before {
          font-family: "Font Awesome 6 Free";
          content: "\f024";
          font-weight: 900;
          font-size: 80%;
          margin-right: 0.5rem;
          color: $color-success;
        }
      }
    }

    &-tocome {
      li {
        &:before {
          color: $color-gold;
          font-family: "Font Awesome 6 Free";
          content: "\f252";
          font-weight: 900;
          font-size: 80%;
          margin-right: 0.5rem;
        }
      }
    }

    &-end {
      p {
        &:before {
          font-family: "Font Awesome 6 Free";
          content: "\f11e";
          font-weight: 900;
          font-size: 80%;
          margin-right: 0.5rem;
          color: $color-base-60;
        }
      }
    }
  }
}
