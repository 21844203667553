.AppTitle {
  display: flex;
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: map-get($spacers, 2);

  &--back-link {
    margin-right: map-get($spacers, 3);
    padding: map-get($spacers, 3) map-get($spacers, 2);
    background-color: $secondary;
    color: $color-base-80;
    font-size: 18px;
    margin-top: map-get($spacers, 4);

    @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
      font-size: 14px;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, "md")) {
      display: none;
    }
  }

  &--title {
    margin-top: 0;
    font-weight: 300;

    @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
      font-size: 18px;
    }
  }

  &--subtitle {
    font-size: 18px;
    color: $color-base-60;
    font-weight: lighter;

    @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
      font-size: 14px;
    }
  }
}
