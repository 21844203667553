#announce_banner {
  @extend .mt-5;
  background: var(--arkefip-def-background);
  border-color: var(--arkefip-def-light);

  p {
    color: var(--arkefip-def-primary);
  }

  .fas, .fa {
    color: var(--arkefip-def-primary);
  }

}
