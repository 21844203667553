.bg-light {
  background: $color-brand-bg !important;
}

.arkefip-bg-leaves {
  color: var(--arkefip-def-background);
  background: var(--arkefip-def-primary);
  @include media-breakpoint-up(md) {
    background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-dark.webp'), var(--arkefip-def-primary);
    background-blend-mode: MULTIPLY, NORMAL;
    background-position: bottom;
    background-size: 20vw;
  }

  &-primary {
    color: var(--arkefip-def-background);
    background: var(--arkefip-dark);
    @include media-breakpoint-up(md) {
      background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-dark.webp'), var(--arkefip-dark);
      background-blend-mode: MULTIPLY, NORMAL;
      background-size: 20vw;
      background-position: bottom;
    }

    &-light {
      color: var(--arkefip-def-dark);
      background: var(--arkefip-pine-background);
      @include media-breakpoint-up(md) {
        background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-linen.webp'), var(--arkefip-pine-background);
        background-size: 20vw;
        background-position: bottom;
      }
    }
  }

  &-action {
    background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-dark.webp'), var(--arkefip-interaction);
    background-blend-mode: MULTIPLY, NORMAL;
    background-size: 20vw;
    background-position: bottom;
    color: var(--arkefip-def-background);
  }

  &-light {
    background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-linen.webp'), var(--arkefip-def-extralight);
    background-size: 20vw;
    background-position: bottom;
    color: var(--arkefip-def-dark);
  }

  &-plum {
    background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-dark.webp'), var(--arkefip-plum-primary);
    background-blend-mode: NORMAL, NORMAL;
    background-size: 20vw;
    background-position: bottom;
    color: var(--arkefip-plum-background);

    img.innovation-logo {
      filter: brightness(5);
    }

    &-light {
      background: url('../../assets/images/patterns/arkefip-pattern-organic_leaves-linen.webp'), var(--arkefip-plum-background);
      // background-blend-mode: NORMAL, NORMAL;
      background-size: 20vw;
      background-position: bottom;
      color: var(--arkefip-plum-dark);
    }
  }
}
