.footer-regulators {
  margin: var(--arkefip-layout-padding);
  padding: var(--arkefip-layout-padding);
  background-color: white;
  border-radius: 1rem;

  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &-item {
    display: flex;
    gap: var(--arkefip-spacer-sm);
    align-items: center;
    flex: 1;

    &-image {
      max-width: 72px;
    }

    &-text {
      font-size: var(--arkefip-text-xs);
    }
  }

}