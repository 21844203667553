// Contents
//
// .page-projects .comment-box

.page-projects {
  .comment-area > .form-group > .form-group > textarea.form-control {
    min-height: 150px !important;
  }

  .comment-box {
    border-radius: 5px;
    overflow: hidden;
    background-color: transparent;
    padding: 0;
  }

  .author-admin {
    @extend .border-primary !optional;
    .card-header {
      p:before {
        font-family: "Font Awesome 6 Free";
        color: $primary;
        content: "\f058";
        font-weight: 900;
        font-size: 80%;
        margin-right: 0.5rem;
      }
    }
  }

  .comment-answer {
    margin-left: 2rem;
  }

  .comment-manage {
    a, .btn {
      margin-right: 2.5px;
      margin-left: 2.5px;
    }
  }

  .new-comment {
  }
}
