.arkefip-pulse {
  box-shadow: 0 0 0 0 rgba(white, .5);
  -webkit-animation: pulse 2s 5;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(white, .5);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(white, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(white, 0);
  }
}

.arkefip-red-pulse {
  box-shadow: 0 0 0 0 rgba($color-danger-text, .5);
  -webkit-animation: red-pulse ease-in-out 2s alternate 4;
}

@-webkit-keyframes red-pulse {
  from {
    box-shadow: 0 0 10px 0 rgba($color-danger-text, .5);
  }

  to {
    box-shadow: 0 0 10px 10px rgba($color-danger-text, 0);
  }
}
