.page-accounts-reportings,
.page-investor_app-reportings-show,
.page-investor_app-reportings-show_quarter {
  $reportings-margin-x: 80px;
  margin: 0;
  font-size: 1em;

  main {
    padding: 0;
  }

  // table container for thead & tfoot repeat
  table.pages-container {
    width: 100%;
    border: none;

    .page-header th {
      display: none;
      color: $secondary;
    }

    .page-footer {
      background-color: $secondary;
      td {
        padding: 20px $reportings-margin-x;
      }

      // counter-increment: page;
      // #page-number {
      //   float: right;
      //   &:after {
      //     content: counter(page);
      //   }
      // }

      .footer-logo {
        svg {
          fill: var(--arkefip-primary);
          width: 6rem;
        }
      }
    }

    @media print {
      .page-header th {
        display: table-cell;
        height: 30px;
        padding: 20px $reportings-margin-x;
        text-align: right;
      }
    }
  }

  .page {
    // min-height: 100vh;
    padding: 30px 0;
    margin: 0 $reportings-margin-x;

    &-cover {
      min-height: 100vh;
      position: relative;
      padding: 0 $reportings-margin-x;
      margin: 0;

      @media print and (orientation: portrait) {
        min-height: 180vh;
      }

      @media print and (orientation: landscape) {
        min-height: 130vh;
      }

      &-background {
        position: absolute;
        right: 0;
        background-color: $secondary;
        height: 100%;
        width: 60%;
        z-index: -1;
      }
    }

    &-about {
      min-height: 100vh;
      position: relative;

      @media print {
        margin-top: -70px;
      }
    }

    &-container {
      margin: 0 (-$reportings-margin-x);
      background-color: $secondary;
      &-content  {
        padding: 50px $reportings-margin-x;
      }
    }

    &-hr {
      border-bottom: 1px solid $secondary;
      margin: 50px (-$reportings-margin-x);
    }
  }

  .cover-title {
    margin-top: 100px;
    font-size: 800%;
    font-weight: 800;
    border: none;

    small {
      font-size: 75%;
    }

    @media print and (orientation: landscape) {
      br {
        display: none;
      }
    }
  }

  .cover-subtitle {
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.5em;

    .reporting-date {
      color: var(--arkefip-primary);
      opacity: 0.8;
    }
  }

  .cover-spacer {
    width: 60px;
    margin: 50px 0;
    border-bottom: 6px solid var(--arkefip-def-darth);
  }

  .cover-details {
    font-size: 2em;
  }

  .cover-logo {
    margin-top: 50px;
    svg {
      fill: var(--arkefip-primary);
      width: 6rem;
    }
  }

  .cover-footer {
    width: 35%;
    margin-top: 100px;
    padding-bottom: 50px;
    font-size: 2em;

    .cover-footer-item {
      border-bottom: 1px solid $secondary;
    }

    @media print {
      position: absolute;
      bottom: 0px;
    }
  }

  .cover-help {
    padding-bottom: 50px;
    font-size: 2em;
    position: absolute;
    bottom: 0px;
    right: $reportings-margin-x;
    text-align: right;

    a {
      font-weight: bold;
    }
  }

  .about-text {
    font-size: 1.5em;
    margin: 50px 0;
  }

  .about-content {
    font-size: 1.5em;
    margin: 50px 0;

    p, ul {
      line-height: 1.5;
    }
  }

  h1 {
    font-size: 3em;
    font-weight: 800;
    border-bottom: 6px solid var(--arkefip-def-darth);
    display: inline-block;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2em;
    font-weight: 600;
    border-bottom: 4px solid $secondary;
    margin: 30px 0;
  }

  ul {
    li {
      margin-left: 25px;
    }
  }

  .info-box {
    padding: 15px;
    height: 100%;
    border-radius: 5px;

    .fas {
      font-size: 2em;

      &.category {
        &-solaire {
          color: $yellow;
        }
        &-eolien {
          color: $secondary;
        }
        &-hydroelectrique {
          color: $secondary;
        }
        &-bioenergie {
          color: $green;
        }
        &-innovation {
          color: $orange;
        }
        &-economie_energie {
          color: $red;
        }
        &-reseau_chaleur {
          color: #525252; // $brown;
        }
        &-reseau_froid {
          color: $secondary;
        }
        &-mix_energetique {
          color: #dde9e6; // $kaki;
        }
        &-stockage {
          color: $energy-stockage;
        }
        &-hydrogen {
          color: $energy-hydrogen;
        }
      }
    }

    &-empty {
      color: $secondary;
      border-color: $secondary !important;

      .fas {
        color: inherit !important;
      }
    }

    .strong {
      font-weight: bolder;
      text-transform: uppercase;
    }

    .text-small {
      @include font-size($small-font-size);
    }

    .number {
      font-size: 1.5em;
    }
  }

  .chart {
    background-color: $secondary;
    height: 400px;
  }

  table.table {
    color: inherit;
    border-collapse: collapse;

    td, th {
      font-size: inherit;
      border-left: 1px dashed $secondary;
      border-end: 1px dashed $secondary;

      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-end: none;
      }

      p.note {
        margin-block-end: 0;
      }

      &.text-small {
        @include font-size($small-font-size);
      }
    }

    thead {
      th {
        border-top: none;
        border-bottom: 2px solid var(--arkefip-def-darth);
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    tfoot {
      td {
        text-align: inherit;
      }
    }

    tr.row-total {
      font-weight: bold;
      background-color: $secondary;
      text-transform: uppercase;
      white-space: nowrap;

      &-global {
        background-color: $secondary;
        // tr background-color not working on print
        @media print {
          background-color: $secondary;
        }
      }
    }

    tr.spacer {
      td {
        border: none;
      }
    }
  }

  .end-page, .end-page-no-break {
    border-bottom: 1px solid $secondary;

    &:last-child {
      margin-bottom: 50px;
    }
  }

  .badge {
    background-color: $dark;
    padding-top: 0.3rem;
  }

  .reference[title] {
    cursor: help;
  }

  #admin_panel {
    display: none;
  }

  #projects_actualities {
    a {
      text-decoration: underline;
    }

    .image-wrapper {
      height: 25vh;

      div {
        background-size: cover;
        background-position: center;
      }

      @media print {
        height: 20vh;
      }
    }

    .reset {
      // disable card display
      > .card {
        border: none;
        .card-header {
          padding: 0.5rem 0;
          border: none;
        }
        .card-body {
          padding: 0;
        }
        .card-body span[style] {
          font-size: 1em !important;
        }
      }
      // hide big images outside table display
      > p > img,
      > p.img-caption {
        display: none;
      }
      // reset overriden styled content
      span[style], pre[style], p[style], div[style] {
        color: inherit !important;
        font-size: inherit !important;
        font-family: inherit !important;
        background-color: inherit !important;
        letter-spacing: inherit !important;
      }
      // reduce content headlines
      h1, h2, h3, .h1, .h2, .h3 {
        font-size: 1em;
        font-weight: bold;
        border-bottom: none;
        margin-bottom: 0.5rem;
      }
    }
  }

  @media print {
    zoom: 0.72;
    -moz-transform: scale(0.72);
    -moz-transform-origin: 0 0;

    .page-section {
      page-break-inside: avoid;
      break-inside: avoid;
    }
    .end-page {
      page-break-after: always;
      break-after: page;
    }
  }
}
