.form_custom_simple {

  table {
    margin-bottom: 0;
  }

  table tr td {
    height: 20px;
    line-height: 25px !important;
    width: 50%;
    text-align: right;
    font-size: 150%;
    padding: 0;
  }

  input {
    border: none;
    margin: 5px 0;
  }

  input[type="text"],
  input[type="number"] {
    padding: 0 10px;
    margin: 0;
    line-height: 16px;
    width: 5em;
  }

  p,
  label {
    font-weight: normal;
    margin-right: 1em;
    text-align: right;
    font-size: 90%
  }

  small {
    font-size: 80%
  }


  .sim-amount-container {
    display: block;
    background: transparent;
    padding: 0 0;
    margin: 0;

    i {
      margin-left: 10px;
      font-size: 16px;
      color: gray;
    }
  }

  button {
    display: inline;
    border: none;
    margin: 0;
    padding: 0;
    height: auto;
    line-height: 16px;
    background: white;
  }

  .total-roi {
    background: lighten($orange, 5);
    padding: 0;
    margin: 0;
    text-align: left;
    padding-left: 10px;
    display: block;
    line-height: 28px;
  }
}
