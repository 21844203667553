// Components specific to Enerfip en Roue Libre

.eerl_landing_page- {
  &-presentation {
    color: $primary;

    &-team {
      border-radius: map-get($spacers, 3);
      background: $white;
      color: $primary;

      padding: map-get($spacers, 2);
      box-shadow: $box-shadow-lg;
      -webkit-box-shadow: $box-shadow-lg;

      &-separator {
        border-left: $border-width solid $border-color;
      }
    }
  }

  &-content {
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: -8rem;
    }
  }

  &-content-box {
    border-radius: map-get($spacers, 3);
    background: $white;
    color: $primary;

    padding: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
      padding: map-get($spacers, 6);
      box-shadow: $box-shadow-lg;
      -webkit-box-shadow: $box-shadow-lg;
    }
  }

  &-agenda {
    h2 {
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }

  &-menu {
    &-img {
      position: relative;
      top: -110px;
      height: 720px;
      width: 680px;
      object-fit: contain;
      background-color: white;

      @include media-breakpoint-down(md) {
        top: -10px;
        height: 350px;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: $lead-font-size;
    }
  }
}

// LANDING PAGES ENERFIP-LA GUILDE FOR LIBAN & VELOCITE

#donation_landing_page- {
  &-wrapper {
    h1 {
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
  }

  &-Header {

    &-title {
      justify-content: center;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(md) {
        justify-content: start;
      }
    }

    img {
      height: 24px;

      @include media-breakpoint-up(md) {
        height: 36px;
      }
    }
  }

  &-sticky-menu {
    top: calc(#{$header-margin} + 1rem);
    z-index: 1019 !important;
  }

  &-sticky-menu-mobile {
    top: $header-margin-sm;
    z-index: 1019 !important;
    overflow-y: hidden;
    white-space: nowrap;
  }

  &-sticky-menu-mobile, &-sticky-menu {
    .nav-link {
      color: $dark;
      background: $secondary;
    }
    .nav {
      grid-gap: 0.75rem;
    }
  }

  &-cta-mobile {
    position: fixed;
    bottom: 18px;
    left: 18px;
    z-index: 1020;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.donation_landing_page- {
  &-bread {
    a {
      color: $dark;

      &:hover {
        color: $color-base-80;
      }
    }
    &-start {
      justify-content: flex-start;
    }
    &-end {
      justify-content: flex-end;
    }
    &-start, &-end {
      @include media-breakpoint-down(md) {
        justify-content: center;
      }
    }
  }

  &-page-content {
    p, li, .alert {
      font-size: $lead-font-size;
    }
    h2 {
      padding-bottom: map-get($spacers, 4);
    }
    blockquote {
      font-size: 150%;
      font-style: italic;
      color: $color-base-80;

      &:before {
        content: "« ";
      }

      &:after {
        content: " »";
      }
    }
  }
}
