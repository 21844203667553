/*
Interaction card or panel, used when button label is too long and must not be under white-space nowrap
*/

.arkefip-interaction {
  &-list {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-sm);
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--arkefip-spacer-sm);
  gap: var(--arkefip-spacer-sm);
  border-radius: 0.5rem;
  border: 1px solid var(--arkefip-interaction);
  transition: all ease-in-out 0.25s;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: var(--arkefip-interaction);
    color: white;
  }

  &-fill {
    background-color: var(--arkefip-interaction);
    color: white;

    &:hover {
      background-color: var(--arkefip-def-dark);
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
  }

  &-check {
    &:hover {

      &::after {
        transform: rotate(0deg);
        background-color: white;
        color: var(--arkefip-interaction);
      }
    }

    &::after {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
      border-radius: 2rem;
      border: 1px solid var(--arkefip-interaction);
      background-color: transparent;
      color: transparent;
      content: "\2713";
      transition: all ease-in-out 0.5s;
      transform: rotate(-45deg);
    }
  }
}
