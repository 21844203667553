.arkefip-button {
  border: 1px solid var(--arkefip-interaction);
  color: var(--arkefip-interaction);
  text-decoration: none;
  border-radius: 4rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.25rem;
  transition: all ease 0.2s;
  cursor: pointer;
  box-shadow: 0 0 0 0 transparent;


  &-label {
    font-size: 1rem;
    font-weight: 500;

    &:first-of-type {
      padding: 0 0 0 1rem;
    }

    &:last-of-type {
      padding: 0 1rem 0 0;
    }

    &:only-of-type {
      padding: 0 1rem;
      align-self: center;
    }
  }

  // &-icon {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     width: 30px;
  //     height: 30px;
  //     transition: all 0.25s ease-in-out;

  //     &-fill {
  //       background: var(--arkefip-contrast);
  //       color: ;
  //       border-radius: 100%;
  //       margin-left: 0.5rem;
  //     }
  // }
  &:hover {
    color: var(--arkefip-interaction);
    box-shadow: 0 0 0 0.1rem var(--arkefip-interaction);
    opacity: 0.9;
  }
}

.arkefip-button-outline-white {
  border: 1px solid white;
  color: white;

  &:hover {
    box-shadow: 0 0 0 0.1rem white;
    color: white;
  }
}

.arkefip-button-white {
  border: 1px solid white;
  background-color: white;
  color: var(--arkefip-dark);

  &:hover {
    box-shadow: 0 0 0 0.1rem white;
    color: var(--arkefip-dark);
  }
}

.arkefip-button-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--arkefip-spacer-md);

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.ComponentsButtons--icon {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: $border-radius;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  i {
    align-self: center;
  }

  &-default {
    background-color: $secondary;
    color: $color-base-60;
  }

  &-success {
    background-color: $color-success-bg;
    color: $color-success;
  }

  &-warning {
    background-color: $color-warning-bg;
    color: $color-warning;
  }

  &-danger {
    background-color: $color-danger-bg;
    color: $color-danger;
  }
}

.ComponentsButtons--like {
  border: 1px solid var(--arkefip-def-extralight);
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;

  .ComponentsButtons--icon {
    background: var(--arkefip-def-extralight);
    color: var(--arkefip-def-primary);
    cursor: pointer;
  }

  &-counter {
    margin: 0 map-get($spacers, 1);
    display: flex;
    align-items: center;
    color: var(--arkefip-def-primary);
  }

  &.liked {
    border-color: var(--arkefip-secondary);

    .ComponentsButtons--icon {
      background: var(--arkefip-secondary);
      color: white;
    }
  }
}


button {
  background: $color-green;
  padding: 10px 25px;
  // text-transform: uppercase;
  color: #fff;
  font-family: $input-btn-font-family;
  font-weight: 400;
  border-radius: 2em;
}

.btn {

  >input[type=checkbox] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

// .btn-secondary {
//   color: $primary;
//   &:hover {
//     color: $color-brand;
//   }
// }
.btn-ghost {
  color: $color-base-80;

  &:hover {
    background: rgba($color-base-100, 0.03);
  }
}

.btn-circle {
  width: 60px;
  height: 60px;
  padding: 12px 0px;
  border-radius: 30px !important;
  text-align: center;
  font-size: 24px;
  line-height: 1.42857;
  cursor: pointer;

  &.active {
    background-color: $primary !important;
    color: $white !important;
  }
}

// Used for documents - using a tag instead of button to avoid triggering form submit
a:not([href]):not([tabindex]):hover.btn-outline-primary {
  color: white;
}

.btn-primary {
  color: $white;
}

// TODO: this should be a temporary class to be removed when the anniversary feature is finished
.btn-anniversary {
  background-color: #9683EC,;
  color: $white;
  &:hover {
    color: $white;
    background-color: darken(#9683EC, 10%);
  }
  @include media-breakpoint-down(md) {
    color: $white !important;
    margin-top: 1rem;
    max-width: fit-content;
    padding: 0.25rem 1.5rem;
    .fas {
      border: none;
    }
  }
}