// Contents
//
// .progress-bar,
// .progress .progress-bar

.box_progress {
  p { margin:0; font-size:12px; }
  span { font-size:12px; }
}

.progress {
  background: $color-base-5;
  padding: 4px;
  margin-bottom: 0;

  .progress-bar {
    background: $color-base-25;
    border-radius: 4px;

    &-100 {
      background: $color-success !important;
    }
  }
}

.threshold-indicator {
  position: relative;
  display: inline-block;
  top: -17.5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: $white;
  z-index: 1049;
}
