@import "design_resources/index";
@import "enerfront/index";
@import "investor_app/index";
@import "landing_pages/index";


// Yet to find a place
.page-pages {
  .new_call_me_back {
    input[name="email_confirmation"] {
      position: absolute;
      left: -9999px;
      top: -9999px;
    }
  }
}

.page-pages {
  h1 {
    padding: 20px 0;
  }

  h2,
  h3 {
    padding: 20px 0;

    &.popover-header {
      padding: 1rem;
    }
  }
}
