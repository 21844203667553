.page-projects,
.page-project_previews {
  .tab-content .content.project-owner-presentation p.owner-logo {
    text-align: center;
    margin-bottom: 1em;

    img {
      max-height: 15em;
    }
  }
}
