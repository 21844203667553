a:hover {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
}

main a {
  text-decoration: underline;
}