.product-sidebar {
  .threshold-indicator {
    top: -22px;
  }
}

.product-invest {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);
  background-color: white;
  border-radius: 1rem;
  padding: var(--arkefip-spacer-md);

  &-group {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-xs);

  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--arkefip-text-sm);
    
    &-label {
      display: flex;
      align-items: center;
      gap: var(--arkefip-spacer-xs);

      text-decoration: none;
      color: var(--arkefip-def-dark);

      &.help {
        cursor: help;
        span {
          text-decoration: underline;
          text-decoration-style: dotted;
        }
        i {
          color: var(--arkefip-def-light);
          transition: all ease-in-out 0.2s;
        }

        &:hover {
          i {
            color: var(--arkefip-def-secondary);
          }
        }
      }
    }

    &-note {
      color: var(--arkefip-def-secondary);
    }

    &-data {
      font-weight: 700;
    }
  }

  &-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--arkefip-spacer-sm);
  }
}

.product-legal {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  gap: var(--arkefip-spacer-xs);
  font-size: var(--arkefip-text-sm);
  text-align: left;

  &-more {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    color: var(--arkefip-def-primary);
    text-decoration: none;
  }
}

.product-progress {
  display: flex;
  align-items: center;
  gap: var(--arkefip-spacer-xs);

  &-bar {
    width: 100%;
    display: flex;
    height: 8px;
    border-radius: 10rem;
    background-color: var(--arkefip-def-light);

    &-fill {
      display: flex;
      height: 100%;
      border-radius: 10rem;
      background-color: var(--arkefip-def-primary);
    }
  }
}

.product-note {
  display: flex;
  padding: 4px 8px;
  border-radius: 8px;
  line-height: 1;
  background-color: transparent;
  border: 1px solid var(--arkefip-def-secondary);
  background-color: var(--arkefip-def-background);
  // color: var(--arkefip-def-primary);
  color: var(--arkefip-def-dark);
  text-decoration: none;

  &.note-a {
    // color: var(--arkefip-note-a-dark);
    background: var(--arkefip-note-a-background);
    border: 1px solid var(--arkefip-note-a-primary);
  }
  &.note-b{
    // color: var(--arkefip-note-b-dark);
    background: var(--arkefip-note-b-background);
    border: 1px solid var(--arkefip-note-b-primary);
  }
  &.note-c {
    // color: var(--arkefip-note-c-dark);
    background: var(--arkefip-note-c-background);
    border: 1px solid var(--arkefip-note-c-primary);
  }
  &.note-d {
    // color: var(--arkefip-note-d-dark);
    background-color: var(--arkefip-note-d-background);
    border: 1px solid var(--arkefip-note-d-primary);
  }
  &.note-e {
    // color: var(--arkefip-note-e-dark);
    background-color: var(--arkefip-note-e-background);
    border: 1px solid var(--arkefip-note-e-primary);
  }
  &.note-f {
    // color: var(--arkefip-note-f-dark);
    background-color: var(--arkefip-note-f-background);
    border: 1px solid var(--arkefip-note-f-primary);
  }
  &.note-g {
    // color: var(--arkefip-note-g-dark);
    background-color: var(--arkefip-note-g-background);
    border: 1px solid var(--arkefip-note-g-primary);
  }
}
