.product-discussion {
  --section-color-dark: var(--arkefip-def-dark);
  --section-color-primary: var(--arkefip-def-primary);
  --section-color-background: var(--arkefip-def-background);
}

#project-comments-wrapper {
  .card-header, .card-footer {
    background: $secondary;
  }
}