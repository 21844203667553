.arkefip-project-page-content {
  background-color: var(--arkefip-def-background);
  padding-top: 1rem;

  #the-project {
    font-size: 1rem;

    h2 {
      @extend .arkefip-bg-leaves;
      @extend .arkefip-bg-leaves-primary;

      display: flex;
      width: 100%;
      padding: 2rem;
      text-align: center;
      justify-content: center;
      border: 1px solid $border-color;
      border-radius: 1rem;
      margin: 2rem 0;

      & + h3 {
        margin-top: 0;
      }
    }

    h3 {
      display: flex;
      flex-direction: column;
      margin: 3rem 0 2rem 0;
      font-weight: 400;
      line-height: 1;
      font-size: 1.5rem;

      &:has(+ h4) {
        margin-bottom: 0;

        &::after {
          content: " ";
          height: 1px;
          width: 100%;
          margin: 0.5rem 0;
          background: var(--arkefip-def-light);
        }
      }

      & + h4 {
        font-family: var(--arkefip-font-body);
        margin-bottom: 2rem;
      }
    }
  }
}