.simulator-container {
  .results {
    display: none;
  }

  input {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
}
