.info-banner {
  .info-banner-container {
    justify-content: space-between;
    gap: 0.75rem;
  }

  .info-banner-close {
    cursor: pointer;
  }
}

#info_banner {
  background-color: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: $box-shadow-sm;
  position: fixed;
  bottom: 5rem;
  max-width: 450px;
  margin: 0 1rem;
  z-index: 100;

  // cf .arkefip-app-mobile-navbar-container
  @media (min-width:960px) {
    bottom: var(--arkefip-layout-padding);
    right: var(--arkefip-layout-padding);
    margin: 0;
  }

  .info-banner-container {
    display: flex;
    align-items: flex-start;
  }
}

#app_info_banner {
  width: 100%;
  padding: 12px var(--arkefip-layout-padding);
  background-color: var(--arkefip-dark);

  .info-banner-container {
    width: 100%;
    display: grid;
    grid-template-columns: 95% auto;
    color: white;

    a.info-banner-link {
      color: inherit;
      text-align: left;

      @media (min-width:960px) {
        text-align: center;
      }
    }

    .info-banner-text {
      display: flex;
      align-items: center;

      @media (min-width:960px) {
        display: inline-block;
        margin-right: 1.125rem;
      }
    }

    strong {
      color: #76CC98;
    }

    strong.info-banner-cta {
      display: block;
      margin-left: 23px;

      @media (min-width:960px) {
        display: inline;
        margin-left: 0
      }
    }

    .info-banner-close {
      display: flex;
      align-items: center;
    }
  }
}
