.arkefip-storypage-instant {
  width: 100%;
  min-width: 240px;
  max-width: 320px;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  box-shadow: var(--arkefip-shadow-lg);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;

  img {
    border-radius: 0.5rem;
    width: 100%;
  }

  &-caption {
    font-family: var(--arkefip-font-handwriting);
    font-size: 1.5rem;
    line-height: 110%;
    color: var(--arkefip-ocean-primary);
  }

  &-pin {
    &::after {
      display: flex;
      width: 4rem;
      height: 4rem;
      content: "";
      background-image: url('../../assets/images/pages/storypage/pin-left.webp');
      background-size: cover;
      position: absolute;
      top: -2rem;
      left: 4rem;
    }
  }
}
