.close-button-container {
  margin-top: -1rem;
  margin-bottom: 1rem;
  .home-button {
    display: block;
    float: left;
    width: auto;
  }
  .close-button {
    display: block;
    float: right;
  }
}

#arkefip-app-with-sidebar {
  display: flex;
  flex-direction: column;
  background: var(--arkefip-def-background);

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.application_with_sidebar {
  min-height: 95vh;
  padding-bottom: 100px;


  .step-title {
    color: $headings-color;
    font-weight: bold;
    font-size: 18px;
    &.text-large {
      font-size: 22px;
    }
  }

  .step-subtitle {
    color: $color-base-60;
    font-weight: lighter;
    font-style: italic;
    font-size: 14px;
    margin-bottom: 0;
  }

  .success-buttons {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  table thead {
    font-size: 14px;
  }
}

.end-items-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;

  .end-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      font-weight: bold;
      margin: 10px 0 0 0;
    }

    .hint {
      margin: 0;
      font-size: 10px;
      font-weight: lighter;
      font-style: italic;
    }
  }

  .end-item-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--arkefip-def-primary);
    color: var(--arkefip-def-primary);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .end-item-image {
    width: 50px;
    height: 50px;
  }


  .inactive {
    opacity: 0.5;

    .end-item-icon {
      copacity: 0.5;
    }
  }

}

@media only screen and (max-width:  map-get($grid-breakpoints,"md")) {
  .end-items-wrapper {
    flex-direction: column;
    .end-item {
      width: 100%;
    }
  }
  .mobile-flex-column-reverse {
    flex-direction: column-reverse;
  }
  .mobile-flex-column {
    flex-direction: column;
  }
  .xs-w-100 {
    width: 100% !important;
  }
  .step-mobile-btn {
    width: 100%;
    white-space: pre-wrap;
    margin: 10px 0;
  }
}
