.highlight {
  padding: 0.3rem 0.8rem;
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: 700;
  @extend .rounded-3 ;
  @extend .d-inline-block ;
  @include transition($btn-transition);
}

@each $color, $value in $theme-colors {
  .highlight-#{$color} {
    @include button-variant($value, $value);
    box-shadow: 0 0 0;
  }
}
