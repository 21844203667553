.product-grid-2 {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-md);
  
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
  }
}