.page-projects-category {
  .projects-category-header {
    &.has-content {
      @extend .py-md-6;
      @extend .pt-6;
      @extend .pb-2;
    }
    &.has-no-content {
      @extend .pt-6;
    }
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }

    gap: var(--arkefip-spacer-md);
    align-items: center;

    .picture {
      img {
        @extend .img-fluid;
        @extend .rounded-3;
      }
    }

    h1 {
      color: var(--arkefip-primary);
      margin-bottom: 1em;
      margin-top: .2rem;
    }
    .baseline {
      text-transform: uppercase;
      margin-top: .8rem;
      margin-bottom: 0;
    }
    .content *:last-child {
      margin-bottom: 0;
    }
  }

  .projects-category-main, .projects-category-bottom {
    @extend .arkefip-bg-leaves;
    @extend .arkefip-bg-leaves-primary;
    @extend .p-4;
    a { color: white; text-decoration: underline; }
    .container {
      display: grid;
      flex-direction: column;
      grid-template-columns: 3fr 1fr;
      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
      }

      gap: var(--arkefip-spacer-md);
      align-items: center;
      .picture {
        img {
          height: 12rem;
        }
        text-align: right;
      }
    }
    .did-you-know {
      background: rgba(255, 255, 255, 0.2);
      padding: var(--arkefip-spacer-sm);
      h3 i { margin-right: .5em }
    }
  }
  .projects-category-faq {
    @extend .p-md-6;
    @extend .p-4;
    .container {
      display: grid;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;
      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
      }
      gap: var(--arkefip-spacer-md);
      .faq {
        h3 {
          font-size: 130%;
          font-family: var(--arkefip-font-body);
          font-weight: 400;
        }
        .faq-item {
          &:not(:last-child) {
            border-bottom: 1px solid var(--arkefip-def-light);
          }
          @extend .mb-4;
        }
      }
      .info {
        background: url('../../assets/images/illustrations/people-around-desk.webp');
        @include media-breakpoint-down(sm) {
          background-image: none;
        }
        background-size: 90%;
        background-position: bottom center;
        background-repeat: no-repeat;

        .btn {
          padding-left: .5rem;
          .icon {
            @extend .rounded-3;
            padding: 2px 2px 2px 8px;
            margin-left: .5rem;
            margin-right: -.8rem;
            background: var(--arkefip-interaction);
            color: white;
            .fa {
              margin-right: .5em;
            }
          }
          &:hover .icon {
            color: var(--arkefip-interaction);
            background: white;
          }
        }
      }
    }
  }
  .projects-category-advantages {
    @extend .pt-6;
    .cards {
      display: grid;
      flex-direction: column;
      grid-template-columns: 1fr 1fr 1fr;
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }
      gap: var(--arkefip-spacer-md);
      .item {
        h3 {
          font-size: 130%;
          @extend .mt-2;
          @extend .mb-3;
        }
        img {
          @extend .rounded-3;
          height: 220px;
          object-fit: cover;
          width: 100%
        }
      }
    }
  }

  .projects-category-faq,.projects-category-main {
    ul {
      list-style-type: disc;
      li {
        margin-left: 1.5em;
        margin-bottom: .5em;
      }
    }
  }
}
