.section-footprint {
  // layout
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  // style 
  background: $primary;
  color: white;
  text-align: center;
  padding: 6rem 1.5rem;
}
