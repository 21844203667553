.arkefip-registration {
  &-wrapper {
    background-color: var(--arkefip-def-background);
    display: flex;
    flex-direction: column-reverse;
    
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .notie-container {
      // this aims to replace the bottom-right notie to top-left to prevent CTA overriding
      bottom: auto !important;
      top: 0 !important;
      right: auto !important;
      left: 0 !important;
    }
  }

  &-fancy {
    padding: var(--arkefip-layout-padding);
    height: 30vh;
    width: 100%; 
    display: flex; 
    flex-direction: column; 
    gap: var(--arkefip-spacer-md);
    
    @include media-breakpoint-up(lg) {
      height: 100vh;
      position: sticky; 
      top: 0; 
    }
  }

  &-brand {
      align-self: center;
      position: relative;
      margin-bottom: 4rem;
  }

  &-image {
    display: none;
    background: var(--arkefip-sky);
    background-size: contain;
    background-position: right center; background-repeat: no-repeat;
    border-radius: 2rem; flex-grow: 1;
    height: 100%;
    width: 100%;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
  
  &-main {
    display: flex;
    flex-direction: column;
    padding: 0 var(--arkefip-layout-padding) var(--arkefip-layout-padding) var(--arkefip-layout-padding);
    align-items: center;
    font-size: 1rem;
  }
  
  &-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    gap: var(--arkefip-spacer-lg);

    .alert {
      font-size: var(--arkefip-text-sm);
    }

    form {
      display: flex;
      flex-direction: column;
      gap: var(--arkefip-spacer-lg);
    }

    h1, h2 {
      margin-bottom: 0;
    }
  }

  &-question {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-xs);
    font-size: var(--arkefip-text-md);
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 400px;
  }

  &-switchbox {
    padding: var(--arkefip-spacer-sm);
    border: 1px solid var(--arkefip-def-extralight);
    border-radius: 1rem;
    font-size: var(--arkefip-text-sm);
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-xs);

    &-item {
      font-size: var(--arkefip-text-sm);
      position: relative;
      padding-left: 1.5rem;
      color: var(--arkefip-def-primary);

      &::before {
        content: "\2713";
        position: absolute;
        top: 0;
        left: 0.25rem;
        color: var(--arkefip-secondary);
      }
    }
  }

  &-hint {
    font-size: var(--arkefip-text-xs);
    opacity: 0.5;
  }

  &-macaron {
    filter: grayscale(0);
    transition: all ease-in-out 0.2s;
    cursor: help;

    &:hover {
      filter: grayscale(1);
    }

    &-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--arkefip-spacer-sm);
    }
  }
}

.psfp-question-label {
  font-weight: 500;
}

.page-users-registrations {
  .arkefip-registration-wrapper {
    flex-direction: column;
  }
  .arkefip-registration-image {
    display: flex;
  }
  .arkefip-registration-brand {
    position: absolute;
    top: 0;

    @include media-breakpoint-up(lg) {
      position: relative;
    }
  }
}
.password-hint {
  font-size: 76%;
  color: gray;
  li { margin-left: 1rem; }
  .fa { margin-right: .5rem; }
}
