.page-agents {
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    .section-icon {
      font-size: 2rem;
    }
  }
  .button-row {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
}
