.product-cover {
  // dimensions
  width: 100%;

  .product-cover-image {
    width: 100%;
    min-height: 25vh;
    
    // background
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  //layout
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  // style
  border-radius: 1rem;
  overflow: hidden;

  .product-header {
    width: 100%;
    padding: var(--arkefip-spacer-sm) var(--arkefip-layout-padding);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: var(--arkefip-spacer-sm);
      flex: 1;

      @include media-breakpoint-up(md) {
        align-items: flex-start;
        text-align: left;
      }
    }

    &-right {
      display: flex;
      justify-content: center;
      
      img.innovation-logo {
        width: 32px;

        @include media-breakpoint-up(md) {
          width: 72px;
        }
      }
    }


    .owner-card {
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      .owner-infos {
        align-items: center;

        @include media-breakpoint-up(md) {
          align-items: flex-start;
        }
      }
      // color: white;
    }

    .product-heading {
      h1 {
        font-size: var(--arkefip-text-lg);
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          font-size: var(--arkefip-text-xl);
        }
        // color: white;

        small { font-size: 60%; margin-left: .5em; }
      }
    }
  }
}
