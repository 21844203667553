.language-dropdown {
  align-self: flex-start;
  cursor: pointer;

  label {
    font-size: var(--arkefip-text-xs);
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
  }

  .dropdown-menu {
    border: none;
    box-shadow: var(--arkefip-shadow-sm);
    padding: var(--arkefip-spacer-xs);
  }
  &-item {
    color: var(--arkefip-def-dark);
    text-decoration: none;
    transition: all ease-in-out 0.2s;
    padding: 4px 8px;
    border-radius: 0.5rem;

    &:hover {
      background-color: var(--arkefip-def-background);
    }

    &.active {
      background-color: var(--arkefip-def-extralight);
    }
  }
  &-flag {
    width: 20px !important;
    border-radius: 100%;
  }
}
