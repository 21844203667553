.text-gradient {
  &-cyan {
    background: linear-gradient(90deg, #00b5ec 0%, $primary 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline;
  }

  &-green {
    background: linear-gradient(90deg, #a8c713 0%, #85a007 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline;
  }

  &-yellow {
    background: linear-gradient(90deg, #ffd420 0%, #ffb507 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline;
  }

  &-orange {
    background: linear-gradient(90deg, #f29301 0%, #f26901 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline;
  }

  &-steel {
    background: linear-gradient(90deg, #8399cf 0%, #5b73ad 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline;
  }

  &-primary {
    background: linear-gradient(90deg, $primary 0%, $primary 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: inline;
  }
}
