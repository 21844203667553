.arkefip-storypage-figures {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;

  &-group {
    display: flex;
    flex-direction: column;

    @media (min-width: 720px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:nth-child(odd) {
        padding-top: 3rem;
      }

      &-figure {
        font-family: var(--arkefip-font-handwriting);
        font-size: 4rem;
        transform: rotate(-4deg);
      }

      &-title {
        font-family: var(--arkefip-font-headings);
        font-weight: 700;
        font-size: 2rem;
        line-height: 110%;
        text-align: center;
      }
    }
  }

  h2 {
    &::after {
      display: flex;
      content: "";
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
      height: 2rem;
      border-right: 2px dotted var(--arkefip-def-light);
    }
  }
}
