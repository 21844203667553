.arkefip-mobile-menu-main {
  position: fixed;
  padding: var(--arkefip-layout-padding);
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .btn-dark {
    border: 2px solid #fff;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }

  &-trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: var(--arkefip-shadow-lg);
    border-radius: 0.5rem;
    padding: 1rem 0.5rem;
  }
}

.page-projects-show {
  .arkefip-mobile-menu-main {
    justify-content: flex-start;
  }
}

.arkefip-mobile-menu-project {
  display: block;
  position: fixed;
  padding: var(--arkefip-layout-padding);
  bottom: 0;
  right: 0;
  z-index: 1001;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.arkefip-mobile-menu-project,
.arkefip-mobile-menu-main {
  .btn {
    box-shadow: var(--arkefip-shadow-lg);

    @include media-breakpoint-up(md) {
      box-shadow: none;
    }
  }
}

#arkefipFrontSidesheetMenu {
  .arkefip-mobile-sidesheet-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .arkefip-mobile-sidesheet-logo {
    display: flex;
    justify-content: center;
    width: 100%;

    svg {
      width: 4rem;
      fill: var(--arkefip-primary);
    }
  }

  .btn-close {
    width: 100%;
    padding: 1.5rem 0;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
