/*
Used for projects landing pages like webinar form pages
*/

.landing-pages-project {
  .position-sticky {
    z-index: $zindex-sticky;
    top: $header-offset;
    @include media-breakpoint-down(lg) {
        top: $header-offset-small !important;
    }
  }
  .landing-section-project {
    p {
      // font-size: 0.9rem;
    }
  }
  .subtitle {
    font-size: 1rem;
    font-style: italic;
  }
}
