// Contents
//
// .item_project

.item_project {
  // max-width: 350px;
  // height: 460px;

  margin-bottom: 30px;
  border-radius: 2px;
  overflow: hidden;
  background: white;
  @extend .transition !optional;

  &:hover {
    @include media-breakpoint-up(md) {
      margin-top: -5px;
    }
  }

  // &.shadow {
  //   box-shadow: 0.2rem 0.2rem 0.5rem rgba($black, 0.45) !important;
  // }

  &--header {
    display: block;
    text-decoration: none !important;
    color: $white !important;
    position: relative;
    z-index: 0; // required to set &-background z-index

    &-background {
      background: linear-gradient(rgba($black,0.5), rgba($black,0.5)), url("../../assets/images/pictures/placeholder.avif");
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      clip-path: ellipse(150% 100% at 50% 0);
      -webkit-clip-path: ellipse(150% 100% at 50% 0);
    }
  }

  .logo-circle {
    background-color: $white; // rgba($white, 0.9);
    border: 4px solid rgba($color-brand-border, 0.5);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    overflow: hidden;

    width: 90px;
    height: 90px;
    border-radius: 45px !important;

    @include media-breakpoint-down(sm) {
      width: 90px;
      height: 90px;
      border-radius: 45px !important;
    }

    img {
      width: 100%;
    }
  }

  h3 {
    font-size: 1rem;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.2rem
  }

  &--sites {
    margin-bottom: 1rem;
    padding: 0 10px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    // height: 2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 2.25rem;
    font-weight: lighter;

    // span {
    //   white-space: pre;
    // }
  }

  &--state {
    font-weight: bold;
    text-transform: uppercase;

    > span {
      position: relative;
      top: 5px;
      // @include border-radius(0.25em); // default $border-radius when .rounded

      background-color: $white;
      color: $color-brand-dark;
      border: 1px solid $border-color;

      &.active {
        background-color: $white;
        color: $color-brand-dark;
        border: 1px solid $border-color;
      }
    }
  }

  &--infos {
    .fas {
      display: inline-block;
      width: 8px;
      color: $primary;
    }

    div {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &.col-6 {
        white-space: normal;
        height: 55px; // allow 2 lines
      }
    }

    a.view-rate {
      text-decoration: underline;
    }

    &.font-weight-normal {
      strong, .small {
        font-size: inherit;
        font-weight: inherit;
      }
    }
  }

  &--card-info {
    background-color: $color-brand-bg;
    color: $color-brand-dark;

    strong, span {
      font-size: larger;
      font-weight: bold;
    }

    .vertical-info {
      font-family: $headings-font-family;

      strong {
        display: block;
        font-size: x-large;
        font-weight: lighter;
      }
      span {
        text-transform: uppercase;
      }
    }
  }
}