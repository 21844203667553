.page-pages-tutorial {
  .arkefip-container {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-2x);
  }
  .arkefip-page-header-cover {
    &-body {
      background: var(--arkefip-def-extralight);
      padding: calc(var(--arkefip-layout-padding)*2);
    }
  }
}