.arkefip-project-page-tab-navigation {
  li {
    a {
      padding: 0.5rem 1rem;
      border-radius: 10rem;
      color: var(--arkefip-def-dark);
      font-size: 14px;

      &:hover {
        background-color: var(--arkefip-def-background);
        color: var(--arkefip-def-dark);
      }

      &.active {
        background-color: var(--arkefip-def-background);
        font-weight: bold;
        color: var(--arkefip-def-dark);
      }
    }

    .badge {
      background-color: var(--arkefip-def-secondary);
    }
  }
}

.arkefip-project-page-fixed-tab-navigation {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 1000;

  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &-body {
    background-color: white;
    box-shadow: var(--arkefip-shadow-lg);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    .form-control {
      padding: 0.25rem 0.5rem;
      height: 36px;
      min-width: 240px;
    }

  }

}
