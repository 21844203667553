.Corporate-Components--service-card {
  background: $white;
  border: 1px solid $border-color;
  border-radius: map-get($spacers, 2);

  &-investors {
    border-color: #ffc30366;

    .Corporate-Components--service-card-title {
      color: #ff991f;
    }
  }

  &-projects {
    border-color: #ff553066;

    .Corporate-Components--service-card-title {
      color: #c02600;
    }
  }

  &-app {
    border-color: #4d9aff66;

    .Corporate-Components--service-card-title {
      color: #0748a6;
    }
  }

  &-support {
    border-color: #35b37e66;

    .Corporate-Components--service-card-title {
      color: #006644;
    }
  }

  &-communication {
    border-color: #00b8d966;

    .Corporate-Components--service-card-title {
      color: #008da6;
    }
  }

  &-marketing {
    border-color: #6555c066; //#d0c6f1;

    .Corporate-Components--service-card-title {
      color: #403294; // #6f42c1;
    }
  }

  .Corporate-Components--service-card-header {
    display: flex;
    align-items: center;
    padding: map-get($spacers, 2);

    .Corporate-Components--service-card-title {
      margin-bottom: 0;
      font-family: $headings-font-family;
      font-size: 1.125rem;
    }

    .Corporate-Components--service-card-icon {
      border-radius: 10rem;
      margin-right: map-get($spacers, 2);
      box-shadow: $box-shadow-sm;
      width: 2.25rem;
      height: 2.25rem;
      padding: 0.375rem;
    }
  }

  .Corporate-Components--service-card-body {
    padding: map-get($spacers, 2);

    p {
      margin-bottom: 0;
      font-size: 1rem;
      color: $color-base-60;
    }
  }
}
