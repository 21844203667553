.comparaison {
  border-bottom-right-radius:5px;
  border-bottom-left-radius:5px;
  color:$white;
  font-weight:lighter;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;


  .compare-item {
    background:$color-base-80;
    border-bottom-left-radius:0px;
    border-bottom-right-radius:5px;
    padding:20px 10px;
    min-height:180px;

    &:first-child {
      padding:10px;
      background:$color-base-100;
      border-bottom-left-radius:5px;
      border-bottom-right-radius:0;
    }
  }
}

.bloc-comparaison {
  border-radius: 5px;
}

.resultats {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: $color-base-80;
  font-size: 24px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;

  span {
    // color:$white;
    font-size: 14px;
  }
}
