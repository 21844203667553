.alert {
  &-primary {
    background: var(--arkefip-primary);
    border-color: var(--arkefip-primary);
    color: white;
    .fas, .fa {
      color: white;
    }
    a, a:hover, a:visited, a:active {
      color: white;
    }
  }
  &-info {
    background: $color-info-bg;
    border-color: $color-info-light;
    color: $color-info-text;
    a, a:hover, a:visited, a:active {
      border-bottom: 1px dashed $color-info-text;
    }
  }
  &-light {
    border-color: $light;
    .fas, .fa {
    }
  }
  &-icon {
    @extend .d-flex ;
    @extend .align-items-center ;
    .fas, .fa {
      @extend .me-3 ;
      font-size: 110%;
    }
  }
  &-plum {
    background-color: var(--arkefip-plum-background);
    color: var(--arkefip-plum-dark);
  }
}
