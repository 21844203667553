/*
PRODUCT PAGE MAIN STYLE
Dedicated to .main element and global style
*/


.product-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--arkefip-layout-padding);
  padding: var(--arkefip-layout-padding);

  background-color: var(--arkefip-def-background);
  font-size: var(--arkefip-text-md);

  @include media-breakpoint-up(md) {
    // background: url('../../assets/images/patterns/arkefip-wood-background-extralight.svg'), var(--arkefip-def-background);
    background: url('../../assets/images/arkefip_pp/topographic-pattern.svg'), var(--arkefip-def-background);
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: luminosity;
    background-position: top;
    background-attachment: fixed;
  }
}

.product-content {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  gap: var(--arkefip-spacer-md);
  align-items: flex-start;
  
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    align-items: stretch;
  }
  @include media-breakpoint-up(xxl) {
    max-width: 1300px;
  }

  .product-main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--arkefip-layout-padding);

    article {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: var(--arkefip-layout-padding);
    }

    #article-scrollspy {
      position: relative;
    }
    
    section {
      background-color: white;
      border-radius: 1rem;
      padding: var(--arkefip-layout-padding);
      display: flex;
      flex-direction: column;
      max-width: 95vw;
      gap: var(--arkefip-spacer-sm);
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-sm);
    width: 100%;
    
    @include media-breakpoint-up(md) {
      min-width: 340px;
      max-width: 380px;
      #sticky-cta {
        position: sticky;
        top: 4.5rem;
        z-index: 100
      }
    }
  }

  img {
    max-width: 100%;
    border-radius: 0.5rem;
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: 0.5rem;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}