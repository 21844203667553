ul.share-oauth {
  list-style: none;

  li {
    width:30px;
    height:30px;
    display:inline-block;
    margin: 0 16px 0 0;

    a {
      // padding:0;
      width:inherit;
      height:inherit;
      display:inline-block;

      img {
        // position: static;
        width: inherit;
        height: inherit;
        @extend .rounded ;
      }
    }
  }
}
