
$notie-font-size-mobile: 0.8rem !default;
$notie-font-size-tablet: 0.8rem; // 1.2rem !default;
$notie-font-size-desktop: 0.8rem; // 1.4rem !default;
$notie-font-size-widescreen: 0.8rem; // 1.6rem !default;

.notie-container {
  z-index: 5000;
  position: fixed;
  display: flex;
  width: 500px;
  background-color: transparent;
  box-shadow: none;

  bottom: 0 !important;
  top: auto !important;
  left: 0 !important;
  right: auto !important;

  padding: 0 !important;

  @include media-breakpoint-up(md) {
    padding: var(--arkefip-layout-padding) !important;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.notie-textbox-inner {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);
  width: 100%;
  position: relative;
  background: white;
  box-shadow: var(--arkefip-shadow-lg);
  color: var(--arkefip-def-dark);
  text-align: left;
  border-style: solid;
  border-color: var(--arkefip-honey-primary);

  border-radius: 1rem 1rem 0 0;
  border-width: 2px 0 0 0;
  padding: var(--arkefip-spacer-lg) var(--arkefip-spacer-md) var(--arkefip-spacer-md) var(--arkefip-spacer-md);

  @include media-breakpoint-up(md) {
    padding: var(--arkefip-spacer-sm);
    border-radius: 0.5rem;
    border-width: 0;
  }

  &::before {
    content: "✕";
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    background-color: var(--arkefip-honey-primary);
    border: 4px solid white;
    color: white;
    border-radius: 10rem;
    position: absolute;
    top: -1rem;
    left: 47%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md) {
      left: -1rem;
    }
  }

  &::after {
    content: " ";
    display: flex;
    width: 100%;
    animation: 9s ease-in 1s both slidein;
    transform-origin: left center;
    background-color: var(--arkefip-honey-primary);
    height: 4px;
    border-radius: 100rem;
  }
}

@keyframes slidein {
  from { transform: scaleX(1); }
  to   { transform: scaleX(0); }
}

.notie-background-success {
  .notie-textbox-inner {
    border-color: var(--arkefip-secondary);
    &::before {
      content: "✓";
      background-color: var(--arkefip-secondary);
    }
    &::after {
      background-color: var(--arkefip-secondary);
    }
  }
}

.notie-background-info {
  .notie-textbox-inner {
    border-color: var(--arkefip-def-primary);
    &::before, &::after {
      background-color: var(--arkefip-def-primary);
    }
  }
}

.notie-textbox {
  padding: 15px;
}

.notie-textbox-inner::before {
  animation: 6s ease-in 1s both timer;
}
