// Contents
//
// body.page-stats

.page-stats {
  background-color: var(--arkefip-def-background);

  .arkefip-page-header-cover {
    &-body {
      background-color: var(--arkefip-sky);
    }

    &-content {
      padding: var(--arkefip-layout-padding);
      order: 2;
      @include media-breakpoint-up(md) {
        order: 0;
        padding-top: var(--arkefip-spacer-xl);
      }
      p {
        margin-bottom: 0;
      }
    }

    &-image {
      background-image: url('../../assets/images/illustrations/portfolio.webp');

      @include media-breakpoint-up(md) {
        background-image: url('../../assets/images/illustrations/portfolio-cut.webp');
        background-position: right;
      }
    }
  }

  main .container-lg {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--arkefip-spacer-md);
  }

  table {
    overflow: hidden;
    border-radius: 1rem;

    tr {
      td, th {
        font-size: inherit;
        padding: 3px 10px;
      }

      td:first-child, th:first-child {
        background-color: var(--arkefip-def-background);
      }

      :nth-child(n+2) {
        text-align: right;
        white-space: nowrap;
      }
    }
  }
}

.page-stats-section {
  background-color: white;
  border-radius: 2rem;
  width: 100%;

  &-header, &-body, &-footer {
    padding: var(--arkefip-layout-padding);

    h2, h3 {
      margin: 0;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}

.page-stats-report {
  &-list {
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-sm);

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &-item {
    border-radius: 1rem;
    background-color: var(--arkefip-def-background);
    display: flex;
    flex-direction: column;
    gap: var(--arkefip-spacer-sm);
    padding: var(--arkefip-spacer-sm);
    align-items: flex-start;

    h3 {
      font-size: 2rem;
    }

    span {
      color: var(--arkefip-def-secondary);
      font-family: var(--arkefip-font-headings);
      font-weight: 100;
    }
  }
}

.page-stats-polling-container {
  display: contents;
}