// https://getbootstrap.com/docs/4.3/getting-started/theming/

// Theme colors
$theme-colors: (
 "notice": $color-warning-light,
);

$enable-dark-mode: false;

// Variables for theming
$primary:       $color-brand;
$secondary:     $color-base-5;
$success:       $color-success;
$info:          $color-info-light;
$warning:       $color-warning;
$danger:        $color-danger;
$light:         $color-brand-light;
$dark:          $color-base-100;
// Specific brand colors for interaction components
$background:    $color-base-5;

// Energy theming
$energy-innovation: #F4950D;
$energy-innovation-bg: #FCF8F3;
$energy-eolien: #849ACF;
$energy-eolien-bg: #F3F5FC;
$energy-bioenergie: #A8C813;
$energy-bioenergie-bg: #FAFCF3;
$energy-solaire: #FFD41F;
$energy-solaire-bg: #FCFAF3;
$energy-hydroelectrique: #00B5EC;
$energy-hydroelectrique-bg: #F3FAFC;
$energy-economie_energie: $color-brand;
$energy-economie_energie-bg: $color-brand-bg;
$energy-reseau_chaleur: $color-brand;
$energy-reseau_chaleur-bg: $color-brand-bg;
$energy-reseau_froid: #39DEDE;
$energy-reseau_froid-bg: #F3FAFC;
$energy-mix_energetique: $color-brand;
$energy-mix_energetique-bg: $color-brand-bg;
$energy-stockage: #5F4BB6;
$energy-stockage-bg: #FCF8F3;
$energy-hydrogen: #2f0fbc;
$energy-hydrogen-bg: #f3f6fc;

$energyColorsList: (
  "energy-innovation": $energy-innovation,
  "energy-innovation-bg": $energy-innovation-bg,
  "energy-eolien": $energy-eolien,
  "energy-eolien-bg": $energy-eolien-bg,
  "energy-bioenergie": $energy-bioenergie,
  "energy-bioenergie-bg": $energy-bioenergie-bg,
  "energy-solaire": $energy-solaire,
  "energy-solaire-bg": $energy-solaire-bg,
  "energy-hydroelectrique": $energy-hydroelectrique,
  "energy-hydroelectrique-bg": $energy-hydroelectrique-bg,
  "energy-economie_energie": $energy-economie_energie,
  "energy-economie_energie-bg": $energy-economie_energie-bg,
  "energy-reseau_chaleur": $energy-reseau_chaleur,
  "energy-reseau_chaleur-bg": $energy-reseau_chaleur-bg,
  "energy-reseau_froid": $energy-reseau_froid,
  "energy-reseau_froid-bg": $energy-reseau_froid-bg,
  "energy-mix_energetique": $energy-mix_energetique,
  "energy-mix_energetique-bg": $energy-mix_energetique-bg,
  "energy-stockage": $energy-stockage,
  "energy-stockage-bg": $energy-stockage-bg,
  "energy-hydrogen": $energy-hydrogen,
  "energy-hydrogen-bg": $energy-hydrogen-bg
);

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "background": $background
);
$theme-colors-rgb: $theme-colors;
$theme-colors-text: $theme-colors;
$theme-colors-text: $theme-colors;
$theme-colors-bg-subtle: $theme-colors;
$theme-colors-border-subtle: $theme-colors;
$utilities-border-colors: $theme-colors;
$utilities-border-subtle: $theme-colors;
$utilities-bg-colors: $theme-colors;
$utilities-bg-subtle: $theme-colors;
$utilities-links-underline: $theme-colors;

// Setting for the Grid
// $grid-gutter-width: ;

// Setting for the body element
$body-color: $color-base-100;
$link-color: $primary;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $color-base-100;
$yiq-text-light:            $color-base-5;
// make green button text light
$yiq-contrasted-threshold:    170;

// https://getbootstrap.com/docs/4.3/content/typography/#responsive-font-sizes
$enable-responsive-font-sizes: true;
$font-size-base:              0.9rem;

// headings
$headings-font-weight: 300;
$headings-font-family:        'new-spirit', serif;
$font-family-sans-serif:      'museo-sans', -apple-system,system-ui,BlinkMacSystemFont,Arial,sans-serif;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            500;
$font-weight-bolder:          700;
$lead-font-weight:            400;

// btn
$border-color:                $color-base-10;
$border-radius:               0.25rem;
$border-radius-lg:            0.8rem;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);


// Buttons + Forms
$input-btn-padding-y:         0.5rem; // 10px
$input-btn-padding-x:         1.25rem; // 25px
$input-btn-font-size:         0.9rem; // $font-size-base !default;
$btn-font-weight:             500;
$btn-white-space:             nowrap;
$btn-border-radius:           10rem;

// nav
$navbar-light-color:                rgba($color-base-100, 0.9);
$navbar-light-hover-color:          rgba($color-base-100, 1.0);
$navbar-light-active-color:         rgba($color-base-100, 0.9);
$navbar-light-toggler-border-color: rgba($color-base-100, 0.5);

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: 0.563rem,
  2: 0.75rem,
  3: 1.125rem,
  4: 1.5rem,
  5: 2.25rem,
  6: 4.5rem,
  7: 9rem,
);
$enable-negative-margins: false;
$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  // keep xs-xl default
  xxl: 1440px,
  xxxl: 1600px,
);

// Grid containers
// Note: max divisible by 12 and 10 lower than grid breakpoint - 30px
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  // keep sm-xl default
  // xxl: 1380px,
  // xxxl: 1560px,
);

// Input and Input group config
$input-group-addon-color: $color-base-80;
$input-group-addon-bg: $color-base-5;
$input-group-addon-border-color: $border-color;
$input-color: $color-base-80;
$input-bg: $white;
$input-border-color: $border-color;
$input-placeholder-color: $color-base-50;

// Cards config
$card-bg: $white;
$card-border-color: $border-color;
$card-cap-bg: $white;
$card-border-radius: $border-radius-lg;

// icons color
$icon-color: $color-brand-border;

// List group
$list-group-action-color: $color-brand;
$list-group-action-hover-color: $color-base-100;
$list-group-hover-bg: $color-base-25;
$list-group-action-active-color: $color-base-100;
$list-group-action-active-bg: $white;
$list-group-bg: transparent;
$list-group-border-color: $border-color;
$list-group-active-color: $body-color;
$list-group-active-bg: $white;
$list-group-active-border-color: $border-color;

// Palette
$palette-background-colors: (
 "danger": $color-danger-bg,
 "success": $color-success-bg,
 "warning": $color-warning-bg,
);

$palette-text-colors: (
 "danger": $color-danger-text,
 "success": $color-success-text,
 "warning": $color-warning-text,
 "white": white,
 "black": black,
 "primary": $primary,
 "secondary": $secondary,
 "dark": $dark,
 "light": $light,
);

$palette-main-colors: (
 "danger": $color-danger,
 "success": $color-success,
 "warning": $color-warning,
);

$palette-light-colors: (
 "danger": $color-danger-light,
 "success": $color-success-light,
 "warning": $color-warning-light,
);

$utilities-text-colors: $palette-text-colors;
$utilities-text-emphasis-colors: $palette-text-colors;

// Carousel

$carousel-control-color:             $color-base-80;

$carousel-indicator-width:           30px;
$carousel-indicator-height:          6px;
$carousel-indicator-spacer:          3px;
$carousel-indicator-active-bg:       $color-base-80;
$carousel-control-opacity:           .25;


// Modal
$modal-content-border-color: $border-color;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $border-color;
$modal-backdrop-bg: $background;
$modal-backdrop-opacity: 0.8;

// shadows
$box-shadow-sm:               0 .125rem .125rem rgba($dark, .1);
$box-shadow:                  0 .5rem 0.75rem rgba($dark, .1);
$box-shadow-lg:               0 0 0.75rem rgba($dark, .1);

// popovers config
$popover-bg:                        $white;
$popover-border-color:              $border-color;
$popover-border-radius:             $border-radius-lg;
$popover-box-shadow:                $box-shadow-lg;
.popover {box-shadow: $popover-box-shadow;}

$popover-header-bg:                 $white;
$popover-header-padding-y:          $spacer;
$popover-header-padding-x:          $spacer;

$popover-body-color:                $body-color;
$popover-body-padding-y:            $spacer;
$popover-body-padding-x:            $spacer;

// modal
$modal-lg:                          700px;
$modal-inner-padding:               var(--arkefip-spacer-md);
