// custom inputs
.custom-checkboxes {
  .checkbox {
    display: block;

    label {
      margin-left: 5px;
    }

    input {
      position: relative;
      right: 5px;
      top: 2px;
    }
  }
}

.custom-radiobuttons {
  .radio {
    display: block;

    label {
      margin-left: 17px;
      text-indent: -12px;
    }

    input {
      position: relative;
      right: 5px;
      top: 2px;
    }
  }
}

.custom_yes_no_btn {
  .btn {
    margin: 10px 0;

  }

  .btn_green {
    background: $color-main;
    color: white
  }
}

.custom-select-wrapper {
  select {
    font-family: $font-family-sans-serif;
    padding: 0 10px;
    cursor: pointer;
  }
}
