.electronic-doc-form {
  .form-card {
    .note {
      margin-bottom: 0;
    }

    .w-90 {
      width: 90%;
    }

    .w-90 .ComponentsStepItem--divider {
      width: calc(111% + 60px);
    }

    li,
    p {
      text-align: justify
    }

    ul {
      margin-left: 1rem;

      li {
        margin-bottom: 0.5rem;
      }
    }

    .doc-sign-switch {
      display: flex;
      justify-content: flex-end;
      margin: -10px;
      position: relative;
      right: -50px;
      top: 5px;
    }

    @media only screen and (max-width: map-get($grid-breakpoints, "md")) {
      .w-90 {
        width: 100%;
      }

      .w-90 .ComponentsStepItem--divider {
        width: calc(100% + 60px);
      }

      .doc-sign-switch {
        display: block;
        margin: 10px 5px;
        position: initial;
      }
    }
  }
}
