.braced-heading {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-xs);
  align-items: flex-start;

  &-top, &-bottom {
    svg {
      fill: var(--arkefip-def-light);
    }

    &[data-heading-level="1"] {
      width: var(--arkefip-spacer-lg);
    }
    &[data-heading-level="2"] {
      width: var(--arkefip-spacer-md);
    }
    &[data-heading-level="3"] {
      width: var(--arkefip-spacer-sm);
    }
  }

  &-bottom {
    transform: rotate(180deg);
  }

  &-body {
    display: flex;
    flex-direction: column;

  }
  
  &-title {
    color: var(--arkefip-dark);
    h1, h2, h3 {
      padding: 0 !important;
      line-height: 100%;
    }
  }

  &-subtitle {
    color: var(--arkefip-def-primary);
  }

  &-center {
    align-items: center;
  }

  &-1 {

  }
}