.arkefip-waiting-cta {
  border-radius: 1rem;
  padding: var(--arkefip-spacer-md);
  // background-color: var(--arkefip-interaction);
  box-shadow: var(--arkefip-shadow-lg);
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-sm);
  
  &-heading {
    color: white;
  }
  
  &-form {
    display: flex;
    align-items: center;
    gap: var(--arkefip-spacer-sm);
    padding: var(--arkefip-spacer-sm);
    background-color: white;
    border-radius: 1rem;
    box-shadow: var(--arkefip-shadow-lg);

    &-input {
      flex-grow: 1;
    }
  }

  &-footnote {
    color: white;
    font-size: 0.8rem;
    line-height: 1.2;
    opacity: 0.6;
  }
}