.toast {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-top: 8px solid $color-success;
  background-color: #fff;
  .toast-body { opacity: 100 }
}

.toast-bottom-center {
  .toast {
    box-shadow: 0 0 250px 200px rgba(0, 0, 0, 0.9);
  }
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 25%;
  left: 0;
  justify-content: center;
  padding: 8px;
  z-index: 5000;
}

.toast-top-right {
  position: fixed;
  top: 32px;
  right: 0;
  padding: 8px;
  z-index: 5000;
}
