.list-item {
  display: flex;
  align-items: center;
  padding: var(--arkefip-spacer-xs);
  background-color: var(--arkefip-def-background);
  gap: var(--arkefip-spacer-xs);

  border-radius: 0.5rem;
  color: var(--arkefip-def-dark);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--arkefip-def-darth);
    background-color: var(--arkefip-def-extralight);
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }
  span {
    display: flex;
    align-items: center;
    flex: 1;
    font-weight: bold;
  }
}