.arkefip-round-icon {
  display: inline-block;
  text-decoration: none !important;
  &:first-child {
    margin-right: .5rem;
  }
  &.with-badge {
    min-height: 1.5rem;
    max-height: 1.5rem;
    &::after {
      font-weight: bold;
      font-family: 'Font Awesome 6 Free';
      content: "\f111";
      color: $color-danger-text;
      position: relative;
      left: 1rem;
      top: -2rem;
      font-size: .5rem;
      height: 0;
    }
  }
  .picture, .picture-icon {
    min-width: 1.5rem;
    min-height: 1.5rem;
    max-width: 1.5rem;
    max-height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    background-color: var(--arkefip-def-extralight);
    border-width: 1px;
    border-style: solid;
    color: var(--arkefip-def-dark);
    border-color: var(--arkefip-def-dark);
    &::after {
      font-weight: bold;
      font-family: 'Font Awesome 6 Free';
    }

    &-globe {
      min-width: 2rem;
      min-height: 2rem;
      max-width: 2rem;
      max-height: 2rem;
      font-size: 1.2rem;
      color: white;
      border-color: var(--arkefip-interaction);
      background-color: var(--arkefip-interaction);
      &::after {
        content: "\f0ac";
      }
    }
    &-icon {
      border: none;
      background-color: transparent;
      &::after {
        content: "\f0c9";
      }
    }

    &-close {
      border: none;
      background-color: white;
      font-size: 1.2rem;

      &::after {
        content: "\00d7";
      }
    }

    &-main {
      &::after {
        content: "\f007";
      }
    }

    &-secondary {
      &::after {
        content: "\f0b1";
      }
    }

    &-plus {
      &::after {
        content: "\f067";
      }
    }
    &-child {
      &::after {
        content: "\f1ae";
      }
    }
  }
}
