.breadcrumb {
  border-top: 1px solid var(--arkefip-def-dark);
  border-bottom: 1px solid var(--arkefip-def-dark);
  background: transparent;
  padding: var(--arkefip-layout-padding);
  ul {
    padding: 0;
    margin: 0 var(--arkefip-layout-padding);
  }

  li {
    &.url, &:last-child {
      &::before {
        font-family: "Font Awesome 6 Free";
        content: "\f0da";
        font-style: normal;
        font-weight: 900;
        color: var(--arkefip-primary);
        padding-right: 0.5rem;
      }
    }

    display: inline;
    margin-left: 1rem;
    &:first-child { margin-left: 0 }
  }
}
