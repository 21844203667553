@use "sass:map";

$energy_categories: "innovation", "eolien", "bioenergie", "solaire", "hydroelectrique", "economie_energie", "reseau_chaleur", "reseau_froid", "mix_energetique", "stockage", "hydrogen";
.energy {
  @extend .pe-2 ;
  @extend .rounded ;
  @extend .border ;
  height: 1.5rem;
  white-space: nowrap;
  
  @each $category in $energy_categories {
    &-#{$category} {
      background: map.get($energyColorsList, "energy-#{$category}-bg");
      border-color: map.get($energyColorsList, "energy-#{$category}") !important;
      span {
        color: map.get($energyColorsList, "energy-#{$category}");
      }
      .energy-icon-wrapper {
        background: map.get($energyColorsList, "energy-#{$category}");
        i {
          color: $white;
        }
      }
    }
  }
}
