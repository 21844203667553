.cgu_acceptance_panel {
  .form-inputs > .form-group {
    border-bottom: 1px solid $secondary;
    &:last-child {
      border-bottom: none;
    }
  }
  .button-checkbox {
    min-width: 70px;
    text-align: center;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  a.btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
