// TODO move me in app/assets/stylesheets/pages/_agents.scss
.page-agents  {
.title {
  font-size: 24px;
  line-height: 110%;
  font-weight: 100;
  @media (min-width: 900px) {
    font-size: 40px;
  }
}

.partner-hero {
  display: flex;
  width: 100%;
  min-height: 90vh;
  padding: 1rem;

  @media (min-width: 900px) {
    padding: 3rem;
  }

  box-sizing: border-box;
  color: white;
  text-align: center;

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url('../../assets/images/pictures/solar-sky.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 2rem;
    overflow: hidden;
    gap: 3rem;
    padding: 2rem;
  }

  &-subtitle {
    width: 50%;
    min-width: 320px;
    margin-bottom: 0;

    img {
      max-height: 48px
    }

  }

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  &-brand-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    @media (min-width: 900px) {
      flex-direction: row;
      align-items: center;
    }

    &-enerfip, &-agent {
      img {
        height: 3rem;
      }
    }
  }

  &-cta {
    display: flex;
    grid-gap: 2rem;
  }
}

.main-nav {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  justify-content: flex-end;
  padding: 2rem;

  &-item {
    font-weight: 400;
  }
}
}
