.arkefip-modal {
  .modal-body {

    h1,
    h2,
    h3,
    h4 {
      margin: var(--arkefip-spacer-md) 0 var(--arkefip-spacer-sm) 0;
    }

    h4 {
      font-family: var(--arkefip-font-body);
      font-size: 1rem;
      font-weight: 600;
    }

    p {
      margin: var(--arkefip-spacer-sm) 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      li {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        @include media-breakpoint-up(md) {
          &::before {
            content: "\2192";
          }
        }
      }
    }
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: var(--arkefip-spacer-xs);

  p + h1 {
    margin-top: var(--arkefip-spacer-md);
  }

  p + h2 {
    margin-top: var(--arkefip-spacer-sm);;
  }

  h1 {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    gap: var(--arkefip-spacer-sm);

    &::after {
      content: ' ';
      display: flex;
      width: 100%;
      flex: 1;
      height: 1px;
      background-color: var(--arkefip-def-extralight);
    }
  }

  h2 {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.1rem;
    font-family: var(--arkefip-font-body);
    font-weight: bold;
    gap: var(--arkefip-spacer-sm);
  }
}

.modal-lead {
  display: flex;
  flex-direction: column;
  padding: var(--arkefip-spacer-sm);
  border-radius: 1rem;
  background-color: var(--arkefip-def-background);
}